import CheckIcon from "@mui/icons-material/Check";
import CrossIcon from "@mui/icons-material/Clear";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

const boolCheckCrossColDef = (
  fieldName: string,
  headerName: string,
  invert?: boolean
) => ({
  field: fieldName,
  headerName: headerName,
  width: 100,
  valueFormatter: (params: any) => {
    const isTrue = invert ? !params.value : params.value;
    return isTrue ? "Y" : "N";
  },
  renderCell: (params: GridRenderCellParams<any>) => {
    const show = invert ? !params.value : params.value;
    return show ? (
      <CheckIcon color="primary" />
    ) : (
      <CrossIcon sx={{ opacity: 0.3 }} />
    );
  },
});
export default boolCheckCrossColDef;
