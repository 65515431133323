import { CommissionModel } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const modelInfoRows = (models: CommissionModel[]) => {
  const rows: GridRowsProp = models.map((model) => {
    return {
      id: model.id,
      name: model.name,
      businessLine: model.businessLine,
      startDate: new Date(model.startDate),
      endDate: model.endDate ? new Date(model.endDate) : null,
      tags: model.tags,
    };
  });

  return rows;
};

export default modelInfoRows;
