import { useCallback, useEffect } from "react";
import { GridBase } from "..";
import { delCommissionModel } from "../../../api/delete";
import { contractListColDef, contractListRows } from "..";
import { useNavigate } from "react-router-dom";

const ContractListDataGrid = ({ contracts }: Props) => {
  const navigate = useNavigate();

  const onClick = useCallback((data: any) => {
    const url = `/entity/${data.row.entityId}/projects/data/${data.row.projectIndex}/contracts/update/${data.row.contractIndex}`;
    navigate(url);
  }, []);

  useEffect(() => {
    console.log("Updated contracts at ContractListDataGrid");
  }, [contracts]);

  return (
    <GridBase
      name=""
      deleteMethod={delCommissionModel}
      data={contracts}
      rowFormatter={contractListRows}
      colDef={contractListColDef}
      deleteById={false}
      initialState={{
        pinnedColumns: { left: ["name", "project"] },
        sorting: {
          sortModel: [{ field: "contractDate", sort: "desc" }],
        },
      }}
      onClick={onClick}
      defaultRows={20}
      style={{ border: "none" }}
    />
  );
};

interface Props {
  contracts: any[];
}

export default ContractListDataGrid;
