import * as React from "react";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { AuthContext, AuthContextProvider } from "../context/role";
import { DataContextProvider } from "../context/data";
import { NotificationContextProvider } from "../context/notification";
import { NotificationPopUp } from "../components/notifications";
import { app } from "../components/firebase";
import { getAuth, User, signOut } from "firebase/auth";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TopBar from "./AppBar";
import Sidebar from "./Sidebar";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Layout() {
  return (
    <AuthContextProvider>
      <NotificationContextProvider>
        <UI />
      </NotificationContextProvider>
    </AuthContextProvider>
  );
}

function UI() {
  const [open, setOpen] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<User | null>();
  const [role, setRole] = React.useState<string>();
  const auth = getAuth(app);

  const authState = React.useContext(AuthContext);

  const logout = React.useCallback(() => {
    signOut(auth);
  }, [signOut]);

  React.useEffect(() => {
    setCurrentUser(authState.user);
    setRole(authState.role);
  }, [authState]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return !!currentUser ? (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <CssBaseline />
      <NotificationPopUp />
      <TopBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        logout={logout}
        avatar={currentUser?.photoURL ?? "/images/republic-icon.svg"}
        name={currentUser?.displayName ?? "..."}
        userRole={role ?? ""}
      />
      {role !== "contractOnly" ? (
        <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      ) : (
        <div />
      )}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div
          style={{
            paddingTop: 15,
            paddingRight: 15,
            paddingBottom: 25,
            paddingLeft: 15,
            height: "calc(100% - 40px)",
            width: "100%",
          }}
        >
          <DataContextProvider role={role!}>
            <Outlet />
          </DataContextProvider>
        </div>
      </Box>
    </Box>
  ) : typeof currentUser !== "undefined" ? (
    <div style={{ height: "100%", width: "100%" }}>
      <Outlet />
    </div>
  ) : null;
}
