import { LegalEntity } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const entityRows = (entities: LegalEntity[]) => {
  const rows: GridRowsProp = entities.map((entity) => {
    return {
      id: entity.id,
      name: entity.name,
      address: entity.address,
      projects: entity.projects?.map((p) => p.name) ?? [],
      tags: entity.tags,
    };
  });

  return rows;
};

export default entityRows;
