import { LoadingButton } from '@mui/lab'
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../../context/role'

const SubmitButton = ({ sending, update, roles, disabled }: Props) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const authState = useContext(AuthContext)

  useEffect(() => {
    if (roles.indexOf(authState.role as 'admin' | 'finance' | 'contractViewer' | undefined) > -1) {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [authState])

  return (
    <LoadingButton
      variant='outlined'
      fullWidth
      type='submit'
      loading={sending}
      sx={{ py: '0.8rem', mt: '1.7rem', color: 'white' }}
      disabled={disabled || !isAdmin}
    >
      {update ? 'Update' : 'Save'}
    </LoadingButton>
  )
}

interface Props {
  sending: boolean
  update: boolean
  roles: ['admin'?, 'finance'?, 'contractViewer'?]
  disabled?: boolean
}

export default SubmitButton
