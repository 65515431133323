import * as f from '../../common'
import { Box } from '@mui/material'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState, useContext, useRef } from 'react'
import { CommissionRole, Payee, CommissionModel, CommissionPool } from '../../../../contract/types'
import { commissionModelSchema } from '../../validation/schemas'
import { putCommissionModel } from '../../../../api/put'
import { postCommissionModel } from '../../../../api/post'
import { SubmitButton } from '../../inputs'
import { DataContext } from '../../../../context/data'
import { NotificationContext } from '../../../../context/notification'
import { app } from '../../../firebase'
import { getAuth, getIdToken } from 'firebase/auth'
import { handleResponse, handleError } from '../../../../utils'
import ModelInfo from './ModelInfo'
import ModelPoolMappings from './ModelPoolMappings'
import ModelRoleMappings from './ModelRoleMappings'
import ModelIndividualMappings from './ModelIndividualMappings'

const ModelForm = ({ update, redirect, close, type, itemIndex }: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [sending, setSending] = useState<boolean>(false)
  const [tagOptions, setTagOptions] = useState<string[]>([])
  const [payees, setPayees] = useState<Payee[]>([])
  const [pools, setPools] = useState<CommissionPool[]>([])
  const [roles, setRoles] = useState<CommissionRole[]>([])
  const [token, setToken] = useState<string>()

  const firstStart = useRef<boolean>(true)
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app)
    const user = auth.currentUser
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user))
      }
      setNewToken()
    }

    firstStart.current = false
  }

  const methods = useForm({
    resolver: zodResolver(commissionModelSchema),
  })

  const context = useContext(DataContext)
  const notifications = useContext(NotificationContext)

  useEffect(() => {
    if (context.tags && context.roles && context.payees && context.pools) {
      setTagOptions(context.tags)
      setPayees(context.payees)
      setRoles(context.roles)
      setPools(context.pools)
    }
  }, [context])

  useEffect(() => {
    if (update) {
      methods.setValue('name', update.name)
      methods.setValue('startDate', new Date(update.startDate))
      methods.setValue('endDate', update.endDate ? new Date(update.endDate) : undefined)
      methods.setValue('tags', update.tags ? update.tags : [])
      methods.setValue('businessLine', update.businessLine)
      methods.setValue('commissionModelPoolMappings', update.commissionModelPoolMappings)
      methods.setValue('commissionModelRoleMappings', update.commissionModelRoleMappings)
      methods.setValue(
        'commissionModelIndividualMappings',
        update.commissionModelIndividualMappings,
      )
    } else {
      methods.setValue('commissionModelPoolMappings', [])
      methods.setValue('commissionModelRoleMappings', [])
      methods.setValue('commissionModelIndividualMappings', [])
      methods.setValue('tags', [])
    }
  }, [])

  useEffect(() => {
    if (tagOptions.length > 0 && payees.length > 0) setLoading(false)
  }, [tagOptions, payees])

  const onSubmitHandler: SubmitHandler<any> = async values => {
    if (!token) throw new Error('No token available')

    setSending(true)
    const body: CommissionModel = {
      ...values,
    }
    if (update && update.id) body.id = update.id
    try {
      const response =
        update && update.id
          ? await putCommissionModel(update.id, token, body)
          : await postCommissionModel(body, token)

      const result = handleResponse(response, notifications)

      if (result) {
        if (!response.id) throw new Error('The server returned an object with no id')

        context.update(response, 'models', response.id)
        context.refreshAll()
        close()
      } else {
        /* Stop the loading spinner */
        setSending(false)
      }
    } catch (e: any) {
      handleError(e, notifications)
      setSending(false)
    }
  }

  if (loading) return null
  return (
    <f.FormContainer>
      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          {type === 'info' ? (
            <>
              <ModelInfo tags={tagOptions} update={update} />
              <SubmitButton sending={sending} roles={['admin', 'finance']} update={!!update} />
            </>
          ) : type === 'pools' ? (
            <>
              <ModelPoolMappings tags={tagOptions} pools={pools} itemIndex={itemIndex} />
              <SubmitButton sending={sending} roles={['admin', 'finance']} update={!!update} />
            </>
          ) : type === 'roles' ? (
            <>
              <ModelRoleMappings tags={tagOptions} roles={roles} itemIndex={itemIndex} />
              <SubmitButton sending={sending} roles={['admin', 'finance']} update={!!update} />
            </>
          ) : type === 'payees' ? (
            <>
              <ModelIndividualMappings tags={tagOptions} payees={payees} itemIndex={itemIndex} />
              <SubmitButton
                sending={sending}
                update={!!update}
                roles={['admin', 'finance']}
                disabled={!!!token}
              />
            </>
          ) : null}
        </Box>
      </FormProvider>
    </f.FormContainer>
  )
}

interface Props {
  update?: CommissionModel
  redirect?: string | number
  type: 'info' | 'pools' | 'roles' | 'payees'
  itemIndex?: number
  close: () => void
}

export default ModelForm
