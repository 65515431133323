import { useCallback } from "react";
import { GridBase } from "..";
import { delCommissionModel } from "../../../api/delete";
import { LiquidationModal } from "../../modals";
import { liquidateMultipleColDef, liquidateMultipleRows } from "..";
import { useNavigate } from "react-router-dom";

const LiquidateMultipleDataGrid = ({ liquidations }: Props) => {
  const navigate = useNavigate();

  const onClick = useCallback((data: any) => {
    const [entityId, projectIndex, contractIndex] = data.row.id.split("-");
    const url = `/entity/${entityId}/projects/data/${projectIndex}/contracts/update/${contractIndex}`;
    navigate(url);
  }, []);

  return (
    <GridBase
      name=""
      FormModal={LiquidationModal as any}
      deleteMethod={delCommissionModel}
      data={liquidations}
      rowFormatter={liquidateMultipleRows}
      colDef={liquidateMultipleColDef}
      deleteById={false}
      initialState={{
        pinnedColumns: { left: ["contractName", "symbol", "quantity"] },
        sorting: {
          sortModel: [{ field: "date", sort: "desc" }],
        },
      }}
      onClick={onClick}
      defaultRows={20}
      style={{ border: "none" }}
    />
  );
};

interface Props {
  liquidations: any[];
}

export default LiquidateMultipleDataGrid;
