import * as contract from "../../contract/types";
import * as query from "./types";
import { Error, post, processResponse } from "../../utils";

export async function postLegalEntity(
  body: contract.LegalEntity,
  token: string
): Promise<contract.LegalEntity & Error> {
  return post("legalentity", body, token).then((res) => {
    return processResponse(res);
  });
}

export async function postCommissionModel(
  body: contract.CommissionModel,
  token: string
): Promise<contract.CommissionModel & Error> {
  return post("commissionModel", body, token).then((res) => {
    return processResponse(res);
  });
}

export async function postCommissionPool(
  body: contract.CommissionPool,
  token: string
): Promise<contract.CommissionPool & Error> {
  return post("commissionPool", body, token).then((res) => {
    return processResponse(res);
  });
}

export async function postCommissionRole(
  body: contract.CommissionRole,
  token: string
): Promise<contract.CommissionRole & Error> {
  return post("commissionRole", body, token).then((res) => {
    return processResponse(res);
  });
}

export async function postCommissionOverride(
  body: contract.CommissionOverride,
  token: string
): Promise<contract.CommissionOverride & Error> {
  return post("commissionOverride", body, token).then((res) => {
    return processResponse(res);
  });
}

export async function postPayee(
  body: contract.Payee,
  token: string
): Promise<contract.Payee & Error> {
  return post("payee", body, token).then((res) => {
    return processResponse(res);
  });
}

export async function postCommissionData(
  body: contract.CommissionData,
  token: string,
  query: query.CommissionData
): Promise<contract.CommissionData & Error> {
  return post("commission", body, token, query).then((res) => {
    return processResponse(res);
  });
}

export async function postSnapshot(
  token: string,
  query: query.Snapshot
): Promise<contract.Snapshot & Error> {
  return post("snapshot", undefined, token, query).then((res) => {
    return processResponse(res);
  });
}
