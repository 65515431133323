import { EntityForm } from "../../components/forms/elements/entity";
import { useParams } from "react-router-dom";
import { LegalEntity } from "../../contract/types";

const ModifyEntity = () => {
  const params = useParams();

  if (!params.entityId || !params.entityData) return <div>Error</div>;

  const entityData = JSON.parse(params.entityData) as unknown as LegalEntity;

  return null;
};

export default ModifyEntity;
