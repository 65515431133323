import { GridBase } from "..";
import {
  SnapshotUpdatePaymentAmountModal,
  DeleteSnapshotPayeeModal,
} from "../../modals";
import { delCommissionModel } from "../../../api/delete";
import { snapshotUpdateColDef } from "..";
import commissionEventRow from "../rows/commissionEventRow";
import commissionEventColDef from "../columns/CommissionEventColDef";

const SnapshotUpdateDataGrid = ({ events, approved }: Props) => {
  return (
    <GridBase
      name=""
      FormModal={SnapshotUpdatePaymentAmountModal as any}
      DeleteModal={DeleteSnapshotPayeeModal as any}
      deleteMethod={delCommissionModel}
      data={events}
      rowFormatter={commissionEventRow}
      colDef={approved ? commissionEventColDef : snapshotUpdateColDef}
      deleteById={true}
      initialState={{
        pinnedColumns: {
          left: ["name", "date", "value", "actions"],
        },
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      createSnapshot={false}
      defaultRows={20}
    />
  );
};

interface Props {
  events: any[];
  approved?: boolean;
}

export default SnapshotUpdateDataGrid;
