import { Payee } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const payeeRows = (payees: Payee[]) => {
  const rows: GridRowsProp = payees.map((payee) => {
    return {
      id: payee.id,
      name: payee.name,
      email: payee.email,
      startDate: new Date(payee.startDate),
      endDate: payee.endDate ? new Date(payee.endDate) : null,
      tags: payee.tags,
    };
  });

  return rows;
};

export default payeeRows;
