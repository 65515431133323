import { useCallback } from "react";
import { GridBase } from "..";
import { putLegalEntity } from "../../../api/put";
import {
  DeleteEntityKeyByStringModal,
  LiquidationApprovalModal,
} from "../../modals";
import { liquidationPendingListColDef, liquidationPendingListRows } from "..";
import { useNavigate } from "react-router-dom";

const LiquidationPendingListDataGrid = ({ liquidations }: Props) => {
  const navigate = useNavigate();

  const onClick = useCallback((data: any) => {
    const [entityId, projectIndex, contractIndex] = data.row.id.split("-");
    const url = `/entity/${entityId}/projects/data/${projectIndex}/contracts/update/${contractIndex}`;
    navigate(url);
  }, []);

  return (
    <GridBase
      name=""
      FormModal={LiquidationApprovalModal as any}
      DeleteModal={DeleteEntityKeyByStringModal as any}
      deleteMethod={putLegalEntity}
      deleteType="liquidations"
      data={liquidations}
      rowFormatter={liquidationPendingListRows}
      colDef={liquidationPendingListColDef}
      deleteById={false}
      initialState={{
        pinnedColumns: { left: ["contractName", "date"] },
        sorting: {
          sortModel: [{ field: "date", sort: "desc" }],
        },
      }}
      onClick={onClick}
      defaultRows={20}
      style={{ border: "none" }}
    />
  );
};

interface Props {
  liquidations: any[];
}

export default LiquidationPendingListDataGrid;
