import { useState, useCallback, useEffect, useContext, useRef } from "react";
import { DataContext } from "../../context/data";
import { useParams } from "react-router-dom";
import { getCommissionData } from "../../api/get";
import { Chip, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { coinInfo } from "./components/config";
import { getAuth, getIdToken } from "firebase/auth";
import { app } from "../../components/firebase";
import PortfolioBalancePie from "./PortfolioBalancePie";
import Loading from "../../layout/Loading";
import LiquidationGrid from "./components/grids/LiquidationGrid";
import PotentialLiquidationsGrid from "./components/grids/PotentialLiquidationsGrid";

const Profile = () => {
  const { loading, payees } = useContext(DataContext);
  const [token, setToken] = useState<string>();
  const [commissionData, setCommissionData] = useState<Profile>();
  const [payoutData, setPayoutData] = useState<any[]>();
  const [tokenData, setTokenData] =
    useState<{ symbol: string; quantity: number; value: number }[]>();
  const [total, setTotal] = useState<number>();
  const email = useParams().emailAddress;

  const firstStart = useRef<boolean>(true);

  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const fetchData = useCallback(async () => {
    if (!token) throw new Error("No token present!");
    const commissions = await getCommissionData(token, { email: email });
    setCommissionData(commissions[0] as any as Profile);
  }, [token, email, payees]);

  useEffect(() => {
    if (!loading && token) {
      fetchData();
    }
  }, [loading, token]);

  useEffect(() => {
    if (commissionData) {
      let total = 0;
      const payoutDates: Date[] = [];
      const payouts: any[] = [];
      const tokens: { symbol: string; quantity: number; value: number }[] = [];

      commissionData.liquidationSplits.forEach((split) => {
        if (!payoutDates.includes(split.approved)) {
          const payout = {
            value: split.value,
            events: 1,
            approved: split.approved,
          };
          payouts.push(payout);
        } else {
          const index = payoutDates.indexOf(split.approved);
          payouts[index].value += split.value;
          payouts[index].events++;
        }
      });

      commissionData.potentialSplits.forEach((split) => {
        if (split.value) total += split.value;
        const tokenIndex = tokens.findIndex((t) => t.symbol === split.symbol);
        if (tokenIndex === -1) {
          tokens.push({
            symbol: split.symbol,
            quantity: split.quantity,
            value: split.value,
          });
        } else {
          tokens[tokenIndex].quantity += split.quantity;
          tokens[tokenIndex].value += split.value;
        }
      });

      setTokenData(tokens);
      setPayoutData(payouts);
      setTotal(total);
    }
  }, [commissionData]);

  if (loading || !commissionData || typeof total === "undefined")
    return <Loading />;

  return (
    <div style={{ marginTop: -20 }}>
      {/* Balance wheel */}
      <div style={{ width: "100%", position: "relative" }}>
        <PortfolioBalancePie
          distribution={commissionData.potentialSplits as any}
          totalValue={""}
        />
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
            Potential Earnings*
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 600, fontSize: "1.7rem" }}>
            {usdFormatter.format(total)}
          </Typography>
        </div>
      </div>

      {/* Chips */}
      <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
        Model Chips
      </Typography>
      <Stack direction="row" spacing={1} justifyContent="center">
        {commissionData.models.map((model) => (
          <Chip
            label={model.name}
            key={(Math.random() + 1).toString(36).substring(7)}
            variant="outlined"
            sx={{ boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px" }}
          />
        ))}
      </Stack>
      {commissionData.roles.length ? (
        <>
          <div style={{ height: 20 }} />
          <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
            Role Chips
          </Typography>
        </>
      ) : null}
      <Stack direction="row" spacing={1} justifyContent="center">
        {commissionData.roles.map((role) => (
          <Chip
            label={`${role.contractName}: ${role.roleName}`}
            variant="outlined"
            sx={{ boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px" }}
            key={(Math.random() + 1).toString(36).substring(7)}
          />
        ))}
      </Stack>

      {payoutData ? (
        <>
          <div style={{ height: 20 }} />
          <Typography variant="body1" sx={{ textAlign: "center", mb: 1 }}>
            Payment Cards
          </Typography>
        </>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {payoutData?.map((payout, i) => (
          <Paper
            square={false}
            variant="outlined"
            key={i}
            sx={{
              minWidth: 320,
              width: 400,
              display: "flex",
              p: 2,
              m: 1,
              border: "1px solid rgba(81, 81, 81, 1)",
              boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px",
            }}
          >
            <Box sx={{ mt: "auto", mb: "auto" }}>
              <img
                src={`/images/republic-icon.svg`}
                style={{ height: 40 }}
                alt={" "}
              />
            </Box>
            <Box sx={{ ml: 1.5, mt: "auto", mb: "auto" }}>
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {payout.approved.substr(0, 10)}
              </Typography>
              <Typography variant="caption">Approved</Typography>
            </Box>

            <Box
              sx={{ ml: "auto", mt: "auto", mb: "auto", textAlign: "right" }}
            >
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {usdFormatter.format(payout.value)}
              </Typography>
              <Typography variant="caption">
                From {payout.events} event{payout.events > 1 ? "s" : ""}
              </Typography>
            </Box>
          </Paper>
        ))}
      </div>

      {tokenData && (
        <Typography variant="body1" sx={{ textAlign: "center", mb: 1, mt: 1 }}>
          Token Cards
        </Typography>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {tokenData?.map((token) => (
          <Paper
            square={false}
            variant="outlined"
            key={token.symbol}
            sx={{
              minWidth: 320,
              width: 400,
              display: "flex",
              p: 2,
              m: 1,
              border: "1px solid rgba(81, 81, 81, 1)",
              boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px",
            }}
          >
            <Box sx={{ mt: "auto", mb: "auto" }}>
              <img
                src={`/images/${
                  coinInfo[token.symbol]
                    ? token.symbol.toLowerCase()
                    : "republic-icon"
                }.svg`}
                style={{ height: 40 }}
                alt={token.symbol}
              />
            </Box>
            <Box sx={{ ml: 1.5, mt: "auto", mb: "auto" }}>
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {token.symbol}
              </Typography>
              <Typography variant="caption">
                {token.quantity
                  ? usdFormatter.format(token.value / token.quantity)
                  : usdFormatter.format(0)}
                <span style={{ color: "green", marginLeft: 5 }}>--</span>
              </Typography>
            </Box>

            <Box
              sx={{ ml: "auto", mt: "auto", mb: "auto", textAlign: "right" }}
            >
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {token.quantity ? token.quantity.toFixed(4) : 0}
              </Typography>
              <Typography variant="caption">
                {usdFormatter.format(token.value)}
              </Typography>
            </Box>
          </Paper>
        ))}
      </div>

      {commissionData.models.length ? (
        <Typography variant="body1" sx={{ textAlign: "center", mb: 1, mt: 1 }}>
          Model Cards
        </Typography>
      ) : null}
      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {commissionData.models?.map((model, i) => (
          <Paper
            square={false}
            variant="outlined"
            key={i}
            sx={{
              minWidth: 320,
              width: 400,
              display: "flex",
              p: 2,
              m: 1,
              border: "1px solid rgba(81, 81, 81, 1)",
              boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px",
            }}
          >
            <Box sx={{ mt: "auto", mb: "auto" }}>
              <img
                src={`/images/republic-icon.svg`}
                style={{ height: 40 }}
                alt={" "}
              />
            </Box>
            <Box sx={{ ml: 1.5, mt: "auto", mb: "auto" }}>
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {model.name}
              </Typography>
              <Typography variant="caption">Commission Model</Typography>
            </Box>

            <Box
              sx={{ ml: "auto", mt: "auto", mb: "auto", textAlign: "right" }}
            >
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {model.percentage}%
              </Typography>
              <Typography variant="caption">of liquidations</Typography>
            </Box>
          </Paper>
        ))}
      </div>

      {commissionData.roles.length ? (
        <Typography variant="body1" sx={{ textAlign: "center", mb: 1, mt: 1 }}>
          Role Cards
        </Typography>
      ) : null}
      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {commissionData.roles?.map((role, i) => (
          <Paper
            square={false}
            variant="outlined"
            key={i}
            sx={{
              minWidth: 320,
              width: 400,
              display: "flex",
              p: 2,
              m: 1,
              border: "1px solid rgba(81, 81, 81, 1)",
              boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px",
            }}
          >
            <Box sx={{ mt: "auto", mb: "auto" }}>
              <img
                src={`/images/republic-icon.svg`}
                style={{ height: 40 }}
                alt={" "}
              />
            </Box>
            <Box sx={{ ml: 1.5, mt: "auto", mb: "auto" }}>
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {role.roleName}
              </Typography>
              <Typography variant="caption">{role.projectName}</Typography>
            </Box>

            <Box
              sx={{ ml: "auto", mt: "auto", mb: "auto", textAlign: "right" }}
            >
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: 1, fontWeight: 500 }}
              >
                {role.percentage}%
              </Typography>
              <Typography variant="caption">of liquidations</Typography>
            </Box>
          </Paper>
        ))}
      </div>

      <Typography variant="body1" sx={{ textAlign: "center", mb: 2.5, mt: 2 }}>
        Commissions Breakdown Spreadsheet
      </Typography>
      <Box
        sx={{
          maxWidth: 1000,
          textAlign: "center",
          margin: "auto",
          border: "1px solid rgba(81, 81, 81, 1)",
          boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px",
        }}
        component={Paper}
        variant="outlined"
        square={false}
      >
        <Box sx={{ borderBottom: "1px solid rgba(81, 81, 81, 1)" }}>
          <Typography
            variant="subtitle1"
            sx={{
              pt: 2.3,
              pb: 2.3,
              fontWeight: 500,
            }}
          >
            Commissions
          </Typography>
        </Box>

        <LiquidationGrid data={commissionData.liquidationSplits} />
      </Box>

      <Typography variant="body1" sx={{ textAlign: "center", mb: 2.5, mt: 3 }}>
        Potential Earnings Breakdown Spreadsheet
      </Typography>
      <Box
        sx={{
          maxWidth: 1000,
          textAlign: "center",
          margin: "auto",
          border: "1px solid rgba(81, 81, 81, 1)",
          boxShadow: "rgb(0 0 0 / 30%) 0px 4px 20px",
        }}
        component={Paper}
        variant="outlined"
        square={false}
        elevation={5}
      >
        <Box sx={{ borderBottom: "1px solid rgba(81, 81, 81, 1)" }}>
          <Typography
            variant="subtitle1"
            sx={{
              pt: 2.3,
              pb: 2.3,
              fontWeight: 500,
            }}
          >
            Potential Earnings
          </Typography>
        </Box>

        <PotentialLiquidationsGrid data={commissionData.potentialSplits} />
      </Box>
    </div>
  );
};

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default Profile;

export interface Profile {
  name: string; // The name of the user
  email: string;
  startDate: Date; // The users start date
  totalValue: number; // The total value of potential rewards
  models: {
    name: string; // Name of the model (i.e. Advisory-2019)
    startDate: Date; // The date they entered the model
    endDate?: Date; // The day they left the model or the date the model ended if its historic
    percentage: number; // The percentage members receive(d)
  }[];
  roles: {
    title: string; // The name value from the contract role assignment
    roleName: string; // The global role name (i.e. Project Manager)
    entityName: string; // The name of the legal entity
    projectName: string; // The name of the project
    contractName: string; // The name of contract
    percentage: number; // The current percentage the applicable model assigns to the role
  }[];
  discretionary: {
    name: string; // Name of the rule
    models: string[]; // The names of the models applicable to the rule based on their pool startDate (i.e. Advisory-2019)
    reason: string; // The reason they are getting the commission
    percentage: number; // The percentage the rule gives them
  }[];
  overrides: {
    name: string; // Name of the rule
    entityName: string; // The name of the legal entity
    projectName: string; // The name of the project
    contractName: string; // The name of contract
    startDate: Date; // The date the rule started
    endDate?: Date; // The end date for the override if it exists
    reason: string; // The reason they are getting the commission
  }[];
  individual: {
    modelName: string; // The name of the model that contains the rule
    startDate: Date; // The The start date of the model
    endDate?: Date; // The day they left the model or the date the model ended if its historic
    percentage: number; // The percentage assigned to the individual rule
  }[];
  // The splits of approved liquidations
  liquidationSplits: {
    entityName: string; // The name of the legal entity
    projectName: string; // The name of the project
    contractName: string; // The name of contract
    modelName?: string; // Model name if applicable
    commissionType: "pool" | "role" | "individual" | "override";
    commissionTypeName: string; // The role title or rule name where applicable;
    date: Date; // Date of the liquidation
    approved: Date;
    symbol: string; // Symbol of the currency liquidated
    sellPrice: number; // The price we sold at
    quantity: number; // The number of the users tokens that were sold
    value: number; // The value the user should expect to receive
    percentage: number; // The percentage of the total liquidation
  }[];
  // The potential splits of remaining funds
  potentialSplits: {
    entityName: string; // The name of the legal entity
    projectName: string; // The name of the project
    contractName: string; // The name of contract
    modelName?: string; // Model name if applicable
    commissionType: "pool" | "role" | "individual" | "override";
    commissionTypeName: string; // The role title or rule name where applicable;
    symbol: string; // Symbol of the currency
    quantity: number; // The number of the users tokens may receive at some point
    value: number; // The value they're currently worth
  }[];
}
