import { useRef, useContext } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { TextInput, DateInput, ArrayInput } from "../../inputs";
import { NotificationContext } from "../../../../context/notification";
import { Contract } from "../../../../contract/types";
import { FormNestedBorder } from "../../common";

const ContractLiquidations = ({
  tags,
  itemIndex,
  symbols,
  startDate,
  baseContract,
}: Props) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append } = useFieldArray<Contract, "liquidations", "index">({
    control: control as any,
    name: "liquidations",
    keyName: "index",
  });

  const firstLoad = useRef<boolean>(true);
  const notifications = useContext(NotificationContext);

  if (firstLoad.current && typeof itemIndex === "undefined") {
    firstLoad.current = false;
    if (symbols.length === 0) {
      notifications.set({
        message: "There aren't any fees or rewards to liquidate",
        type: "error",
      });
    }

    append({
      name:
        baseContract && symbols.length > 0
          ? `${symbols[0]} Staking Liquidation ${new Date().toLocaleDateString(
              "en-US"
            )}`
          : undefined,
      quantity: undefined,
      symbol: symbols.length > 0 ? symbols[0] : undefined,
      price: undefined,
      type: baseContract && symbols ? "Rewards" : undefined,
      date: new Date(),
      approved: "",
      tags: [],
    });
  }

  return (
    <>
      {fields.map((field, index) => {
        if (
          typeof itemIndex === "number"
            ? index === itemIndex
            : index === fields.length - 1
        ) {
          return (
            <FormNestedBorder uid={field.index} key={field.index}>
              <TextInput
                name={`liquidations.${index}.name`}
                label="Event Title"
                defaultValue={field.name}
                required={true}
                register={register}
                errors={errors.liquidations?.[index]?.name}
              />

              <ArrayInput
                name={`liquidations.${index}.symbol`}
                label="Symbol"
                register={register}
                setValue={setValue}
                options={symbols}
                defaultValue={field.symbol}
                errors={errors.liquidations?.[index].symbol}
                multiple={false}
                required={true}
              />

              <ArrayInput
                name={`liquidations.${index}.type`}
                label="Fee Type"
                register={register}
                setValue={setValue}
                options={["Cash", "Rewards", "Fund", "Token", "Reserve"]}
                defaultValue={field.type}
                errors={errors.liquidations?.[index]?.type}
                multiple={false}
                required={true}
              />

              <DateInput
                name={`liquidations.${index}.date`}
                label="Liquidation Date"
                value={field.date as Date | null}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors.liquidations?.[index]?.date}
                required={true}
                maxDate={new Date()}
                minDate={new Date(startDate)}
              />

              {/* <DateInput
                name={`liquidations.${index}.approved`}
                label="Approved Date"
                value={field.date as Date | null}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors.liquidations?.[index]?.approved}
                required={false}
                maxDate={new Date()}
              /> */}

              <TextInput
                name={`liquidations.${index}.quantity`}
                label="Quantity"
                defaultValue={field.quantity}
                type="number"
                required={true}
                register={register}
                errors={errors.liquidations?.[index]?.quantity}
              />

              <TextInput
                name={`liquidations.${index}.price`}
                label="Price"
                defaultValue={field.price}
                type="number"
                required={true}
                register={register}
                errors={errors.liquidations?.[index]?.price}
              />

              <ArrayInput
                name={`liquidations.${index}.tags`}
                label="Tags"
                register={register}
                setValue={setValue}
                options={tags}
                defaultValue={field.tags}
                errors={errors.liquidations?.[index].tags}
                multiple={true}
              />
            </FormNestedBorder>
          );
        } else return null;
      })}
    </>
  );
};

interface Props {
  update?: Contract;
  tags: string[];
  symbols: string[];
  itemIndex?: number;
  baseContract?: string;
  startDate: Date | string;
}

export default ContractLiquidations;
