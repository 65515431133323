const dateColDef = (fieldName: string, headerName: string) => {
  const dateColumn = {
    field: fieldName,
    headerName: headerName,
    width: 140,
    valueFormatter: ({ value }: { value: Date | undefined }) =>
      value ? new Date(value).toLocaleDateString("en-US") : "--",
  };

  return dateColumn;
};

export default dateColDef;
