import { useNavigate } from "react-router-dom";
import { SnapshotApprovalModal, DeleteByIdModal } from "../../modals";
import { GridBase, snapshotListColDef, snapshotListRows } from "..";
import { delSnapshot } from "../../../api/delete";
import { Snapshot } from "../../../contract/types";

const SnapshotListDataGrid = ({ snapshots }: Props) => {
  const navigate = useNavigate();

  const onClick = (data: any) => {
    const id: string = data.row.id;
    const url = `/snapshot/${id}`;
    navigate(url);
  };

  return (
    <GridBase
      name=""
      FormModal={SnapshotApprovalModal as any}
      DeleteModal={DeleteByIdModal as any}
      deleteMethod={delSnapshot}
      data={snapshots}
      rowFormatter={snapshotListRows}
      colDef={snapshotListColDef}
      deleteById={true}
      onClick={onClick}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      isList={true}
    />
  );
};

interface Props {
  snapshots: Snapshot[];
}

export default SnapshotListDataGrid;
