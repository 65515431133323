import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  dateColDef,
  infoShortColDef,
  currencyColDef,
  infoLongColDef,
  tagsColDef,
  actionsColDef,
} from "./common";

const snapshotUpdateColDef = ({
  handleEditClick,
  handleDeleteClick,
}: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Name", "name") },
    { ...dateColDef("date", "Date") },
    {
      ...currencyColDef("Value USD", "value"),
    },
    {
      ...infoShortColDef("Symbol", "symbol"),
    },
    {
      ...infoShortColDef("Quantity", "quantity"),
    },
    { ...nameColDef("Liquidation", "liquidationName") },
    { ...infoShortColDef("Contract", "contractName"), width: 330 },
    { ...nameColDef("Project", "projectName") },
    {
      ...infoShortColDef("Comm. Model", "model"),
    },
    {
      ...infoShortColDef("Pool", "pool"),
    },
    {
      ...infoShortColDef("Role", "role"),
    },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default snapshotUpdateColDef;
