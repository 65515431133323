import { memo } from "react";
import { useNavigate, To } from "react-router-dom";
import { Chip } from "@mui/material";

export const LinkChip = ({ label, url, variant }: ChipProps) => {
  const navigate = useNavigate();

  return (
    <Chip
      label={label}
      style={{ margin: 2 }}
      size="small"
      variant="outlined"
      onClick={(e) => {
        e.stopPropagation();
        navigate(url as To);
      }}
      color="primary"
    />
  );
};

const LinkChipMemo = memo(LinkChip);

interface ChipProps {
  label: string;
  variant: "outlined" | "filled";
  url: string;
}

export default LinkChipMemo;
