// import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ContractForm } from "../../forms/elements/contract";
import { formModalStyle } from "../common";
import { LegalEntity, Contract } from "../../../contract/types";

export default function ContractModal({
  legalEntityId,
  legalEntityData,
  contractData,
  projectIndex,
  contractIndex,
  itemIndex,
  type,
  open,
  handleOpenState,
}: Props) {
  /** Use to avoid the menu being closed while a sending is happening */
  // const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  const infoModalStyle = { ...formModalStyle, width: 1000 };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={
            type === "info"
              ? { ...infoModalStyle, maxHeight: "100%", overflow: "auto" }
              : { ...formModalStyle, maxHeight: "100%", overflow: "auto" }
          }
        >
          <ContractForm
            redirect={0}
            legalEntityId={legalEntityId}
            legalEntityData={legalEntityData}
            contractData={contractData}
            projectIndex={projectIndex}
            contractIndex={contractIndex}
            itemIndex={itemIndex}
            type={type}
            close={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  legalEntityId: string;
  legalEntityData: LegalEntity;
  contractData?: Contract;
  projectIndex: number;
  contractIndex?: number;
  itemIndex?: number;
  type: "info" | "fee" | "liquidation" | "override" | "assignment";
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
