import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Contract } from "../../../../contract/types";
import { Grid } from "@mui/material";
import { TextInput, DateInput, ArrayInput } from "../../inputs";
import { Autocomplete, TextField } from "@mui/material";

const ContractInfo = ({ update, tags, contracts }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(
    update && update.startDate ? new Date(update.startDate) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    update && update.endDate ? new Date(update.endDate) : null
  );
  const [contractType, setContractType] = useState<string | undefined>(
    update?.baseContract
  );

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            name="name"
            label="Name"
            defaultValue={update?.name}
            required={true}
            register={register}
            errors={errors.name}
          />

          <DateInput
            name="startDate"
            label="Start Date"
            value={startDate}
            control={control}
            register={register}
            setValue={setValue}
            setParentState={setStartDate}
            errors={errors.startDate}
            required={true}
            maxDate={new Date()}
          />

          <DateInput
            name="endDate"
            label="End Date"
            value={endDate}
            control={control}
            register={register}
            setValue={setValue}
            setParentState={setEndDate}
            errors={errors.endDate}
            required={true}
            minDate={startDate as Date}
          />

          <ArrayInput
            name="businessLine"
            label="Business Line"
            register={register}
            setValue={setValue}
            options={[
              "Advisory",
              "Tokenization",
              "TokenSales",
              "Treasury",
              "Funds",
              "CommissionReserves",
              "MusicFinancing",
            ]}
            defaultValue={update?.businessLine}
            errors={errors.businessLine}
            multiple={false}
            required={true}
          />

          <ArrayInput
            name="contractType"
            label="Contract Type"
            register={register}
            setValue={setValue}
            options={["New", "Extension", "Staking", "CommissionReserves"]}
            defaultValue={update?.contractType}
            errors={errors.contractType}
            multiple={false}
            required={true}
            setParentState={setContractType}
          />

          <ArrayInput
            name="baseContract"
            label="Base Contract (Staking)"
            register={register}
            setValue={setValue}
            options={contracts}
            defaultValue={
              update?.baseContract
                ? contracts[
                    contracts.findIndex((c) => c.name === update.baseContract)
                  ]
                : undefined
            }
            errors={errors.baseContract}
            multiple={false}
            optionFormatter={(option: any) => (option.name ? option.name : "")}
            path="name"
            disabled={contractType !== "Staking"}
          />

          <ArrayInput
            name="tags"
            label="Tags"
            register={register}
            setValue={setValue}
            options={tags}
            defaultValue={update?.tags}
            errors={errors.tags}
            multiple={true}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            name="cashTerms"
            label="Cash Terms"
            defaultValue={update?.cashTerms}
            register={register}
            errors={errors.cashTerms}
            multiline={true}
          />

          <TextInput
            name="tokenTerms"
            label="Token Terms"
            defaultValue={update?.tokenTerms}
            register={register}
            errors={errors.tokenTerms}
            multiline={true}
          />

          <Autocomplete
            options={["Yes", "No"]}
            defaultValue={update ? (update.vested ? "Yes" : "No") : undefined}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ mb: 2 }}
                label="Vesting"
                placeholder="Vesting"
                error={!!errors["vested"]}
                helperText={errors["vested"] ? errors["vested"].message : ""}
              />
            )}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : ""
            }
            {...register("vested")}
            onChange={(e, selectedObject) => {
              if (selectedObject !== null)
                setValue(
                  "vested",
                  selectedObject === "Yes"
                    ? true
                    : selectedObject === "No"
                    ? false
                    : undefined,
                  { shouldValidate: true }
                );
            }}
          />

          <TextInput
            name="equityFee"
            label="Equity Fee"
            defaultValue={update?.equityFee}
            register={register}
            type="number"
            errors={errors.equityFee}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface Props {
  update?: Contract;
  tags: string[];
  contracts: Contract[];
}

export default ContractInfo;
