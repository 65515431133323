import { useContext } from "react";
import { DataContext } from "../../../context/data";
import { Payee } from "../../../contract/types";
import { PayeeModal, DeleteByIdModal } from "../../modals";
import { useNavigate } from "react-router-dom";
import { GridBase, payeeColDef, payeeRows } from "..";
import { delPayee } from "../../../api/delete";

const PayeeDataGrid = ({ payees }: Props) => {
  const navigate = useNavigate();

  const context = useContext(DataContext);

  const onClick = (data: any) => {
    const id = data.row.id;
    const payee = context.payees[context.payees.findIndex((p) => p.id === id)];
    const url = `https://sonar-client.republic-beta.app/dashboard/${payee.email}`;
    window.open(url);
  };

  return (
    <GridBase
      name="Payee"
      FormModal={PayeeModal as any}
      DeleteModal={DeleteByIdModal as any}
      deleteMethod={delPayee}
      data={payees}
      rowFormatter={payeeRows}
      colDef={payeeColDef}
      deleteById={true}
      onClick={onClick}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  payees: Payee[];
  tags?: string[];
}

export default PayeeDataGrid;
