import { TextField, Autocomplete } from "@mui/material";
import { FieldValues, UseFormRegister } from "react-hook-form";

const ArrayInput = ({
  name,
  label,
  options,
  register,
  setValue,
  errors,
  setParentState,
  path,
  optionFormatter,
  required,
  reduce,
  isString,
  callback,
  ...rest
}: Props) => {
  return (
    <Autocomplete
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ mb: 2 }}
          label={label}
          error={!!errors}
          required={required}
          helperText={errors ? errors.message : ""}
        />
      )}
      getOptionLabel={
        optionFormatter
          ? optionFormatter
          : (option) => (typeof option === "string" ? option : "")
      }
      {...register(name)}
      onChange={(e, selectedObject) => {
        if (selectedObject !== null) {
          const value =
            reduce && path
              ? selectedObject.map((d: any) => d[path])
              : isString
              ? selectedObject[0]
              : path
              ? selectedObject[path]
              : selectedObject;
          setValue(name, value, { shouldValidate: true });
          if (callback) {
            callback(value);
          }
          if (setParentState) {
            setParentState(value);
          }
        } else {
          setValue(name, undefined, { shouldValidate: true });
          if (callback) {
            callback(undefined);
          }
          if (setParentState) {
            setParentState(undefined);
          }
        }
      }}
      {...rest}
    />
  );
};

interface Props {
  name: string;
  label: string;
  options: any[];
  register: UseFormRegister<FieldValues>;
  setValue: (
    name: string,
    value: any,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
  required?: boolean;
  disabled?: boolean;
  errors?: { [key: string]: any };
  defaultValue?: any[] | { [key: string]: any } | string;
  value?: any[];
  multiple?: boolean;
  setParentState?: (value: React.SetStateAction<string | undefined>) => void;
  path?: string;
  optionFormatter?: (option: { [key: string]: any } | string) => string;
  reduce?: boolean;
  isString?: boolean;
  callback?: any;
}

export default ArrayInput;
