import { useState, useContext } from "react";
import { DataContext } from "../../context/data";
import { useParams } from "react-router-dom";
import { Payee } from "../../contract/types";
import {
  PoolPayeeDataGrid,
  PoolDiscretionaryCommissionDataGrid,
} from "../../components/datagrids";
import { Chip, Typography } from "@mui/material";
import { PoolModal } from "../../components/modals";
import { Heading, Subheading } from "../../components/profile";
import Loading from "../../layout/Loading";

const ModifyPool = () => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const { loading, payees, pools } = useContext(DataContext);

  const params = useParams();

  if (!params.poolData || !params.poolId) {
    throw new Error("Pool data not provided");
  }

  if (loading) return <Loading />;

  const poolIdMap = pools.map((p) => p.id);

  let percentage = 0;

  const poolData = pools[poolIdMap.indexOf(params.poolId)];
  const { owners, discretionaryCommissions, payeePoolMappings, ...info } =
    poolData;

  const payeeIdMap = payees?.map((payee) => payee.id);

  const poolOwners = owners?.map((id) => {
    const payeeData = payees![payeeIdMap!.indexOf(id)];
    return { ...payeeData };
  });

  const poolPayees = payeePoolMappings?.map((payee) => {
    const payeeIndex = payeeIdMap!.indexOf(payee.payeeId);
    if (payeeIndex === -1)
      return { ...payee, name: "DELETED", email: "DELETED" };
    const payeeData = payees![payeeIndex];
    return { ...payee, name: payeeData.name, email: payeeData.email };
  }) as Payee[];

  const discretionaryCommissionRules = discretionaryCommissions?.map((rule) => {
    const payeeIndex = payeeIdMap!.indexOf(rule.payeeId);
    if (rule.percentage) percentage += Number(rule.percentage);
    if (payeeIndex === -1)
      return { ...rule, payeeName: "DELETED", email: "DELETED" };
    const payeeData = payees![payeeIdMap!.indexOf(rule.payeeId)];
    return { ...rule, payeeName: payeeData.name, email: payeeData.email };
  }) as any[];

  const payeeDeletePath = `payeePoolMappings`;
  const discretionaryDeletePath = `discretionaryCommissions`;

  return (
    <>
      <Heading
        title={`${info.name} ${
          poolData.discretionary ? " (" + percentage + "%" + ")" : ""
        }`}
        tags={info.tags}
        extraText={`This pool is ${
          info.discretionary ? "" : "not "
        }discretionary.`}
        handleOpenState={setOpenEdit}
      />
      <div style={{ marginTop: 30 }} />
      <Subheading title={"Owners"} />
      <div style={{ marginTop: 10, marginBottom: 25 }}>
        {poolOwners.map((owner) =>
          owner.name ? (
            <a
              href={`mailto: ${owner.email}`}
              style={{ textDecoration: "none" }}
              key={(Math.random() + 1).toString(36).substring(7)}
            >
              <Chip
                label={`${owner.name} (${owner.email})`}
                sx={{ mr: 1, mb: 0.5, cursor: "pointer" }}
                key={(Math.random() + 1).toString(36).substring(7)}
              />
            </a>
          ) : null
        )}
      </div>
      <Subheading title={"Status"} />
      <div style={{ marginTop: 10, marginBottom: 15 }}>
        <Chip
          label={
            <span>
              Active:{" "}
              <span style={{ fontWeight: 500 }}>
                {poolData.discretionary
                  ? discretionaryCommissionRules.length
                  : poolPayees?.filter((p) => !p.endDate).length || 0}
              </span>
            </span>
          }
          sx={{ mr: 1, mb: 0.5 }}
          color="primary"
          variant="outlined"
          key={(Math.random() + 1).toString(36).substring(7)}
        />

        <Chip
          label={
            <span>
              Inactive:{" "}
              <span>
                {poolPayees?.filter(
                  (p) => p.endDate && new Date(p.endDate) <= new Date()
                ).length || 0}
              </span>
            </span>
          }
          sx={{ mr: 1, mb: 0.5 }}
          variant="outlined"
          key={(Math.random() + 1).toString(36).substring(7)}
        />
      </div>

      <div style={{ height: 15 }} />
      {!poolData.discretionary && (
        <>
          <Subheading title={"Pool Payees"} />
          <PoolPayeeDataGrid
            payees={poolPayees}
            poolData={poolData}
            deletePath={payeeDeletePath}
          />
          <div style={{ height: 50 }} />
        </>
      )}

      {poolData.discretionary && (
        <>
          <Subheading title={"Discretionary Commissions"} />
          <PoolDiscretionaryCommissionDataGrid
            rules={discretionaryCommissionRules}
            poolData={poolData}
            deletePath={discretionaryDeletePath}
          />
          <div style={{ height: 50 }} />
        </>
      )}
      <PoolModal
        open={openEdit}
        handleOpenState={setOpenEdit}
        poolData={poolData}
        type="info"
      />
    </>
  );
};

export default ModifyPool;
