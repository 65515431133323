import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import {
  FieldValues,
  UseFormRegister,
  Controller,
  Control,
} from "react-hook-form";

const DateInput = ({
  name,
  label,
  value,
  required,
  control,
  register,
  setValue,
  setParentState,
  errors,
  ...rest
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <DatePicker
            label={label}
            value={value ? value : null}
            inputRef={null}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            openTo="year"
            views={["year", "month", "day"]}
            renderInput={(params) => (
              <TextField
                sx={{ mb: 2, width: "100%" }}
                value={value ? value : null}
                {...params}
                required={required}
                error={!!errors}
                helperText={errors ? errors.message : ""}
                onClick={(e) => setOpen(true)}
              />
            )}
            onChange={onChange}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  );
};

interface Props {
  name: string;
  label: string;
  value: Date | null;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  setValue: (
    name: string,
    value: any,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
  required?: boolean;
  errors?: { [key: string]: any };
  defaultValue?: string;
  setParentState?: (value: React.SetStateAction<Date | null>) => void;
  minDate?: Date;
  maxDate?: Date;
}

export default DateInput;
