import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  infoShortColDef,
  dateColDef,
  tagsColDef,
  actionsColDef,
  numberColDef,
  boolCheckCrossColDef,
} from "./common";

const contractFeeColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    {
      ...infoShortColDef("Type", "type"),
      width: 120,
    },
    {
      ...infoShortColDef("Symbol", "symbol"),
      width: 120,
    },
    {
      ...numberColDef("Quantity", "quantity"),
      width: 150,
    },
    {
      ...infoShortColDef("Method", "tokenTerms"),
      width: 150,
    },
    {
      ...dateColDef("atDate", "Date"),
      width: 120,
    },
    { ...boolCheckCrossColDef("manual", "Auto", true) },
    {
      ...infoShortColDef("Notes", "notes"),
      width: 300,
    },
    { ...tagsColDef, width: 160 },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default contractFeeColDef;
