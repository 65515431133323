import { useRef, useCallback, useState, useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { Contract, Payee } from "../../../../contract/types";
import { CommissionRole } from "../../../../api/get/types";
import { TextInput, DateInput, ArrayInput } from "../../inputs";
import { FormNestedBorder } from "../../common";
import { Button } from "@mui/material";

const ContractPayeeRoleAssignments = ({
  tags,
  payees,
  roles,
  itemIndex,
}: Props) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append } = useFieldArray<
    Contract,
    "payeeRoleAssignments",
    "index"
  >({
    control: control as any,
    name: "payeeRoleAssignments",
    keyName: "index",
  });

  const [userId, setUserId] = useState<string>();
  const firstLoad = useRef<boolean>(true);

  if (firstLoad.current && typeof itemIndex === "undefined") {
    firstLoad.current = false;
    append({
      name: undefined,
      commissionRoleId: undefined,
      payeeId: undefined,
      startDate: "",
      endDate: "",
      tags: [],
    });
  }

  const setStartDate = useCallback(
    (id: string, index: number) => {
      const payeeIndex = payees.findIndex((p) => p.id === id);
      if (payeeIndex === -1) throw new Error("The payee doesn't exist?");
      setValue(
        `payeeRoleAssignments.${index}.startDate`,
        new Date(payees[payeeIndex].startDate)
      );
    },
    [payees]
  );

  return (
    <>
      {fields.map((field, index) => {
        if (
          typeof itemIndex === "number"
            ? index === itemIndex
            : index === fields.length - 1
        ) {
          return (
            <FormNestedBorder uid={field.index} key={index}>
              <TextInput
                name={`payeeRoleAssignments.${index}.name`}
                label="Name"
                defaultValue={field.name}
                required={true}
                register={register}
                errors={errors.payeeRoleAssignments?.[index]?.name}
              />

              <ArrayInput
                name={`payeeRoleAssignments.${index}.payeeId`}
                label="Payee"
                register={register}
                setValue={setValue}
                options={payees}
                defaultValue={() => {
                  const payee =
                    payees[payees.findIndex((p) => p.id === field.payeeId)];
                  if (payee) setUserId(payee.id);
                  else setUserId(undefined);

                  return payee;
                }}
                errors={errors.payeeRoleAssignments?.[index]?.payeeId}
                multiple={false}
                required={true}
                optionFormatter={(option: any) =>
                  option.name && option.email
                    ? `${option.name} (${option.email})`
                    : ""
                }
                path="id"
                callback={setUserId}
              />

              <ArrayInput
                name={`payeeRoleAssignments.${index}.commissionRoleId`}
                label="Commission Role"
                register={register}
                setValue={setValue}
                options={roles}
                defaultValue={
                  field.commissionRoleId
                    ? roles.find((r) => r.id === field.commissionRoleId)
                    : undefined
                }
                errors={errors.payeeRoleAssignments?.[index]?.commissionRoleId}
                multiple={false}
                required={true}
                optionFormatter={(option: any) =>
                  option.name ? option.name : ""
                }
                path="id"
              />

              <DateInput
                name={`payeeRoleAssignments.${index}.startDate`}
                label="Start Date"
                value={field.startDate as Date | null}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors.payeeRoleAssignments?.[index]?.startDate}
                required={true}
              />

              <Button
                sx={{ mt: -2, mb: 1, float: "right" }}
                disabled={!!!userId}
                onClick={() => setStartDate(userId as string, index)}
              >
                User start date
              </Button>

              <DateInput
                name={`payeeRoleAssignments.${index}.endDate`}
                label="End Date"
                value={field.endDate as Date | null}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors.payeeRoleAssignments?.[index]?.endDate}
                required={false}
              />

              <ArrayInput
                name={`payeeRoleAssignments.${index}.tags`}
                label="Tags"
                register={register}
                setValue={setValue}
                options={tags}
                defaultValue={field.tags}
                errors={errors.payeeRoleAssignments?.[index].tags}
                multiple={true}
              />
            </FormNestedBorder>
          );
        } else return null;
      })}
    </>
  );
};

interface Props {
  tags: string[];
  payees: Payee[];
  roles: CommissionRole[];
  itemIndex?: number;
}

export default ContractPayeeRoleAssignments;
