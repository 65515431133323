import queryString from "query-string";

export const urlQuery = (slug: string, query?: { [key: string]: any }) => {
  const url = "https://sonar-api.republic-beta.app/" + slug;
  if (!query) return url;
  return queryString.stringifyUrl({ url, query });
};

export async function get(
  slug: string,
  token: string,
  query?: { [key: string]: any }
) {
  const options: {
    method: string;
    headers: { [key: string]: any };
    body?: string;
  } = {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  };

  return fetch(urlQuery(slug, query), options)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      const errData: Error = {
        error: true,
        status: err.status,
        message: err.message,
      };
      return errData;
    });
}

export interface Error {
  error: boolean;
  status?: number;
  message?: string;
}

export async function post(
  slug: string,
  data: { [key: string]: any } | undefined,
  token: string,
  query?: { [key: string]: any }
) {
  const options: {
    method: string;
    headers: { [key: string]: any };
    body?: string;
  } = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  if (data) options.body = JSON.stringify(data);

  return fetch(urlQuery(slug, query), options);
}

export async function put(
  slug: string,
  data: { [key: string]: any } | undefined,
  token: string,
  query?: { [key: string]: any }
) {
  const options: {
    method: string;
    headers: { [key: string]: any };
    body?: string;
  } = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  if (data) options.body = JSON.stringify(data);

  return fetch(urlQuery(slug, query), options);
}

export async function del(
  slug: string,
  token: string,
  query?: { [key: string]: any }
) {
  const options = {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  };

  return fetch(urlQuery(slug, query), options); //.then((res) => res.json());
}

export const processResponse = (res: any) => {
  if (res.ok) {
    return res.json();
  } else {
    console.error(res);
    return { error: true, status: res.status, message: res.statusText };
  }
};

export const handleResponse = (response: any, notifications: any) => {
  /* Check for error */
  if (!response.error) {
    if (!response.id)
      throw new Error("The server returned an object with no id");

    /* Show notification that it saved successfully */
    notifications.set({
      message: `Saved successfully!`,
      type: "success",
    });

    return true;
  } else {
    notifications.set({
      message: `Failed to save: ${
        response.message
          ? response.message
          : response.status
          ? response.status + " Error"
          : "Unknown error"
      }`,
      type: "error",
    });

    console.error(response);
    return false;
  }
};

export const handleError = (error: any, notifications: any) => {
  notifications.set({
    message: `Failed to save: ${
      error?.message
        ? error.message
        : error?.status
        ? error.status + " Error"
        : "Unknown error"
    }`,
    type: "error",
  });
  console.error(error);
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

export function monthDiff(d1: Date, d2: Date) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
