import { useState, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Heading } from "../../components/profile";
import { ProjectModal } from "../../components/modals";
import { ProjectDataGrid } from "../../components/datagrids";
import { DataContext } from "../../context/data";
import Loading from "../../layout/Loading";

const ListProject = () => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const context = useContext(DataContext);
  const params = useParams();

  if (!params.entityId || !params.entityData) {
    console.error("Data not provided");
    return <Navigate to="/entity" />;
  }

  if (context.loading) return <Loading />;

  const entityIndex = context.entities.findIndex(
    (e) => e.id === params.entityId
  );
  const entityData = context.entities[entityIndex];
  const deletePath = `projects`;

  return (
    <>
      <Heading
        title={`${entityData.name} Projects`}
        extraText={"Create, edit and manage projects"}
      />
      <div style={{ height: 5 }} />
      <ProjectDataGrid
        projects={entityData.projects ?? []}
        legalEntityData={entityData}
        legalEntityId={entityData.id!}
        deletePath={deletePath}
        objectToEdit={entityData}
      />
      <ProjectModal
        open={openEdit}
        handleOpenState={setOpenEdit}
        legalEntityData={entityData}
        legalEntityId={entityData.id!}
      />
    </>
  );
};

export default ListProject;
