import * as f from '../../common'
import { Box, Typography } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState, useContext, useRef } from 'react'
import { postSnapshot } from '../../../../api/post'
import { snapshotSchema } from '../../validation/schemas'
import { NotificationContext } from '../../../../context/notification'
import { useNavigate } from 'react-router-dom'
import { TextInput, SubmitButton } from '../../inputs'
import { DataContext } from '../../../../context/data'
import { app } from '../../../firebase'
import { handleResponse, handleError } from '../../../../utils'
import { getAuth, getIdToken } from 'firebase/auth'

const SnapshotForm = ({ snapshotId, snapshotData, update, redirect, close }: Props) => {
  const [sending, setSending] = useState<boolean>(false)
  const [token, setToken] = useState<string>()

  const navigate = useNavigate()

  const firstStart = useRef<boolean>(true)
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app)
    const user = auth.currentUser
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user))
      }
      setNewToken()
    }

    firstStart.current = false
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: zodResolver(snapshotSchema),
  })

  useEffect(() => {
    if (update) {
      setValue('name', update.name)
    }
  }, [])

  const uniquePayees: string[] = []
  let totalPayout: number = 0
  snapshotData.forEach(d => {
    if (!uniquePayees.includes(d.payeeId)) {
      uniquePayees.push(d.payeeId)
    }
    if (d.valueUsd) {
      totalPayout += d.valueUsd
    }
  })

  const context = useContext(DataContext)
  const notifications = useContext(NotificationContext)

  const onSubmitHandler: SubmitHandler<any> = async values => {
    if (!token) throw new Error('No token available')

    setSending(true)
    const query = { ...values }

    try {
      const response = await postSnapshot(token, query)
      const result = handleResponse(response, notifications)

      if (result) {
        if (!response.id) throw new Error('The server returned an object with no id')

        context.update(response, 'snapshots', response.id)
        context.refreshAll()
        navigate('/snapshot')
      } else {
        setSending(false)
      }
    } catch (e: any) {
      handleError(e, notifications)
      setSending(false)
    }
  }

  return (
    <f.FormContainer>
      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <TextInput
          name='name'
          label='Snapshot Name'
          defaultValue={update?.name}
          required={true}
          register={register}
          errors={errors.name}
        />
        <div style={{ marginTop: -10, paddingLeft: 10 }}>
          <Typography variant='caption'>
            * Values can be modified after creating the snapshot.
          </Typography>
        </div>

        <SubmitButton
          sending={sending}
          update={!!update}
          roles={['admin', 'finance']}
          disabled={!!!token}
        />
      </Box>
    </f.FormContainer>
  )
}

interface Props {
  snapshotId?: string
  snapshotData: any[]
  update?: any
  redirect?: string | number
  close: () => void
}

export default SnapshotForm
