import * as f from "../../common";
import { Box } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState, useContext, useRef } from "react";
import { LegalEntity } from "../../../../contract/types";
import { postLegalEntity } from "../../../../api/post";
import { putLegalEntity } from "../../../../api/put";
import { entitySchema } from "../../validation/schemas";
import { DataContext } from "../../../../context/data";
import { TextInput, ArrayInput, SubmitButton } from "../../inputs";
import { app } from "../../../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { NotificationContext } from "../../../../context/notification";
import { handleResponse, handleError } from "../../../../utils";

const EntityForm = ({ update, close }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);
  const [tagOptions, setTagOptions] = useState<string[]>([]);
  const [token, setToken] = useState<string>();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: zodResolver(entitySchema),
  });

  const context = useContext(DataContext);
  const notifications = useContext(NotificationContext);

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  useEffect(() => {
    if (context.tags) {
      setTagOptions(context.tags);
    }
  }, [context]);

  useEffect(() => {
    if (tagOptions.length > 0) setLoading(false);
  }, [tagOptions]);

  useEffect(() => {
    if (update) {
      setValue("name", update.name);
      setValue("address", update.address);
      setValue("tags", update.tags);
    } else {
      setValue("name", undefined);
      setValue("address", undefined);
      setValue("tags", []);
    }
  }, []);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    if (!token) throw new Error("No token available");
    setSending(true);

    const body = update
      ? { ...update, ...values }
      : { ...values, projects: [] };

    try {
      const response =
        update && update.id
          ? await putLegalEntity(update.id, token, body)
          : await postLegalEntity(body, token);

      const result = handleResponse(response, notifications);

      if (result) {
        if (!response.id)
          throw new Error("The server returned an object with no id");

        context.update(response, "entities", response.id);
        close();
      } else {
        /* Stop the loading spinner */
        setSending(false);
      }
    } catch (e: any) {
      handleError(e, notifications);
      setSending(false);
    }
  };

  if (loading) return null;
  return (
    <f.FormContainer>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <TextInput
          name="name"
          label="Name"
          defaultValue={update?.name}
          required={true}
          register={register}
          errors={errors.name}
        />

        <TextInput
          name="address"
          label="Address"
          defaultValue={update?.address}
          required={true}
          register={register}
          errors={errors.address}
        />

        <ArrayInput
          name="tags"
          label="Tags"
          register={register}
          setValue={setValue}
          options={tagOptions}
          defaultValue={update?.tags}
          errors={errors.tags}
          multiple={true}
        />

        <SubmitButton sending={sending} update={!!update} roles={["admin", "finance", "contractViewer"]} disabled={!!!token} />
      </Box>
    </f.FormContainer>
  );
};

interface Props {
  update?: LegalEntity;
  redirect?: string | number;
  close: () => void;
}

export default EntityForm;
