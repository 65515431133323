import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ModelForm } from "../../forms/elements/model";
import { formModalStyle } from "../common";
import { CommissionModel } from "../../../contract/types";

export default function ModelModal({
  data,
  modelData,
  open,
  type,
  itemIndex,
  handleOpenState,
}: Props) {
  const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <ModelForm
            update={modelData ?? data}
            redirect={0}
            type={type}
            itemIndex={itemIndex}
            close={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  data?: CommissionModel;
  modelData?: CommissionModel;
  open: boolean;
  type: "info" | "pools" | "roles" | "payees";
  itemIndex?: number;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
