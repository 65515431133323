import { GridColumns } from "@mui/x-data-grid-pro";
import {
  dateColDef,
  infoShortColDef,
  numberColDef,
  currencyColDef,
} from "./common";

const liquidationListColDef = () => {
  const columns: GridColumns = [
    {
      field: "contractName",
      headerName: "Contract",
      width: 220,
    },
    {
      ...dateColDef("date", "Sell Date"),
    },
    {
      ...currencyColDef("Value", "value"),
    },
    {
      field: "name",
      headerName: "Event",
      width: 140,
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
    },
    {
      field: "symbol",
      headerName: "Symbol",
      width: 100,
    },
    {
      ...numberColDef("Quantity", "quantity"),
    },
    {
      ...currencyColDef("Price", "price"),
    },
    {
      ...dateColDef("approved", "Approved"),
    },

    {
      field: "snapshotted",
      headerName: "Distributed",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      width: 150,
    },
  ];

  return columns;
};

export default liquidationListColDef;
