import * as f from "../../common";
import { useContext, useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { putLegalEntity } from "../../../../api/put";
import { contractSchema } from "../../validation/schemas";
import ContractInfo from "./ContractInfo";
import ContractFees from "./ContractFees";
import ContractLiquidations from "./ContractLiquidations";
import ContractCommissionOverrides from "./ContractCommissionOverrides";
import ContractPayeeRoleAssignments from "./ContractPayeeRoleAssignments";
import ContractQuickLiquidation from "./ContractQuickLiquidation";
import { SubmitButton } from "../../inputs";
import { DataContext } from "../../../../context/data";
import { NotificationContext } from "../../../../context/notification";
import { handleResponse, handleError } from "../../../../utils";
import {
  Contract,
  Payee,
  CommissionPool,
  CommissionRole,
  LegalEntity,
} from "../../../../contract/types";
import { app } from "../../../firebase";
import { getAuth, getIdToken } from "firebase/auth";

const ContractForm = ({
  legalEntityId,
  legalEntityData,
  contractData,
  projectIndex,
  contractIndex,
  type,
  itemIndex,
  symbol,
  max,
  title,
  close,
}: Props) => {
  const context = useContext(DataContext);
  const notifications = useContext(NotificationContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);
  const [symbols, setSymbols] = useState<string[]>([]);
  const [remainingValues, setRemainingValues] = useState<{
    [key: string]: number;
  }>({});
  const [tagOptions, setTagOptions] = useState<string[]>(context.tags);
  const [payees, setPayees] = useState<Payee[]>(context.payees);
  const [pools, setPools] = useState<CommissionPool[]>(context.pools);
  const [roles, setRoles] = useState<CommissionRole[]>(context.roles);
  const [entities, setEntities] = useState<LegalEntity[]>(context.entities);
  const [globalError, setGlobalError] = useState<{
    liquidatedTooMuch?: string;
  }>();
  const [token, setToken] = useState<string>();

  const contracts = legalEntityData.projects?.[projectIndex].contracts ?? [];
  const methods = useForm({
    resolver: zodResolver(contractSchema(setGlobalError)),
  });

  useEffect(() => {
    if (globalError?.liquidatedTooMuch) {
      const symbol = globalError?.liquidatedTooMuch;
      if (remainingValues[symbol]) {
        notifications.set({
          message: `Not enough ${symbol} remaining. Max: ${symbol} ${remainingValues[symbol]}`,
          type: "error",
        });
      }
    }
  }, [globalError]);

  useEffect(() => {
    if (
      context.tags &&
      context.roles &&
      context.payees &&
      context.pools &&
      context.entities
    ) {
      setTagOptions(context.tags);
      setPayees(context.payees);
      setRoles(context.roles);
      setPools(context.pools);
      setEntities(context.entities);
    }
  }, [context]);

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    /* Check symbols available for liquidations */
    const remainingFees: { [key: string]: number } = {};

    if (contractData && contractData.fees) {
      contractData.fees.forEach((f) => {
        /* Calculate total fees */
        if (!remainingFees[f.symbol]) {
          remainingFees[f.symbol] = Number(f.quantity);
        } else {
          remainingFees[f.symbol] += Number(f.quantity);
        }
      });

      /* Calculate what is left */
      if (contractData.liquidations) {
        contractData.liquidations.forEach((l) => {
          if (
            remainingFees[l.symbol] &&
            typeof remainingFees[l.symbol] === "number"
          ) {
            remainingFees[l.symbol] -= Number(l.quantity);
          }
        });
      }
    }

    if (contractData && contractData.liquidations) {
    }

    const remainingSymbols: string[] = Object.keys(remainingFees).filter(
      (key) => remainingFees[key] > 0
    );

    setRemainingValues(remainingFees);
    setSymbols(remainingSymbols);
    firstStart.current = false;
  }

  /* Check if user is opening top level form from spreadsheet */
  if (
    type === "info" &&
    !contractData &&
    legalEntityData?.projects &&
    typeof projectIndex === "number" &&
    typeof itemIndex === "number"
  ) {
    contractData =
      legalEntityData.projects[projectIndex].contracts?.[itemIndex];
    contractIndex = itemIndex;
  }

  useEffect(() => {
    if (contractData) {
      methods.setValue("name", contractData.name);
      methods.setValue("startDate", new Date(contractData.startDate));
      methods.setValue("endDate", new Date(contractData.endDate));
      methods.setValue("businessLine", contractData.businessLine);
      methods.setValue("contractType", contractData.contractType);
      methods.setValue("baseContract", contractData.baseContract);
      methods.setValue("equityFee", contractData.equityFee);
      methods.setValue("cashTerms", contractData.cashTerms);
      methods.setValue("tokenTerms", contractData.tokenTerms);
      methods.setValue("vested", contractData.vested ?? false);
      methods.setValue("tags", contractData.tags ? contractData.tags : []);
      methods.setValue(
        "fees",
        contractData.fees
          ? contractData.fees.map((fee) => ({
              ...fee,
              feeData: fee.feeData
                ? {
                    ...fee.feeData,
                    atDate: fee.feeData.atDate
                      ? new Date(fee.feeData.atDate)
                      : "",
                  }
                : undefined,
              tags: fee.tags ? fee.tags : [],
            }))
          : []
      );
      methods.setValue(
        "liquidations",
        contractData.liquidations
          ? contractData.liquidations.map((liquidation) => {
              const data = {
                ...liquidation,
                date: new Date(liquidation.date),
              };
              if (liquidation.approved)
                data.approved = new Date(liquidation.approved);
              else data.approved = "";
              return data;
            })
          : []
      );
      methods.setValue(
        "commissionOverrides",
        contractData.commissionOverrides
          ? contractData.commissionOverrides.map((override) => {
              return {
                ...override,
                startDate: new Date(override.startDate),
                endDate: override.endDate
                  ? new Date(override.endDate)
                  : undefined,
              };
            })
          : []
      );
      methods.setValue(
        "payeeRoleAssignments",
        contractData.payeeRoleAssignments
          ? contractData.payeeRoleAssignments.map((role) => {
              const roleAssignment = {
                ...role,
                startDate: new Date(role.startDate),
              };
              if (role.endDate) {
                roleAssignment.endDate = new Date(role.endDate);
              }

              return roleAssignment;
            })
          : []
      );
    } else {
      methods.setValue("cashTerms", "");
      methods.setValue("tokenTerms", "");
      methods.setValue("equityFee", 0);
      methods.setValue("vested", false);
      methods.setValue("tags", []);
    }
  }, []);

  useEffect(() => {
    if (
      tagOptions.length > 0 &&
      payees.length > 0 &&
      roles.length > 0 &&
      pools.length > 0 &&
      entities.length > 0
    )
      setLoading(false);
  }, [tagOptions, payees, roles, pools, entities]);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    if (!token) throw new Error("No token available");

    setSending(true);
    const body = legalEntityData;
    if (!body?.projects?.[projectIndex]) {
      throw new Error("Invalid project ID");
    }
    if (!body.projects[projectIndex].contracts) {
      body.projects[projectIndex].contracts = [];
    }
    if (!contractData) {
      body.projects[projectIndex].contracts!.push(values);
    } else {
      if (typeof contractIndex !== "undefined") {
        body.projects[projectIndex].contracts![contractIndex] = {
          ...body.projects[projectIndex].contracts![contractIndex],
          ...values,
        };
      } else {
        console.error("Contract index not provided for update");
        return false;
      }
    }
    try {
      const response = await putLegalEntity(legalEntityId, token, body);
      const result = handleResponse(response, notifications);

      if (result) {
        if (!response.id)
          throw new Error("The server returned an object with no id");

        context.update(response, "entities", response.id);
        context.refreshAll();
        close();
      } else {
        /* Stop the loading spinner */
        setSending(false);
      }
    } catch (e: any) {
      handleError(e, notifications);
      setSending(false);
    }
  };
  if (loading) return null;
  return (
    <f.FormContainer>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          {type === "info" ? (
            <>
              <ContractInfo
                tags={tagOptions}
                update={contractData}
                contracts={contracts}
              />
              <SubmitButton
                sending={sending}
                update={!!contractData}
                roles={["admin", "finance", "contractViewer"]}
                disabled={!!!token}
              />
            </>
          ) : type === "fee" || type === "reward" ? (
            <>
              <ContractFees
                itemIndex={itemIndex}
                reward={type === "reward" ? true : false}
                baseContract={contractData?.baseContract}
                projectIndex={projectIndex}
                entityData={legalEntityData}
              />
              <SubmitButton
                sending={sending}
                update={!!contractData}
                roles={["admin", "finance", "contractViewer"]}
                disabled={!!!token}
              />
            </>
          ) : type === "liquidation" ? (
            <>
              <ContractLiquidations
                tags={tagOptions}
                itemIndex={itemIndex}
                symbols={symbols}
                baseContract={contractData?.baseContract}
                startDate={contractData?.startDate || ""}
              />
              <SubmitButton
                sending={sending}
                update={!!contractData}
                roles={["admin", "finance", "contractViewer"]}
                disabled={!!!token}
              />
            </>
          ) : type === "override" ? (
            <>
              <ContractCommissionOverrides
                tags={tagOptions}
                payees={payees}
                pools={pools}
                itemIndex={itemIndex}
              />
              <SubmitButton
                sending={sending}
                update={!!contractData}
                roles={["admin", "finance", "contractViewer"]}
                disabled={!!!token}
              />
            </>
          ) : type === "assignment" ? (
            <>
              <ContractPayeeRoleAssignments
                tags={tagOptions}
                payees={payees}
                roles={roles}
                itemIndex={itemIndex}
              />
              <SubmitButton
                sending={sending}
                update={!!contractData}
                roles={["admin", "finance"]}
                disabled={!!!token}
              />
            </>
          ) : type === "quickLiquidation" ? (
            <>
              <ContractQuickLiquidation
                tags={tagOptions}
                itemIndex={itemIndex}
                symbol={symbol!}
                max={max!}
                title={title!}
              />
              <SubmitButton
                sending={sending}
                update={!!contractData}
                roles={["admin", "finance"]}
                disabled={!!!token}
              />
            </>
          ) : null}
        </Box>
      </FormProvider>
    </f.FormContainer>
  );
};

interface Props {
  legalEntityId: string;
  legalEntityData: LegalEntity;
  contractData?: Contract;
  projectIndex: number;
  contractIndex?: number;
  redirect?: string | number;
  symbol?: string;
  max?: number;
  title?: string;
  type:
    | "info"
    | "fee"
    | "reward"
    | "liquidation"
    | "override"
    | "assignment"
    | "quickLiquidation";
  itemIndex?: number;
  close: () => void;
}

export default ContractForm;
