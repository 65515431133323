import { CommissionModel } from "../../../contract/types";
import { ModelModal, DeleteByIdModal } from "../../modals";
import { GridBase, modelInfoColDef, modelInfoRows } from "..";
import { delCommissionModel } from "../../../api/delete";
import { useNavigate } from "react-router-dom";

const ModelInfoDataGrid = ({ models }: Props) => {
  const navigate = useNavigate();

  const onClick = (data: any) => {
    const id: string = data.row.id;
    const index: number = models.findIndex((m) => m.id === id);
    const url = `/model/update/${models[index].id}/data`;
    navigate(url);
  };

  return (
    <GridBase
      name="Commission Model"
      FormModal={ModelModal as any}
      DeleteModal={DeleteByIdModal as any}
      deleteMethod={delCommissionModel}
      data={models}
      rowFormatter={modelInfoRows}
      colDef={modelInfoColDef}
      deleteById={true}
      onClick={onClick}
      formType="info"
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  models: CommissionModel[];
  tags: string[];
}

export default ModelInfoDataGrid;
