import { CommissionRole } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const roleRows = (roles: CommissionRole[]) => {
  const rows: GridRowsProp = roles.map((role) => {
    return {
      id: role.id,
      payeeRoleMappings: role.payeeRoleMappings,
      commissionModelRoleMappings: role.commissionModelRoleMappings,
      payees: role.payeeRoleMappings,
      name: role.name,
      tags: role.tags,
    };
  });

  return rows;
};

export default roleRows;
