import { useRef } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { CommissionModel, Contract, Payee } from "../../../../contract/types";
import { TextInput, ArrayInput } from "../../inputs";
import { FormNestedBorder } from "../../common";

const ModelIndividualMappings = ({ tags, payees, itemIndex }: Props) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append } = useFieldArray<
    CommissionModel,
    "commissionModelIndividualMappings",
    "index"
  >({
    control: control as any,
    name: "commissionModelIndividualMappings",
    keyName: "index",
  });

  const firstLoad = useRef<boolean>(true);
  if (firstLoad.current && typeof itemIndex === "undefined") {
    firstLoad.current = false;
    append({
      payeeId: undefined,
      percentage: undefined,
      tags: [],
    });
  }

  return (
    <>
      {fields.map((field, index) => {
        if (
          typeof itemIndex === "number"
            ? index === itemIndex
            : index === fields.length - 1
        ) {
          return (
            <FormNestedBorder uid={field.index} key={index}>
              <ArrayInput
                name={`commissionModelIndividualMappings.${index}.payeeId`}
                label="Payee"
                register={register}
                setValue={setValue}
                options={payees}
                defaultValue={
                  payees[payees.findIndex((p) => p.id === field.payeeId)]
                }
                errors={
                  errors.commissionModelIndividualMappings?.[index]?.payeeId
                }
                multiple={false}
                required={true}
                optionFormatter={(option: any) =>
                  option.name && option.email
                    ? `${option.name} (${option.email})`
                    : ""
                }
                path="id"
              />

              <TextInput
                name={`commissionModelIndividualMappings.${index}.percentage`}
                label="Percentage"
                defaultValue={field.percentage}
                type="number"
                required={true}
                register={register}
                errors={
                  errors.commissionModelIndividualMappings?.[index]?.percentage
                }
              />

              <ArrayInput
                name={`commissionModelIndividualMappings.${index}.tags`}
                label="Tags"
                register={register}
                setValue={setValue}
                options={tags}
                defaultValue={field.tags}
                errors={errors.commissionModelIndividualMappings?.[index].tags}
                multiple={true}
              />
            </FormNestedBorder>
          );
        }
      })}
    </>
  );
};

interface Props {
  update?: Contract;
  tags: string[];
  payees: Payee[];
  itemIndex?: number;
}

export default ModelIndividualMappings;
