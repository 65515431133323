import { useState } from "react";
import { LiquidationApprovalForm } from "../../forms/elements/contract";
import { formModalStyle } from "../common";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

export default function LiquidationApprovalModal({
  open,
  data,
  handleOpenState,
}: Props) {
  const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  if (!data) return null;

  const [entityId, projectIndex, contractIndex, liquidationIndex] =
    data.split("-");

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <LiquidationApprovalForm
            entityId={entityId}
            projectIndex={projectIndex}
            contractIndex={contractIndex}
            liquidationIndex={liquidationIndex}
            close={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  data: { [key: string]: any };
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
