import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { EntityForm } from "../../forms/elements/entity";
import { formModalStyle } from "../common";
import { LegalEntity } from "../../../contract/types";

export default function EntityModal({ data, open, handleOpenState }: Props) {
  const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <EntityForm update={data} redirect={0} close={handleClose} />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  data?: LegalEntity;
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
