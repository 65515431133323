import { useContext, useEffect } from "react";
import { DataContext } from "../../context/data";
import { PayeeDataGrid } from "../../components/datagrids";
import { Heading } from "../../components/profile";
import Loading from "../../layout/Loading";

const PayeeGridTest = () => {
  const context = useContext(DataContext);

  useEffect(() => {
    context.refreshAll();
  }, []);

  if (context.loading) return <Loading />;
  return (
    <div style={{ paddingBottom: 40 }}>
      <Heading title={`Payees`} extraText={"Create, edit and manage payees"} />
      <div style={{ height: 5 }} />
      <PayeeDataGrid
        payees={context.payees.filter((p) => p.name !== "termination_pool")}
        tags={context.tags}
      />
    </div>
  );
};

export default PayeeGridTest;
