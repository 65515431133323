import { object, string, array, optional, boolean, Schema, z } from "zod";
import { Snapshot } from "../../../api/post/types";
import {
  CommissionModel,
  CommissionModelIndividualMapping,
  CommissionModelPoolMapping,
  CommissionModelRoleMapping,
  CommissionOverride,
  CommissionRole,
  Contract,
  DiscretionaryCommission,
  ExtendedFeeType,
  Fee,
  LegalEntity,
  Liquidation,
  Payee,
  PayeePoolMapping,
  PayeeRoleAssignment,
  Project,
} from "../../../contract/types";
import {
  validateString,
  validateNumber,
  validatePercentage,
  validateDate,
  validateOptionalDate,
} from "./validationMethods";

const emptyStringToUndefined = z.literal("").transform(() => undefined);

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
  return schema.optional().or(emptyStringToUndefined);
}

export const entitySchema: Schema<LegalEntity> = object({
  name: validateString("Name", 32),
  address: validateString("Address", 100),
  tags: array(string()),
});

export const projectSchema: Schema<Project> = object({
  name: validateString("Name", 32),
  tags: array(string()),
});

export const snapshotSchema: Schema<Snapshot> = object({
  name: validateString("Name", 32),
});

export const extendedFeeType: Schema<ExtendedFeeType> = object({
  tokenFeeType: z.enum([
    "Fixed",
    "PercentOfSupplyAtDate",
    "PercentOfSupplyDynamic",
    "Cash",
  ]),
  atDate: validateOptionalDate().optional(),
  cashValue: optional(z.number().or(z.string())),
  percent: validatePercentage().optional(),
  manual: optional(boolean()),
})
  .refine(
    (data) => {
      if (data.tokenFeeType === "Cash") {
        return (
          data.tokenFeeType === "Cash" &&
          data.cashValue &&
          Number(data.cashValue) > 0
        );
      } else {
        return true;
      }
    },
    {
      message: "Invalid value",
      path: ["cashValue"],
    }
  )
  .refine(
    (data) => {
      if (
        data.tokenFeeType === "Cash" ||
        data.tokenFeeType === "PercentOfSupplyAtDate"
      ) {
        return !!data.atDate;
      } else {
        return true;
      }
    },
    {
      message: "Required",
      path: ["atDate"],
    }
  );

export const feeSchema: Schema<Fee> = object({
  name: validateString("Name", 32),
  quantity: z.number().or(z.string()).optional(),
  symbol: validateString("Symbol", 6),
  type: z.enum(["Cash", "Rewards", "Fund", "Token", "Reserve"]),
  feeData: optional(extendedFeeType),
  notes: optional(validateString("Notes", 2000)).or(z.literal("")),
  tags: array(string()),
})
  .refine(
    (data) => {
      if (data.type === "Cash") {
        return data.quantity && Number(data.quantity) > 0;
      } else {
        return true;
      }
    },
    {
      message: "Quantity required for cash fees",
      path: ["quantity"],
    }
  )
  .refine(
    (data) => {
      if (data.type === "Token" && data.feeData?.tokenFeeType === "Fixed") {
        return data.quantity && Number(data.quantity) > 0;
      } else {
        return true;
      }
    },
    {
      message: "Quantity required for fixed token fees",
      path: ["quantity"],
    }
  );

export const liquidationSchema: Schema<Liquidation> = object({
  name: validateString("Name", 200),
  quantity: validateNumber(),
  date: validateDate(),
  approved: optional(validateOptionalDate()),
  symbol: validateString("Symbol", 6),
  price: validateNumber(),
  type: z.enum(["Cash", "Rewards", "Fund", "Token", "Reserve"]),
  tags: array(string()),
});

export const commissionOverrideSchema: Schema<CommissionOverride> = object({
  name: validateString("Name", 32),
  reason: validateString("Reason", 32),
  percentage: asOptionalField(validatePercentage()),
  startDate: validateDate(),
  endDate: asOptionalField(validateOptionalDate()),
  payeeId: validateString("Payee", 32),
  poolIds: array(string()).optional(),
  tags: array(string()),
});

export const payeeRoleAssignmentSchema: Schema<PayeeRoleAssignment> = object({
  name: validateString("Name", 32),
  startDate: validateDate(),
  endDate: optional(validateOptionalDate()),
  payeeId: validateString("Payee", 32),
  commissionRoleId: validateString("Role", 32),
  tags: array(string()),
});

export const contractSchema: (cb: any) => Schema<Contract> = (cb) => {
  const schema: Schema<Contract> = object({
    name: validateString("Name", 200),
    startDate: validateDate(),
    endDate: validateDate(),
    businessLine: validateString("Business line", 32),
    contractType: validateString("Contract type", 32),
    baseContract: optional(validateString("Base contract", 32)),
    equityFee: optional(validatePercentage()).or(z.literal("")),
    cashTerms: optional(validateString("Cash terms", 3000)).or(z.literal("")),
    tokenTerms: optional(validateString("Token terms", 3000)).or(z.literal("")),
    vested: boolean().refine((val) => typeof val !== "undefined", {
      message: "Vested value is required",
    }),
    tags: array(string()),
    fees: array(feeSchema).optional(),

    liquidations: array(liquidationSchema).optional(),
    commissionOverrides: array(commissionOverrideSchema).optional(),
    payeeRoleAssignments: array(payeeRoleAssignmentSchema).optional(),
  }).refine((data) => {
    const remainingFees: { [key: string]: number } = {};
    if (data.fees && data.liquidations) {
      data.fees.forEach((f) => {
        if (!remainingFees[f.symbol]) {
          remainingFees[f.symbol] = Number(f.quantity);
        } else {
          remainingFees[f.symbol] += Number(f.quantity);
        }
      });

      data.liquidations.forEach((l) => {
        if (
          remainingFees[l.symbol] &&
          typeof remainingFees[l.symbol] === "number"
        ) {
          remainingFees[l.symbol] -= Number(l.quantity);
        }
      });

      const isNotValid = Object.keys(remainingFees).filter((key) => {
        if (remainingFees[key] < 0) {
          cb({ liquidatedTooMuch: key });
          return true;
        } else {
          return false;
        }
      });

      return isNotValid.length ? false : true;
    } else {
      return true;
    }
  });

  return schema;
};

export const commissionModelPoolMapping: Schema<CommissionModelPoolMapping> =
  object({
    commissionPoolId: validateString("Pool", 32),
    percentage: validatePercentage(),
    tags: array(string()),
  });

export const commissionModelRoleMapping: Schema<CommissionModelRoleMapping> =
  object({
    commissionRoleId: validateString("Role", 32),
    percentage: validatePercentage(),
    tags: array(string()),
  });

export const commissionModelIndividualMapping: Schema<CommissionModelIndividualMapping> =
  object({
    payeeId: validateString("Payee", 32),
    percentage: validatePercentage(),
    tags: array(string()),
  });

export const commissionModelSchema: Schema<CommissionModel> = object({
  name: validateString("Name", 32),
  startDate: validateDate(),
  endDate: validateDate(),
  commissionModelPoolMappings: array(commissionModelPoolMapping).optional(),
  commissionModelRoleMappings: array(commissionModelRoleMapping).optional(),
  commissionModelIndividualMappings: array(
    commissionModelIndividualMapping
  ).optional(),
  businessLine: validateString("Business line", 32),
  tags: array(string()),
});

export const discretionaryCommission: Schema<DiscretionaryCommission> = object({
  name: validateString("Name", 32),
  reason: validateString("Reason", 100),
  payeeId: string().nonempty("Payee is required"),
  percentage: validatePercentage(),
  tags: array(string()),
});

export const payeeSchema: Schema<Payee> = object({
  name: validateString("Name", 32),
  email: string().email(),
  startDate: validateDate(),
  endDate: optional(validateOptionalDate()),
  tags: array(string()),
});

export const payeePoolMappingSchema: Schema<PayeePoolMapping> = object({
  payeeId: validateString("Payee", 32),
  startDate: validateDate(),
  endDate: optional(validateOptionalDate()),
  tags: array(string()),
});

export const commissionPoolSchema = object({
  name: validateString("Name", 32),
  tags: array(string()),
  owners: array(string()),
  discretionary: boolean().refine((val) => typeof val !== "undefined", {
    message: "Discretionary value is required",
  }),
  commissionModelIds: optional(array(string())),
  payeeIds: optional(array(string())),
  discretionaryCommissions: optional(array(discretionaryCommission)),
  payeePoolMappings: optional(array(payeePoolMappingSchema)),
});

export const roleSchema: Schema<CommissionRole> = object({
  name: validateString("Name", 32),
  tags: array(string()),
});
