import { CommissionPool } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const poolInfoRows = (pools: CommissionPool[]) => {
  const rows: GridRowsProp = pools.map((pool) => {
    return {
      id: pool.id,
      name: pool.name,
      owners: pool.owners,
      tags: pool.tags,
    };
  });

  return rows;
};

export default poolInfoRows;
