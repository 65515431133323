import * as contract from "../../contract/types";
import * as query from "./types";
import { get } from "../../utils";

export async function getLegalEntity(
  token: string,
  query?: query.LegalEntity
): Promise<contract.LegalEntity[]> {
  return get("legalEntity", token, query).then((res) => {
    return res as contract.LegalEntity[];
  });
}

export async function getProject(
  token: string,
  query?: query.Project
): Promise<contract.Project[]> {
  return get("project", token, query).then((res) => {
    return res as contract.Project[];
  });
}

export async function getContract(
  token: string,
  query?: query.Contract
): Promise<contract.Contract[]> {
  return get("contract", token, query).then((res) => {
    return res as contract.Contract[];
  });
}

export async function getFee(
  token: string,
  query?: query.Fee
): Promise<contract.Fee[]> {
  return get("fee", token, query).then((res) => {
    return res as contract.Fee[];
  });
}

export async function getLiquidation(
  token: string,
  query?: query.Liquidation
): Promise<contract.Liquidation[]> {
  return get("liquidation", token, query).then((res) => {
    return res as contract.Liquidation[];
  });
}

export async function getCommissionModel(
  token: string,
  query?: query.CommissionModel
): Promise<contract.CommissionModel[]> {
  return get("commissionModel", token, query).then((res) => {
    return res as contract.CommissionModel[];
  });
}

export async function getCommissionPool(
  token: string,
  query: query.CommissionPool
): Promise<contract.CommissionPool[]> {
  return get("commissionPool", token, query).then((res) => {
    return res as contract.CommissionPool[];
  });
}

export async function getCommissionRole(
  token: string,
  query: query.CommissionRole
): Promise<contract.CommissionRole[]> {
  return get("commissionRole", token, query).then((res) => {
    return res as contract.CommissionRole[];
  });
}

export async function getCommissionOverride(
  token: string,
  query: query.CommissionOverride
): Promise<contract.CommissionOverride[]> {
  return get("commissionOverride", token, query).then((res) => {
    return res as contract.CommissionOverride[];
  });
}

export async function getPayee(
  token: string,
  query: query.Payee
): Promise<contract.Payee[]> {
  return get("payee", token, query).then((res) => {
    return res as contract.Payee[];
  });
}

export async function getCommissionData(
  token: string,
  query: query.CommissionData
): Promise<contract.CommissionData[]> {
  return get("commission", token, query).then((res) => {
    return res as contract.CommissionData[];
  });
}

export async function getSnapshots(
  token: string
): Promise<contract.Snapshot[]> {
  return get("snapshot", token).then((res) => {
    return res as contract.Snapshot[];
  });
}

export async function getPendingSnapshot(
  token: string
): Promise<contract.PendingSnapshot[]> {
  return get("snapshot?simulate=true", token).then((res) => {
    return res as contract.PendingSnapshot[];
  });
}

export async function getTags(token: string): Promise<string[]> {
  return get("tags", token).then((res) => {
    return res as string[];
  });
}
