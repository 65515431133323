import { useState } from "react";
import { login } from "../../components/login/GoogleSignin";
import { Card, CardContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Card
          style={{ minWidth: 330, borderRadius: 10, padding: 10 }}
          elevation={5}
        >
          <CardContent>
            <div
              style={{ textAlign: "center", paddingTop: 10, paddingBottom: 40 }}
            >
              <img
                src="/images/republic-crypto-r-logo.svg"
                alt="Republic"
                style={{
                  height: 95,
                  marginTop: 10,
                  marginBottom: 30,
                }}
              />
            </div>
            <div style={{ textAlign: "center", paddingBottom: 0 }}>
              <LoadingButton
                onClick={() => login(setLoading)}
                loading={loading}
                variant="outlined"
                color="primary"
                sx={{ color: "white", mb: 1 }}
                fullWidth
              >
                Login with Okta
              </LoadingButton>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
