import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import { tagsColDef, actionsColDef } from "./common";

const projectColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    { field: "name", headerName: "Name", width: 420 },
    { ...tagsColDef },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default projectColDef;
