import { ProjectForm } from "../../components/forms/elements/project";
import { Navigate, useParams } from "react-router-dom";
import { LegalEntity } from "../../contract/types";
import { Project } from "../../contract/types";

const ModifyEntity = () => {
  const params = useParams();

  if (!params.entityId || !params.entityData || !params.projectIndex) {
    console.error("Data not provided");
    return <Navigate to="/entity" />;
  }

  const entityData = JSON.parse(params.entityData) as unknown as LegalEntity;
  const projectIndex = Number(params.projectIndex);
  const updateProject: Project | undefined =
    entityData.projects?.[projectIndex];

  if (!updateProject) {
    console.error("Project does not exist");
    return <Navigate to="/entity" />;
  }

  return null;
};

export default ModifyEntity;
