import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Chip } from "@mui/material";

const emailColDef = {
  field: "email",
  headerName: "Email",
  width: 315,
  renderCell: (params: GridRenderCellParams<any>) => (
    <div>
      <Chip
        label={params.value}
        style={{ margin: 2 }}
        key={(Math.random() + 1).toString(36).substring(7)}
        size="small"
      />
    </div>
  ),
};

export default emailColDef;
