import { GridRowsProp } from "@mui/x-data-grid-pro";

const liquidationPendingListRows = (liquidations: any[]) => {
  const rows: GridRowsProp = liquidations.map((liquidation) => {
    return {
      ...liquidation,
      date: new Date(liquidation.date),
    };
  });

  return rows;
};

export default liquidationPendingListRows;
