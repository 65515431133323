import { GridRowsProp } from "@mui/x-data-grid-pro";

const modelLinkedPoolRows = (pools: any[]) => {
  const rows: GridRowsProp = pools.map((pool, i) => {
    return {
      id: i,
      name: pool.name,
      percentage: pool.percentage,
      tags: pool.tags,
      poolId: pool.id,
    };
  });

  return rows;
};

export default modelLinkedPoolRows;
