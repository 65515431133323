import { GridRowsProp } from "@mui/x-data-grid-pro";

const contractFeeRows = (fees: any[]) => {
  const rows: GridRowsProp = fees.map((fee, i) => {
    return {
      id: i,
      ...fee,
    };
  });

  return rows;
};

export default contractFeeRows;
