import { useContext, useEffect } from "react";
import { DataContext } from "../../context/data";
import { RoleDataGrid } from "../../components/datagrids";
import { Heading } from "../../components/profile";
import Loading from "../../layout/Loading";

const RoleGridTest = () => {
  const { loading, tags, roles, payees, models, refreshAll } =
    useContext(DataContext);

  useEffect(() => {
    refreshAll();
  }, []);

  if (loading) return <Loading />;
  return (
    <div>
      <Heading
        title={"Role Titles"}
        extraText={"Titles can be assigned to individuals in a contract."}
      />
      <div style={{ height: 5 }} />
      <RoleDataGrid roles={roles} tags={tags} payees={payees} models={models} />
    </div>
  );
};

export default RoleGridTest;
