import { PoolModal, DeleteByIndexModal } from "../../modals";
import {
  GridBase,
  poolDiscretionaryCommissionColDef,
  poolDiscretionaryCommissionRows,
} from "..";
import { putCommissionPool } from "../../../api/put";
import { CommissionPool } from "../../../contract/types";

const PoolDiscretionaryCommissionDataGrid = ({
  rules,
  poolData,
  deletePath,
}: Props) => {
  return (
    <GridBase
      name="Discretionary Commission"
      FormModal={PoolModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putCommissionPool}
      data={rules}
      rowFormatter={poolDiscretionaryCommissionRows}
      colDef={poolDiscretionaryCommissionColDef}
      deleteById={false}
      formType="commissions"
      poolData={poolData}
      deletePath={deletePath}
      objectToEdit={poolData}
      deleteType="pools"
      initialState={{
        sorting: {
          sortModel: [{ field: "payeeName", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  rules: any[];
  tags?: string[];
  poolData: CommissionPool;
  deletePath: string;
}

export default PoolDiscretionaryCommissionDataGrid;
