import { GridRowsProp } from "@mui/x-data-grid-pro";

const commissionPaymentRows = (events: any[]) => {
  const rows: GridRowsProp = events.map((event, i) => {
    return {
      id: i,
      ...event,
    };
  });

  return rows;
};

export default commissionPaymentRows;
