import { ModelModal, DeleteByIndexModal } from "../../modals";
import { GridBase, modelRoleColDef, modelRoleRows } from "..";
import { putCommissionModel } from "../../../api/put";
import { CommissionModel } from "../../../contract/types";

const ModelRoleDataGrid = ({ roles, modelData, deletePath }: Props) => {
  return (
    <GridBase
      name="Role"
      FormModal={ModelModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putCommissionModel}
      data={roles}
      rowFormatter={modelRoleRows}
      colDef={modelRoleColDef}
      deleteById={false}
      formType="roles"
      modelData={modelData}
      deletePath={deletePath}
      objectToEdit={modelData}
      deleteType="models"
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  roles: any[];
  modelData: CommissionModel;
  deletePath: string;
}

export default ModelRoleDataGrid;
