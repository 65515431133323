import { LegalEntity, Project } from "../../../contract/types";
import { ProjectModal, DeleteByIndexModal } from "../../modals";
import { GridBase, projectColDef, projectRows } from "..";
import { putLegalEntity } from "../../../api/put";
import { useNavigate } from "react-router-dom";

const ProjectDataGrid = ({
  projects,
  legalEntityData,
  legalEntityId,
  deletePath,
  objectToEdit,
}: Props) => {
  const navigate = useNavigate();

  const onClick = (data: any) => {
    const url = `${data.row.id}/contracts`;
    navigate(url);
  };

  return (
    <GridBase
      name="Project"
      rowFormatter={projectRows}
      FormModal={ProjectModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putLegalEntity}
      data={projects}
      colDef={projectColDef}
      deleteById={false}
      deletePath={deletePath}
      onClick={onClick}
      legalEntityData={legalEntityData}
      legalEntityId={legalEntityId}
      objectToEdit={objectToEdit}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      deleteType="entities"
    />
  );
};

interface Props {
  projects: Project[];
  legalEntityData: LegalEntity;
  legalEntityId: string;
  deletePath: string;
  objectToEdit: { [key: string]: any };
}

export default ProjectDataGrid;
