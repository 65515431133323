import { Payee } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const poolPayeeRows = (payees: RoleData[]) => {
  const rows: GridRowsProp = payees.map((payee, i) => {
    return {
      id: i,
      name: payee.name,
      roleTitle: payee.roleTitle,
      payeeName: payee.payeeName,
      email: payee.email,
      startDate: new Date(payee.startDate),
      endDate: payee.endDate ? new Date(payee.endDate) : null,
      tags: payee.tags,
    };
  });

  return rows;
};

interface RoleData extends Payee {
  roleTitle: string;
  payeeName: string;
}

export default poolPayeeRows;
