import { useEffect, useState, useContext } from 'react'
import {
  GridApi,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro'
import { SnapshotModal } from '../../modals'
import { AuthContext } from '../../../context/role'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'

function GridToolbar(props: EditToolbarProps) {
  const [openSnapshot, setOpenSnapshot] = useState<boolean>(false)
  const [snapshotData, setSnapshotData] = useState<any[] | undefined>()
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const authState = useContext(AuthContext)

  useEffect(() => {
    if (
      authState.role === 'admin' ||
      authState.role === 'finance' ||
      authState.role === 'contractViewer'
    ) {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [authState])

  const { name, apiRef, setOpenFormModal, setDocToUpdate, createSnapshot, selectionModel } = props

  const newPayee = () => {
    setDocToUpdate(undefined)
    setOpenFormModal(true)
  }

  const saveSnapshot = () => {
    setOpenSnapshot(true)
  }

  useEffect(() => {
    setSnapshotData(selectionModel)
  }, [selectionModel])

  return (
    <GridToolbarContainer
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 10,
        paddingLeft: !name ? 10 : 0,
        paddingBottom: 10,
        marginLeft: !name ? 0 : -5,
      }}
    >
      <div style={{ marginRight: 'auto' }}>
        {name && (
          <Button color='primary' startIcon={<AddIcon />} onClick={newPayee} disabled={!isAdmin}>
            Add {name}
          </Button>
        )}
        {createSnapshot && (
          <Button
            color='primary'
            startIcon={<AddIcon />}
            onClick={saveSnapshot}
            disabled={!isAdmin}
          >
            Save/Edit Snapshot
          </Button>
        )}
        <GridToolbarExport
          csvOptions={{
            fileName: `${new Date().toDateString()}`,
          }}
        />
        <GridToolbarFilterButton />
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <GridToolbarQuickFilter variant='outlined' size='small' />
      </div>
      <SnapshotModal
        open={openSnapshot}
        snapshotData={snapshotData ?? []}
        handleOpenState={setOpenSnapshot}
      />
    </GridToolbarContainer>
  )
}

interface EditToolbarProps {
  name: string
  apiRef: React.MutableRefObject<GridApi>
  setOpenFormModal: (value: React.SetStateAction<boolean>) => void
  setDocToUpdate: (value: React.SetStateAction<any>) => void
  createSnapshot?: boolean
  selectionModel?: any[]
}

export default GridToolbar
