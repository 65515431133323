import { CommissionModel } from "../../../contract/types";
import { ModelModal, DeleteByIndexModal } from "../../modals";
import { GridBase, modelLinkedPoolColDef, modelLinkedPoolRows } from "..";
import { putCommissionModel } from "../../../api/put";
import { useNavigate } from "react-router-dom";

const ModelLinkedPoolDataGrid = ({ pools, modelData, deletePath }: Props) => {
  const navigate = useNavigate();

  const onClick = (data: any) => {
    const id: string = data.row.poolId;
    const url = `/pool/update/${id}/data`;
    navigate(url);
  };

  return (
    <GridBase
      name="Pool"
      FormModal={ModelModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putCommissionModel}
      data={pools}
      rowFormatter={modelLinkedPoolRows}
      colDef={modelLinkedPoolColDef}
      deleteById={false}
      formType="pools"
      modelData={modelData}
      onClick={onClick}
      deletePath={deletePath}
      objectToEdit={modelData}
      deleteType="models"
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  pools: any[];
  modelData: CommissionModel;
  deletePath: string;
}

export default ModelLinkedPoolDataGrid;
