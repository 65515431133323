import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import { nameColDef, tagsColDef, actionsColDef, LinkChipMemo } from "./common";

const entityInfoColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    { field: "name", headerName: "Legal Name", width: 280 },
    {
      field: "projects",
      headerName: "Projects",
      width: 250,
      renderCell: ({ id, value }) =>
        value.map((p: string, i: number) => (
          <LinkChipMemo
            label={p}
            variant="outlined"
            url={`/entity/${id}/projects/data/${i}/contracts`}
            key={(Math.random() + 1).toString(36).substring(7)}
          />
        )),
    },
    { field: "address", headerName: "Address", width: 280 },
    { ...tagsColDef },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default entityInfoColDef;
