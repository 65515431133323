import { useContext, useRef, useState, useEffect, useCallback } from "react";
import { DataContext } from "../../context/data";
import { ContractListDataGrid } from "../../components/datagrids";
import Loading from "../../layout/Loading";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

function monthDiff(d1: Date, d2: Date) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

const ListAllContract = () => {
  const context = useContext(DataContext);
  const { loading, entities, models, payees, roles, refreshAll } = context;

  const [contracts, setContracts] = useState<any[]>();

  useEffect(() => {
    refreshAll();
  }, []);

  const processData = useCallback(() => {
    let contractList: any[] = [];

    entities.forEach((entity, i) => {
      entity.projects?.forEach((project, j) => {
        project.contracts?.forEach((contract, k) => {
          const contractData: { [key: string]: any } = {
            id: contract.id ? contract.id : k,
            contractId: `0x${
              contract.id ? contract.id.slice(0, 7) : "00000" + k.toString()
            }`,
            name: contract.name,
            project: project.name,
            legalName: entity.name,
            contractDate: new Date(contract.startDate),
            contractEndDate: new Date(contract.endDate),
            termMonths: monthDiff(
              new Date(contract.startDate),
              new Date(contract.endDate)
            ),
            businessLine: contract.businessLine
              .match(/[A-Z][a-z]+|[0-9]+/g)
              ?.join(" "),
            baseContract: contract.baseContract,
            contractType: contract.contractType,
            commissionModel: [],
            cash: 0,
            tokens: 0,
            symbol: "",
            rewards: 0,
            fund: 0,
            tokenPercent: "",
            equityFee: contract.equityFee ?? 0,
            vested: contract.vested,
            cashTerms: contract.cashTerms,
            tokenTerms: contract.tokenTerms,
            roleAssignments: [],
            commissionOverrides: [],
            tags: contract.tags,

            /* Extra info for ease of linking/editing */
            entityId: entity.id,
            projectIndex: j,
            contractIndex: k,
          };

          /* Work out which models apply */
          const applicableModels = models.map((model) => {
            if (model.businessLine === contract.businessLine) {
              const modelStartDate = new Date(model.startDate).getTime();
              const modelEndDate = model.endDate
                ? new Date(model.endDate).getTime()
                : new Date().getTime();
              const contractStartDate = contractData.contractDate.getTime();

              /* If contract start or end date is in the active range of the model it's applicable */
              if (
                contractStartDate >= modelStartDate &&
                contractStartDate <= modelEndDate
              ) {
                return { name: model.name, id: model.id };
              } else {
                return false;
              }
            } else {
              return false;
            }
          });

          /* Remove empty values for contracts with no model in range */
          contractData.commissionModel = applicableModels.filter((v) => v);

          /* Calculate total cash and token values */
          contract.fees?.forEach((fee) => {
            if (typeof fee.manual === "undefined" && fee.type === "Token") {
              if (fee.feeData && fee.feeData.tokenFeeType !== "Fixed") {
                contractData.manual = false;
              } else {
                contractData.manual = true;
              }
            }

            switch (fee.type) {
              case "Cash":
                contractData.cash += fee.quantity;
                break;
              case "Rewards":
                contractData.rewards += fee.quantity;
                break;
              case "Token":
                if (fee.quantity) {
                  contractData.tokens += fee.quantity;
                  contractData.symbol = fee.symbol;
                }
                if (fee.feeData) {
                  const suffix =
                    fee.feeData.tokenFeeType === "PercentOfSupplyAtDate"
                      ? fee.feeData.atDate
                        ? `on ${new Date(
                            fee.feeData.atDate
                          ).toLocaleDateString()}`
                        : "Pending"
                      : fee.feeData.tokenFeeType === "PercentOfSupplyDynamic"
                      ? "ongoing"
                      : fee.feeData.tokenFeeType === "Fixed"
                      ? "Fixed amount"
                      : fee.feeData.tokenFeeType === "Cash"
                      ? "in tokens"
                      : "--";
                  contractData.tokenPercent = `${
                    fee.feeData.percent ?? fee.feeData.cashValue
                  }% ${suffix}`;

                  contractData.tokenPercent =
                    fee.feeData.tokenFeeType === "Fixed"
                      ? suffix
                      : fee.feeData.tokenFeeType === "Cash"
                      ? `${currencyFormatter(
                          fee.feeData.cashValue
                            ? Number(fee.feeData.cashValue)
                            : 0
                        )} in tokens`
                      : `${
                          fee.feeData.percent ?? fee.feeData.cashValue
                        }% ${suffix}`;
                }

                break;
              case "Fund":
                contractData.fund += fee.quantity;
                break;
            }
          });

          if (typeof contractData.manual === "undefined") {
            contractData.manual = true;
            contractData.tokenPercent = "--";
          }

          /* Map overrides to payees */
          contractData.commissionOverrides = contract.commissionOverrides
            ? contract.commissionOverrides.map((override) => {
                const payeeIndex = payees.findIndex(
                  (payee) => payee.id === override.payeeId
                );
                const payee = payees[payeeIndex];

                const output = {
                  name: payee ? payee.name : "DATA MISSING",
                  percentage: override.percentage,
                };

                return output;
              })
            : [];

          /* Map roles to titles and payees */
          contractData.roleAssignments = contract.payeeRoleAssignments
            ? contract.payeeRoleAssignments.map((assignment) => {
                const payeeIndex = payees.findIndex(
                  (payee) => payee.id === assignment.payeeId
                );
                const roleIndex = roles.findIndex(
                  (role) => role.id === assignment.commissionRoleId
                );

                const payee = payees[payeeIndex];
                const role = roles[roleIndex];

                const output = {
                  title: role ? role.name : "DATA MISSING",
                  name: payee ? payee.name : "DATA MISSING",
                };

                return output;
              })
            : [];

          contractList.push(contractData);
        });
      });
    });
    setContracts(contractList);
    firstLoad.current = false;
  }, [context]);

  const firstLoad = useRef<boolean>(true);
  const firstUpdate = useRef<boolean>(true);

  if (firstLoad.current && !loading) {
    firstLoad.current = false;
    processData();
  }

  useEffect(() => {
    if (!firstUpdate.current) {
      processData();
    } else if (!firstLoad.current && !loading) {
      firstUpdate.current = false;
    }
  }, [context]);

  if (loading || contracts === undefined) return <Loading />;

  return (
    <div
      style={{
        marginTop: -28,
        marginLeft: -40,
        marginRight: -40,
        marginBottom: -49,
        height: "calc(100% + 55px)",
        width: "calc(100% + 80px)",
        overflow: "hidden",
      }}
    >
      <ContractListDataGrid contracts={contracts!} />
    </div>
  );
};

export default ListAllContract;
