import { del } from "../../utils";

export async function delLegalEntity(
  id: string,
  token: string
): Promise<boolean> {
  return del(`legalentity/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delProject(id: string, token: string): Promise<boolean> {
  return del(`project/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delContract(id: string, token: string): Promise<boolean> {
  return del(`contract/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delCommissionPool(
  id: string,
  token: string
): Promise<boolean> {
  return del(`commissionPool/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delCommissionModel(
  id: string,
  token: string
): Promise<boolean> {
  return del(`commissionModel/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delCommissionRole(
  id: string,
  token: string
): Promise<boolean> {
  return del(`commissionRole/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delPayee(id: string, token: string): Promise<boolean> {
  return del(`payee/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delCommissionData(
  id: string,
  token: string
): Promise<boolean> {
  return del(`commission/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delSnapshot(id: string, token: string): Promise<boolean> {
  return del(`snapshot/${id}`, token).then((res) => {
    return res.status === 200;
  });
}

export async function delTag(tag: string, token: string): Promise<boolean> {
  return del("tags", token, { tag }).then((res) => {
    return res.status === 200;
  });
}
