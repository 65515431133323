import { string, number, date, z } from "zod";

export const validateNumber = () => {
  return string()
    .or(number())
    .refine(
      (val) => !Number.isNaN(parseInt(String(val), 10)) && Number(val) >= 0,
      {
        message: "Invalid number",
      }
    )
    .transform((val) => Number(val));
};

export const validatePercentage = () => {
  return string()
    .or(number())
    .refine(
      (val) =>
        !Number.isNaN(parseInt(String(val), 10)) &&
        Number(val) >= 0 &&
        Number(val) <= 100,
      {
        message: "Must be a number between 0 and 100",
      }
    )
    .transform((val) => Number(val));
};

export const validateString = (fieldName: string, maxLength: number) => {
  return string()
    .nonempty(`${fieldName} is required`)
    .max(maxLength, `${fieldName} must be less than ${maxLength} characters`);
};

export const validateDate = () => {
  return date()
    .or(string())
    .refine(
      (val) => {
        if (typeof val === "string") {
          return !!isNaN(Date.parse(val)) && val !== "";
        } else {
          return true;
        }
      },
      {
        message: "Invalid date",
      }
    );
};

export const validateOptionalDate = () => {
  return date()
    .or(string())
    .or(z.null())
    .refine(
      (val) => {
        if (typeof val === "string") {
          return !!isNaN(Date.parse(val));
        } else {
          return true;
        }
      },
      {
        message: "Invalid date",
      }
    );
};
