import { GridColumns } from "@mui/x-data-grid-pro";

function caps(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

const liquidationsColDef = () => {
  const columns: GridColumns = [
    { field: "date", headerName: "Sell Date", type: "date", width: 110 },
    {
      field: "value",
      headerName: "Value",
      width: 100,
      valueFormatter: ({ value }) =>
        typeof value === "number"
          ? currencyFormatter(value)
          : currencyFormatter(0),
    },
    { field: "projectName", headerName: "Project", width: 150 },
    { field: "contractName", headerName: "Contract", width: 180 },
    { field: "quantity", headerName: "Quantity", width: 100 },
    { field: "symbol", headerName: "Symbol", width: 100 },
    {
      field: "sellPrice",
      headerName: "Sell Price",
      valueFormatter: ({ value }) =>
        currencyFormatter(value ? Number(value) : 0),
      width: 100,
    },

    {
      field: "commissionType",
      headerName: "Type",
      valueFormatter: ({ value }: { value: string }) => caps(value),
      width: 100,
    },
    { field: "commissionTypeName", headerName: "Title", width: 150 },

    {
      field: "percentage",
      headerName: "Percent",
      valueFormatter: ({ value }) => `${value}%`,
      width: 100,
    },
  ];

  return columns;
};

export default liquidationsColDef;
