import { useCallback, createContext, useState, useEffect, useRef } from "react";
import {
  getPayee,
  getCommissionRole,
  getCommissionPool,
  getCommissionModel,
  getLegalEntity,
  getTags,
  getSnapshots,
} from "../api/get";
import {
  CommissionModel,
  CommissionPool,
  Payee,
  CommissionRole,
  LegalEntity,
  Snapshot,
} from "../contract/types";
import { getAuth, getIdToken } from "firebase/auth";
import { app } from "../components/firebase";

const initialState = {
  loading: true,
  payees: [],
  roles: [],
  pools: [],
  models: [],
  entities: [],
  snapshots: [],
  tags: [],
  update: () => {},
  refreshAll: () => null,
};

export const DataContext = createContext<ContextProps>(initialState);

export const DataContextProvider = (props: Props) => {
  const [values, setValues] = useState<ContextProps>(initialState);
  const [token, setToken] = useState<string>();

  /* Checks if the token has changed and either updates it to trigger a refresh or fetches the data using the existing token */
  const refreshAll = useCallback(async () => {
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const currentToken = await getIdToken(user);
      if (token !== currentToken) {
        setToken(token);
      } else {
        fetchData();
      }
    }
  }, [token]);

  const fetchData = useCallback(async () => {
    if (!token) throw new Error("No token to fetch!");

    const [
      payeeData,
      roleData,
      poolData,
      modelData,
      entityData,
      tagData,
      snapshotData,
    ] = await Promise.all([
      getPayee(token, {}),
      getCommissionRole(token, {}),
      getCommissionPool(token, {}),
      getCommissionModel(token, {}),
      getLegalEntity(token, {}),
      getTags(token),
      props.role !== "contractOnly" ? getSnapshots(token) : [],
    ]);

    setValues({
      loading: false,
      payees: payeeData
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((p) => p.name !== "termination_pool"),
      roles: roleData.sort((a, b) => a.name.localeCompare(b.name)),
      pools: poolData.sort((a, b) => a.name.localeCompare(b.name)),
      models: modelData.sort((a, b) => a.name.localeCompare(b.name)),
      entities: entityData,
      tags: tagData.sort((a, b) => a.localeCompare(b)),
      snapshots: snapshotData.sort((a, b) => a.name.localeCompare(b.name)),
      update: update,
      refreshAll: refreshAll,
    });
  }, [token]);

  const update = useCallback(
    (
      value:
        | Payee
        | CommissionRole
        | CommissionPool
        | CommissionModel
        | LegalEntity
        | Snapshot
        | string,
      type:
        | "payees"
        | "roles"
        | "pools"
        | "models"
        | "entities"
        | "snapshots"
        | "tags",
      id: string
    ) => {
      setValues((prevState) => {
        const newState = {
          ...prevState,
        };
        let itemIndex: number;
        if (typeof value === "string") {
          // For tags only
          itemIndex = newState[type].length;
        } else {
          itemIndex = newState[type].findIndex((item: any) => item.id === id);
        }
        // Check if it is a new item
        if (id && itemIndex === -1) {
          // @ts-ignore
          newState[type].push({ ...value, id: id });
        } else {
          newState[type][itemIndex] = value;
        }
        return newState;
      });
    },
    []
  );

  const firstStart = useRef<boolean>(true);

  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <DataContext.Provider value={values}>{props.children}</DataContext.Provider>
  );
};

interface Props {
  role: string;
  children: JSX.Element | JSX.Element[];
}

export interface ContextProps {
  loading: boolean;
  tags: string[];
  roles: CommissionRole[];
  models: CommissionModel[];
  pools: CommissionPool[];
  entities: LegalEntity[];
  payees: Payee[];
  snapshots: Snapshot[];
  update: (
    value:
      | Payee
      | CommissionRole
      | CommissionPool
      | CommissionModel
      | LegalEntity
      | string,
    type:
      | "payees"
      | "roles"
      | "pools"
      | "models"
      | "entities"
      | "snapshots"
      | "tags",
    id: string
  ) => void;
  refreshAll: () => Promise<void> | null;
}
