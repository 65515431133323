import React, { useContext } from "react";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid-pro";
import { DataContext } from "../../../../context/data";
import ApproveIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

const ApproveColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const { snapshots } = useContext(DataContext);

  const actions = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }: any) => {
      const snapshot = snapshots.find((s) => s.id === id);
      const approved = snapshot && snapshot.approved ? true : false;

      return [
        <GridActionsCellItem
          icon={
            <ApproveIcon
              color="success"
              style={{ opacity: !approved ? 1 : 0.3 }}
            />
          }
          label="Approve"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
          disabled={approved}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon style={{ opacity: !approved ? 1 : 0.3 }} />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="error"
          disabled={approved}
        />,
      ];
    },
  };
  return actions;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default ApproveColDef;
