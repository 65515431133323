import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../../context/data";
import { PaymentEventDataGrid } from "../../components/datagrids";
import Loading from "../../layout/Loading";

const Payments = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentsRows, setPaymentsRows] = useState<PaymentRow[]>([]);

  const params = useParams();
  const snapshotId = params.snapshotId;
  const context = useContext(DataContext);

  useEffect(() => {
    if (
      context.snapshots &&
      context.snapshots.length > 0 &&
      context.payees &&
      context.payees.length > 0
    ) {
      const snapshotIndex = context.snapshots.findIndex(
        (s) => s.id === snapshotId
      );
      const snapshot = context.snapshots[snapshotIndex];
      const paymentEventRows: PaymentRow[] = [];
      const payeeIds: string[] = [];

      snapshot.payouts.forEach((payout) => {
        const payeeIndex = context.payees.findIndex(
          (p) => p.id === payout.payeeId
        );

        if (!payeeIds.includes(payout.payeeId)) {
          payeeIds.push(payout.payeeId);
          paymentEventRows.push({
            name: payout.payeeName,
            email: context.payees?.[payeeIndex]
              ? context.payees[payeeIndex].email
              : "DELETED",
            value: Number(payout.valueUsd),
            approved: new Date(snapshot.approved),
          });
        } else {
          const index = payeeIds.findIndex((p) => p === payout.payeeId);
          paymentEventRows[index].value += Number(payout.valueUsd);
        }
      });

      setPaymentsRows(paymentEventRows);
      setLoading(false);
    }
  }, [context]);

  if (loading) return <Loading />;
  return (
    <div
      style={{
        marginTop: -28,
        marginLeft: -40,
        marginRight: -40,
        marginBottom: -49,
        height: "calc(100% + 55px)",
        width: "calc(100% + 80px)",
        overflow: "hidden",
      }}
    >
      <PaymentEventDataGrid events={paymentsRows} />
    </div>
  );
};

interface PaymentRow {
  name: string;
  email: string;
  value: number;
  approved: Date | string;
}

export default Payments;
