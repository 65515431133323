// import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { PayeeForm } from "../../forms/elements/payee";
import { formModalStyle } from "../common";
import { Payee } from "../../../contract/types";

export default function PayeeModal({ data, open, handleOpenState }: Props) {
  /** Use to avoid the menu being closed while a sending is happening */
  // const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <PayeeForm update={data} redirect={0} close={handleClose} />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  data?: Payee;
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
