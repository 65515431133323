import { useState, useContext } from "react";
import { DataContext } from "../../context/data";
import { ContractModal } from "../../components/modals";
import { useParams, Navigate } from "react-router-dom";
import { Heading, Subheading } from "../../components/profile";
import { FormNestedBorder } from "../../components/forms/common";
import {
  ContractCommissionOverrideDataGrid,
  ContractFeeDataGrid,
  ContractLiquidationDataGrid,
  ContractRoleAssignmentDataGrid,
} from "../../components/datagrids";
import Loading from "../../layout/Loading";
import { Typography } from "@mui/material";
import { currencyFormatter } from "../../utils";

const ModifyContract = () => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const { loading, payees, roles, entities } = useContext(DataContext);

  const params = useParams();
  if (
    !params.entityId ||
    !params.entityData ||
    !params.projectIndex ||
    !params.contractIndex
  ) {
    console.error("Data not provided");
    return <Navigate to="/entity" />;
  }

  if (loading) return <Loading />;

  const projectIndex = Number(params.projectIndex);
  const contractIndex = Number(params.contractIndex);
  const entityIndex = entities.findIndex((e) => e.id === params.entityId);
  const entityData = entities[entityIndex];
  const contractData =
    entities[entityIndex]?.projects?.[projectIndex]?.contracts?.[contractIndex];

  const feeDeletePath = `projects.${projectIndex}.contracts.${contractIndex}.fees`;
  const roleDeletePath = `projects.${projectIndex}.contracts.${contractIndex}.payeeRoleAssignments`;
  const overrideDeletePath = `projects.${projectIndex}.contracts.${contractIndex}.commissionOverrides`;
  const liquidationDeletePath = `projects.${projectIndex}.contracts.${contractIndex}.liquidations`;

  if (!contractData) throw new Error("Contract doesn't exist");

  const payeeIdMap = payees!.map((payee) => payee.id);
  const roleIdMap = roles!.map((role) => role.id);
  const remainingFees: { [key: string]: number } = {};

  const contractFees = contractData.fees?.map((f) => {
    const fee: { [key: string]: any } = {
      ...f,
      quantity: f.quantity ? f.quantity : "Pending",
    };

    /* Calculate total fees */
    if (!remainingFees[f.symbol]) {
      remainingFees[f.symbol] = Number(f.quantity);
    } else {
      remainingFees[f.symbol] += Number(f.quantity);
    }

    if (f.feeData?.atDate) fee.atDate = new Date(f.feeData.atDate);
    if (typeof fee.manual === "undefined") {
      switch (fee.type) {
        case "Cash":
          fee.manual = true;
          break;
        case "Token":
          if (fee.feeData && fee.feeData.tokenFeeType !== "Fixed") {
            fee.manual = false;
          } else {
            fee.manual = true;
          }
          break;
      }
    }
    if (fee.feeData) {
      const suffix =
        fee.feeData.tokenFeeType === "PercentOfSupplyAtDate"
          ? `at date`
          : fee.feeData.tokenFeeType === "PercentOfSupplyDynamic"
          ? "ongoing"
          : fee.feeData.tokenFeeType === "Fixed"
          ? "Fixed amount"
          : fee.feeData.tokenFeeType === "Cash"
          ? "in tokens"
          : "";

      fee.tokenTerms =
        fee.feeData.tokenFeeType === "Fixed"
          ? suffix
          : fee.feeData.tokenFeeType === "Cash"
          ? `${currencyFormatter(
              fee.feeData.cashValue ? Number(fee.feeData.cashValue) : 0
            )} in tokens`
          : `${fee.feeData.percent ?? fee.feeData.cashValue}% ${suffix}`;
    } else {
      fee.tokenTerms = "--";
    }

    return fee;
  });

  /* Calculate how much is left to liquidate */
  const liquidations =
    entityData.projects?.[projectIndex]?.contracts?.[contractIndex]
      .liquidations ?? [];

  liquidations.forEach((l) => {
    if (
      remainingFees[l.symbol] &&
      typeof remainingFees[l.symbol] === "number"
    ) {
      remainingFees[l.symbol] -= Number(l.quantity);
    }
  });

  const roleAssignments = contractData.payeeRoleAssignments?.map(
    (assignment) => {
      const payee = payees![payeeIdMap.indexOf(assignment.payeeId)];
      const role = roles![roleIdMap.indexOf(assignment.commissionRoleId)];

      const data = {
        ...assignment,
        roleTitle: role.name,
        payeeName: payee?.name ?? "DELETED",
        email: payee?.email ?? "DELETED",
      };

      return data;
    }
  );

  const commissionOverrides = contractData.commissionOverrides?.map((rule) => {
    const payee = payees![payeeIdMap.indexOf(rule.payeeId)];

    const data = {
      ...rule,
      payeeName: payee?.name ?? "DELETED",
      email: payee?.email ?? "DELETE",
    };

    return data;
  });

  return (
    <>
      <Heading
        title={
          entityData.projects![projectIndex].contracts![contractIndex].name
        }
        tags={entityData.projects![projectIndex].contracts![contractIndex].tags}
        handleOpenState={setOpenEdit}
      />
      <Subheading title="Cash Terms" />
      <div style={{ height: 10 }} />
      <FormNestedBorder uid="1">
        <Typography sx={{ pb: 0.2 }}>
          {entityData.projects![projectIndex].contracts![contractIndex]
            .cashTerms ?? "None"}
        </Typography>
      </FormNestedBorder>
      <div style={{ height: 15 }} />

      <Subheading title="Token Terms" />
      <div style={{ height: 10 }} />
      <FormNestedBorder uid="2">
        <Typography sx={{ pb: 0.2 }}>
          {entityData.projects?.[projectIndex]?.contracts?.[contractIndex]
            ?.tokenTerms
            ? entityData.projects?.[projectIndex]?.contracts?.[contractIndex]
                ?.tokenTerms
            : "None"}
        </Typography>
      </FormNestedBorder>
      <div style={{ height: 20 }} />

      <Subheading
        title={contractData.contractType === "Staking" ? "Rewards" : "Payment"}
      />
      <ContractFeeDataGrid
        fees={contractFees ?? []}
        projectIndex={projectIndex}
        contractIndex={contractIndex}
        legalEntity={entityData}
        contract={contractData}
        formType={contractData.contractType === "Staking" ? "reward" : "fee"}
        deletePath={feeDeletePath}
      />
      <div style={{ height: 50 }} />

      <Subheading title="Liquidations" />
      <ContractLiquidationDataGrid
        liquidations={
          entityData.projects?.[projectIndex]?.contracts?.[contractIndex]
            .liquidations ?? []
        }
        projectIndex={projectIndex}
        contractIndex={contractIndex}
        legalEntity={entityData}
        contract={contractData}
        formType={"liquidation"}
        deletePath={liquidationDeletePath}
      />
      <div style={{ height: 50 }} />

      <Subheading title="Role Assignments" />
      <ContractRoleAssignmentDataGrid
        assignments={roleAssignments ?? []}
        projectIndex={projectIndex}
        contractIndex={contractIndex}
        legalEntity={entityData}
        contract={contractData}
        formType={"assignment"}
        deletePath={roleDeletePath}
      />
      <div style={{ height: 50 }} />

      <Subheading title="Commission Overrides" />
      <ContractCommissionOverrideDataGrid
        rules={commissionOverrides ?? []}
        projectIndex={projectIndex}
        contractIndex={contractIndex}
        legalEntity={entityData}
        contract={contractData}
        formType={"override"}
        deletePath={overrideDeletePath}
      />
      <ContractModal
        open={openEdit}
        handleOpenState={setOpenEdit}
        legalEntityId={entityData.id!}
        legalEntityData={entityData}
        projectIndex={projectIndex}
        contractIndex={contractIndex}
        contractData={
          entityData.projects![projectIndex].contracts![contractIndex]
        }
        type="info"
      />
      <div style={{ height: 50 }} />
    </>
  );
};

export default ModifyContract;
