import { SnapshotApprovalModal, DeleteByIdModal } from "../../modals";
import { GridBase, snapshotApprovedListColDef, snapshotListRows } from "..";
import { delSnapshot } from "../../../api/delete";
import { Snapshot } from "../../../contract/types";

const SnapshotApprovedListDataGrid = ({ snapshots }: Props) => {
  return (
    <GridBase
      name=""
      FormModal={SnapshotApprovalModal as any}
      DeleteModal={DeleteByIdModal as any}
      deleteMethod={delSnapshot}
      data={snapshots}
      rowFormatter={snapshotListRows}
      colDef={snapshotApprovedListColDef}
      deleteById={true}
      initialState={{
        sorting: {
          sortModel: [{ field: "approved", sort: "desc" }],
        },
      }}
      isList={true}
    />
  );
};

interface Props {
  snapshots: Snapshot[];
}

export default SnapshotApprovedListDataGrid;
