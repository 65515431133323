import * as f from "../../common";
import { Box, Typography } from "@mui/material";
import { useState, useContext, useCallback, useRef } from "react";
import { putLegalEntity } from "../../../../api/put";
import { DataContext } from "../../../../context/data";
import { NotificationContext } from "../../../../context/notification";
import { LoadingButton } from "@mui/lab";
import { app } from "../../../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { handleResponse, handleError } from "../../../../utils";

const LiquidationApprovalForm = ({
  entityId,
  projectIndex,
  contractIndex,
  liquidationIndex,
  close,
}: Props) => {
  const [sending, setSending] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const { entities, update } = useContext(DataContext);
  const notifications = useContext(NotificationContext);

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const approve = useCallback(async () => {
    if (!token) throw new Error("No token available");

    setSending(true);

    const entity = entities.find((e) => e.id === entityId);

    if (!entity) {
      throw new Error("There is no entity with the ID provided");
    }

    entity.projects![projectIndex].contracts![contractIndex].liquidations![
      liquidationIndex
    ].approved = new Date();

    try {
      const response = await putLegalEntity(entityId, token, entity);
      const result = handleResponse(response, notifications);

      if (result) {
        if (!response.id)
          throw new Error("The server returned an object with no id");

        update(response, "entities", response.id);
        close();
      } else {
        /* Stop the loading spinner */
        setSending(false);
      }
    } catch (e: any) {
      handleError(e, notifications);
      setSending(false);
    }
  }, [
    entities,
    entityId,
    projectIndex,
    contractIndex,
    liquidationIndex,
    token,
  ]);

  return (
    <f.FormContainer>
      <Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Approve liquidation for payout?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 1 }}>
          Once approved this event will be included in the next commission
          snapshot for distribution to employees.
        </Typography>
        <LoadingButton
          variant="outlined"
          fullWidth
          type="submit"
          loading={sending}
          sx={{ py: "0.8rem", mt: "1.7rem", color: "white" }}
          color="success"
          onClick={() => approve()}
          disabled={!!!token}
        >
          Approve
        </LoadingButton>
      </Box>
    </f.FormContainer>
  );
};

interface Props {
  entityId: string;
  projectIndex: number;
  contractIndex: number;
  liquidationIndex: number;
  close: () => void;
}

export default LiquidationApprovalForm;
