import {
  GridColumns,
  GridRowId,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { nameColDef, tagsColDef, actionsColDef, InfoChipMemo } from "./common";

const poolInfoColDef = ({
  handleEditClick,
  handleDeleteClick,
  idMap,
}: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Name") },
    {
      field: "owners",
      headerName: "Owners",
      width: 650,
      valueFormatter: (params) => {
        const owners = idMap?.filter((payee) =>
          params?.value?.includes(payee.id)
        );

        return (
          owners &&
          owners.map((owner) => `${owner.name} (${owner.email})`).join(", ")
        );
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const owners = idMap?.filter((payee) =>
          params?.value?.includes(payee.id)
        );

        if (owners) {
          let r = (Math.random() + 1).toString(36).substring(7);
          return owners.map((owner, i) => (
            <InfoChipMemo
              label={`${owner.name} (${owner.email})`}
              variant="filled"
              key={r + i}
            />
          ));
        }
      },
    },
    {
      ...tagsColDef,
    },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  idMap?: any[];
}

export default poolInfoColDef;
