import { CommissionPool, Payee } from "../../../contract/types";
import { PoolModal, DeleteByIdModal } from "../../modals";
import { GridBase, poolInfoColDef, poolInfoRows } from "..";
import { delCommissionPool } from "../../../api/delete";
import { useNavigate } from "react-router-dom";

const PoolInfoDataGrid = ({ pools, payees }: Props) => {
  const navigate = useNavigate();

  const onClick = (data: any) => {
    const poolIdMap = pools.map((pool) => pool.id);
    const id: string = data.row.id;
    const pool = pools[poolIdMap.indexOf(id)];
    const url = `/pool/update/${id}/data`;
    navigate(url);
  };

  return (
    <GridBase
      name="Pool"
      rowFormatter={poolInfoRows}
      FormModal={PoolModal as any}
      DeleteModal={DeleteByIdModal as any}
      deleteMethod={delCommissionPool}
      data={pools}
      colDef={poolInfoColDef}
      deleteById={true}
      idMap={payees}
      onClick={onClick}
      formType="info"
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  pools: CommissionPool[];
  payees: Payee[];
  tags: string[];
}

export default PoolInfoDataGrid;
