import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  emailColDef,
  dateColDef,
  tagsColDef,
  actionsColDef,
} from "./common";

const poolPayeeColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Name") },
    { ...emailColDef },
    {
      ...dateColDef("startDate", "Start Date"),
    },
    {
      ...dateColDef("endDate", "End Date"),
    },
    { ...tagsColDef },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  payees?: any[];
}

export default poolPayeeColDef;
