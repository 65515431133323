import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  dateColDef,
  infoShortColDef,
  infoLongColDef,
  tagsColDef,
  percentageColDef,
  currencyColDef,
  tokenColDef,
  LinkChipMemo,
  InfoChipMemo,
  boolCheckCrossColDef,
} from "./common";

const ContractListColDef = () => {
  const columns: GridColumns = [
    {
      field: "contractId",
      headerName: "ID",
      width: 140,
    },
    {
      field: "name",
      headerName: "Contract",
      width: 340,
    },
    {
      ...nameColDef("Project Name", "project"),
      renderCell: (params: GridRenderCellParams<any>) => {
        let r = (Math.random() + 1).toString(36).substring(7);
        return (
          <LinkChipMemo
            url={`/entity/${params.row.entityId}/projects/data/${params.row.projectIndex}/contracts`}
            label={params.value}
            variant="outlined"
            key={r}
          />
        );
      },
    },
    {
      field: "legalName",
      headerName: "Legal Name",
      width: 220,
    },
    { ...dateColDef("contractDate", "Start Date") },
    { ...dateColDef("contractEndDate", "End Date") },
    { ...infoShortColDef("Business Line", "businessLine") },
    {
      ...infoShortColDef("Contract Type", "contractType"),
    },
    {
      field: "commissionModel",
      headerName: "Commission Models",
      width: 200,
      valueFormatter: (params: any) => {
        return params.value && params.value.length > 0
          ? params.value
              .map((model: { name: string; id: string }) => model.name)
              .join(", ")
          : "";
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.value && params.value.length > 0) {
          let r = (Math.random() + 1).toString(36).substring(7);
          return params.value.map(
            (model: { name: string; id: string }, i: number) =>
              model ? (
                <LinkChipMemo
                  label={model.name}
                  variant="filled"
                  url={`/model/update/${model.id}/data`}
                  key={r + i}
                />
              ) : null
          );
        }
      },
    },
    {
      ...infoShortColDef("Term (m)", "termMonths"),
      width: 80,
    },
    {
      ...currencyColDef("Cash", "cash"),
    },
    {
      ...tokenColDef("Tokens", "tokens"),
    },
    {
      field: "tokenPercent",
      headerName: "Token Rules",
      width: 180,
    },
    { ...boolCheckCrossColDef("manual", "Auto", true) },
    {
      ...infoShortColDef("Rewards", "rewards"),
    },
    {
      ...infoShortColDef("Fund", "fund"),
    },
    {
      ...infoShortColDef("Reserve", "reserve"),
    },
    {
      ...percentageColDef("Equity Fee", "equityFee"),
    },
    { ...boolCheckCrossColDef("vested", "Vesting", false) },
    {
      ...infoLongColDef("Contract Cash Terms", "cashTerms"),
    },
    {
      ...infoLongColDef("Contract Token Terms", "tokenTerms"),
    },
    {
      field: "roleAssignments",
      headerName: "Role Assignments",
      width: 1080,
      valueFormatter: ({ value }) =>
        value && value.length > 0
          ? value
              .map(
                (role: { name: string; title: string }) =>
                  `${role.title}: ${role.name}`
              )
              .join(", ")
          : undefined,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.value && params.value.length > 0) {
          let r = (Math.random() + 1).toString(36).substring(7);
          return params.value.map(
            (role: { name: string; title: string }, i: number) =>
              role ? (
                <InfoChipMemo
                  label={`${role.title}: ${role.name}`}
                  variant="filled"
                  key={r + i}
                />
              ) : null
          );
        } else {
          return "--";
        }
      },
    },
    {
      field: "commissionOverrides",
      headerName: "Commission Overrides",
      width: 620,
      valueFormatter: ({ value }) =>
        value && value.length > 0
          ? value
              .map(
                (model: { name: string; percentage: number }) =>
                  `${model.name} (${model.percentage}%)`
              )
              .join(", ")
          : undefined,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.value && params.value.length > 0) {
          let r = (Math.random() + 1).toString(36).substring(7);
          return params.value.map(
            (override: { name: string; percentage: string }, i: number) =>
              override ? (
                <InfoChipMemo
                  label={override.name}
                  variant="filled"
                  key={r + i}
                />
              ) : null
          );
        } else {
          return "--";
        }
      },
    },
    { ...tagsColDef },
  ];

  return columns;
};

export default ContractListColDef;
