import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { SnapshotForm } from "../../forms/elements/snapshot";
import { formModalStyle } from "../common";

export default function SnapshotModal({
  open,
  snapshotData,
  snapshotId,
  handleOpenState,
}: Props) {
  const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <SnapshotForm
            update={snapshotId ? snapshotData : undefined}
            redirect={0}
            snapshotData={snapshotData}
            snapshotId={snapshotId}
            close={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  snapshotId?: string;
  snapshotData: any[];
  itemIndex?: number;
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
