import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ProjectForm } from "../../forms/elements/project";
import { formModalStyle } from "../common";
import { Project, LegalEntity } from "../../../contract/types";

export default function ProjectModal({
  open,
  legalEntityData,
  legalEntityId,
  itemIndex,
  handleOpenState,
}: Props) {
  const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <ProjectForm
            update={
              typeof itemIndex === "number"
                ? legalEntityData.projects![itemIndex]
                : undefined
            }
            redirect={0}
            legalEntityData={legalEntityData}
            legalEntityId={legalEntityId}
            projectIndex={itemIndex}
            close={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  legalEntityId: string;
  legalEntityData: LegalEntity;
  itemIndex?: number;
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
