import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { PoolForm } from "../../forms/elements/pool";
import { formModalStyle } from "../common";
import { CommissionPool } from "../../../contract/types";

export default function PoolModal({
  data,
  poolData,
  open,
  handleOpenState,
  type,
  itemIndex,
}: Props) {
  const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <PoolForm
            update={poolData ?? data}
            redirect={0}
            type={type}
            itemIndex={itemIndex}
            close={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  data?: CommissionPool;
  poolData?: CommissionPool;
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
  type: "info" | "commissions" | "payees";
  itemIndex?: number;
}
