import { useContext, useEffect, useState } from "react";
import { Heading, Subheading } from "../../components/profile";
import { DataContext } from "../../context/data";
import {
  SnapshotListDataGrid,
  SnapshotApprovedListDataGrid,
} from "../../components/datagrids";
import { Snapshot } from "../../contract/types";
import Loading from "../../layout/Loading";

const Snapshots = () => {
  const [pendingList, setPendingList] = useState<Snapshot[]>([]);
  const [approvedList, setApprovedList] = useState<Snapshot[]>([]);
  const context = useContext(DataContext);

  useEffect(() => {
    context.refreshAll();
  }, []);

  useEffect(() => {
    if (context.snapshots) {
      const data = context.snapshots.map((snapshot) => {
        const snap = { ...snapshot };
        if (snap.approved) snap.approved = new Date(snap.approved);
        return snap;
      });
      setPendingList(data.filter((d) => !d.approved));
      setApprovedList(data.filter((d) => d.approved));
    }
  }, [context]);

  if (context.loading) return <Loading />;
  return (
    <div>
      <Heading title="Snapshots" extraText="Manage commission payouts" />
      <div style={{ height: 30 }} />
      <Subheading title="Pending Approval" />
      <div style={{ height: 15 }} />
      <SnapshotListDataGrid snapshots={pendingList} />
      <div style={{ height: 30 }} />
      <Subheading title="Approved for Distribution" />
      <div style={{ height: 15 }} />
      <SnapshotApprovedListDataGrid snapshots={approvedList} />
    </div>
  );
};

export default Snapshots;
