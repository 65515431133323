import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import InfoChipMemo from "./InfoChipMemo";

const tagsColDef = {
  field: "tags",
  headerName: "Tags",
  width: 280,
  valueFormatter: (params: any) => {
    return params.value && params.value.length > 0
      ? params.value.join(", ")
      : "";
  },
  renderCell: (params: GridRenderCellParams<any>) => {
    let r = (Math.random() + 1).toString(36).substring(7);
    if (params.value && params.value.length > 0) {
      return params.value.map((tag: string, i: number) => (
        <InfoChipMemo label={tag} variant="filled" key={r + i} />
      ));
    } else {
      return "--";
    }
  },
};

export default tagsColDef;
