import { useState, useContext } from "react";
import { DataContext } from "../../../context/data";
import { ContractForm } from "../../forms/elements/contract";
import { formModalStyle } from "../common";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

export default function LiquidationModal({
  open,
  data,
  handleOpenState,
}: Props) {
  const { entities } = useContext(DataContext);
  const [sending, setSending] = useState<boolean>(false);
  const handleClose = () => handleOpenState(false);

  if (!data) return null;

  const [entityId, projectIndex, contractIndex, symbol, max, title] =
    data.split("-");

  const entity = entities.find((e) => e.id === entityId);
  const contract = entity?.projects?.[projectIndex]?.contracts?.[contractIndex];

  if (!entity || !contract)
    throw new Error("Either the entity or the contract does not exist");

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <ContractForm
            redirect={0}
            legalEntityId={entityId}
            legalEntityData={entity}
            contractData={contract}
            projectIndex={projectIndex}
            contractIndex={contractIndex}
            itemIndex={undefined}
            type="quickLiquidation"
            close={handleClose}
            symbol={symbol}
            max={max}
            title={title}
          />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  data: { [key: string]: any };
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
