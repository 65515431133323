import { GridColDef } from "@mui/x-data-grid-pro";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 8,
}).format;

const currencyColDef = (title: string, field: string) => {
  const nameColumn: GridColDef = {
    field: field,
    headerName: title,
    width: 140,
    valueFormatter: ({ value }) =>
      value && !Number.isNaN(parseInt(String(value), 10))
        ? currencyFormatter(Number(value))
        : "--",
  };
  return nameColumn;
};

export default currencyColDef;
