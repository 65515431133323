import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { CommissionModel } from "../../../../contract/types";
import { TextInput, DateInput, ArrayInput } from "../../inputs";

const ModelInfo = ({ update, tags }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(
    update && update.startDate ? new Date(update.startDate) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    update && update.endDate ? new Date(update.endDate) : null
  );

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <TextInput
        name="name"
        label="Name"
        defaultValue={update?.name}
        required={true}
        register={register}
        errors={errors.name}
      />

      <DateInput
        name="startDate"
        label="Start Date"
        value={startDate}
        control={control}
        register={register}
        setValue={setValue}
        setParentState={setStartDate}
        errors={errors.startDate}
        required={true}
      />

      <DateInput
        name="endDate"
        label="End Date"
        value={endDate}
        control={control}
        register={register}
        setValue={setValue}
        setParentState={setEndDate}
        errors={errors.endDate}
        required={true}
      />

      <ArrayInput
        name="businessLine"
        label="Business Line"
        register={register}
        setValue={setValue}
        options={[
          "Advisory",
          "Tokenization",
          "TokenSales",
          "Treasury",
          "Funds",
          "CommissionReserves",
          "MusicFinancing"
        ]}
        defaultValue={update?.businessLine}
        errors={errors.businessLine}
        multiple={false}
        required={true}
      />

      <ArrayInput
        name="tags"
        label="Tags"
        register={register}
        setValue={setValue}
        options={tags}
        defaultValue={update?.tags}
        errors={errors.tags}
        multiple={true}
      />
    </>
  );
};

interface Props {
  update?: CommissionModel;
  tags: string[];
}

export default ModelInfo;
