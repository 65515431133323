import { GridBase } from "..";
import { delCommissionModel } from "../../../api/delete";
import commissionPaymentRows from "../rows/commissionPaymentRows";
import commissionPaymentColDef from "../columns/CommissionPaymentsColDef";

const PaymentEventDataGrid = ({ events }: Props) => {
  return (
    <GridBase
      name=""
      deleteMethod={delCommissionModel}
      data={events}
      rowFormatter={commissionPaymentRows}
      colDef={commissionPaymentColDef}
      deleteById={false}
      initialState={{
        pinnedColumns: {
          left: ["name", "email"],
        },
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      createSnapshot={false}
      defaultRows={20}
    />
  );
};

interface Props {
  events: any[];
}

export default PaymentEventDataGrid;
