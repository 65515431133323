export const coinInfo: { [key: string]: { color: string } } = {
  "1INCH": {
    color: "#5412E2",
  },
  AAVE: {
    color: "#AA4A94",
  },
  ACA: {
    color: "#FE4A3C",
  },
  ADA: {
    color: "#0033AD",
  },
  ALGO: {
    color: "#010000", // Black
  },
  ARCC: {
    color: "#6835C2",
  },
  ATLAS: {
    color: "#181C1C", // Black
  },
  ATOM: {
    color: "#2E3148",
  },
  AVAX: {
    color: "#E84142",
  },
  AXS: {
    color: "#0055D5",
  },
  BCH: {
    color: "#0AC18E",
  },
  BNB: {
    color: "#F3BA2F",
  },
  BNT: {
    color: "#0FBCD5",
  },
  BTC: {
    color: "#F79317",
  },
  BUSD: {
    color: "#F0B90B",
  },
  CAKE: {
    color: "#47CFD8",
  },
  CBSN: {
    color: "#2C79F2",
  },
  CEEK: {
    color: "#0230F3",
  },
  CERE: {
    color: "#B70F93",
  },
  CRO: {
    color: "#103F68",
  },
  DAI: {
    color: "#F5AC37",
  },
  DODO: {
    color: "#1B1B15", // Black (change to yellow)
  },
  DOGE: {
    color: "#BA9F33",
  },
  DOT: {
    color: "#E6007A",
  },
  DPR: {
    color: "#56B8E5",
  },
  DRIP: {
    color: "#EA0001",
  },
  EGLD: {
    color: "#0D022B", // Black
  },
  EOS: {
    color: "#33427D",
  },
  ENTRP: {
    color: "#DD1438",
  },
  EPIX: {
    color: "#0D093C",
  },
  EST: {
    color: "#0A090B", // Black
  },
  ETH: {
    color: "#4C8AE2",
  },
  FIL: {
    color: "#0090FF",
  },
  FIO: {
    color: "#3AB4E5",
  },
  FLOW: {
    color: "#00EF8B",
  },
  FRESH: {
    color: "#000000", // Black
  },
  FTM: {
    color: "#13B5EC",
  },
  FTT: {
    color: "#02A6C2",
  },
  GALA: {
    color: "#0E0D0C", // Black
  },
  GARI: {
    color: "#F1663D",
  },
  GDT: {
    color: "#041A29",
  },
  GRT: {
    color: "#6747ED",
  },
  HBAR: {
    color: "#000000", // Black
  },
  HHOG: {
    color: "#0AB395",
  },
  HMND: {
    color: "#FF100D",
  },
  HNT: {
    color: "#38A2FF",
  },
  ICP: {
    color: "#121110", // Black
  },
  JET: {
    color: "#59CBD0",
  },
  KAR: {
    color: "#E53E3E",
  },
  KLAY: {
    color: "#4F473B",
  },
  LEO: {
    color: "#F07B2D",
  },
  LINK: {
    color: "#2A5ADA",
  },
  LRC: {
    color: "#1C60FF",
  },
  LTC: {
    color: "#345D9D",
  },
  LUNA: {
    color: "#172852",
  },
  MANA: {
    color: "#FF3355",
  },
  MATIC: {
    color: "#8247E5",
  },
  MIOTA: {
    color: "#000000",
  },
  MOC: {
    color: "#04364F",
  },
  NEAR: {
    color: "#000000", // Black
  },
  NODL: {
    color: "#18AE82",
  },
  ORE: {
    color: "#1D1D1D",
  },
  OST: {
    color: "#1E222A",
  },
  PEG: {
    color: "#0673BA",
  },
  PNTM: {
    color: "#B231A5",
  },
  PODS: {
    color: "#C91C4D",
  },
  POKT: {
    color: "#1D8AED",
  },
  POLIS: {
    color: "#34F6F7",
  },
  PORTAL: {
    color: "#00CAB5",
  },
  PUSD: {
    color: "#1B3B5F",
  },
  PYTH: {
    color: "#8246FA",
  },
  RADAR: {
    color: "#006CFF",
  },
  RARI: {
    color: "#EF6EE2",
  },
  RATIO: {
    color: "#ED74D0",
  },
  REACH: {
    color: "#000000", // Black
  },
  REGEN: {
    color: "#52B675",
  },
  RUON: {
    color: "#00C9C6",
  },
  SAND: {
    color: "#0090FF",
  },
  SBR: {
    color: "#6767FB",
  },
  SCRT: {
    color: "#141919", // Black
  },
  SHIB: {
    color: "#F00500",
  },
  SOL: {
    color: "#000001", // Black
  },
  SWITCH: {
    color: "#D32D79",
  },
  THETA: {
    color: "#1B1F2B",
  },
  TRX: {
    color: "#FF060A",
  },
  UFIT: {
    color: "#C0272E",
  },
  UNI: {
    color: "#FF007A",
  },
  USDC: {
    color: "#2775CA",
  },
  USDT: {
    color: "#50AF95",
  },
  VET: {
    color: "#18B0ED",
  },
  VGX: {
    color: "#504EE0",
  },
  WTHR: {
    color: "#11A7D9",
  },
  XLM: {
    color: "#0E121B", // Black
  },
  XMR: {
    color: "#F26822",
  },
  XRP: {
    color: "#23292F", // Black
  },
  XTZ: {
    color: "#2C7DF7",
  },
  ZBC: {
    color: "945DB9",
  },
};
