import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import { infoShortColDef, numberColDef, ApproveColDef } from "./common";
import liquidateColDef from "./common/LiquidateColDef";

const liquidateMultipleColDef = ({
  handleEditClick,
  handleDeleteClick,
}: Props) => {
  const columns: GridColumns = [
    {
      field: "contractName",
      headerName: "Contract",
      width: 340,
    },
    {
      field: "symbol",
      headerName: "Symbol",
      width: 100,
    },

    {
      ...numberColDef("Available", "quantity"),
    },
    {
      ...numberColDef("Spent", "spent"),
    },
    {
      ...infoShortColDef("Type", "type"),
    },
    {
      field: "projectName",
      headerName: "Project",
      width: 180,
    },
    {
      field: "entityName",
      headerName: "Partner",
      width: 220,
    },
    { ...liquidateColDef({ handleEditClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default liquidateMultipleColDef;
