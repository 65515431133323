import { GridColumns } from "@mui/x-data-grid-pro";

function caps(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

const potentialLiquidationsColDef = () => {
  const columns: GridColumns = [
    { field: "projectName", headerName: "Project", width: 120 },
    {
      field: "value",
      headerName: "Est. Value",
      width: 100,
      valueFormatter: ({ value }) =>
        typeof value === "number"
          ? currencyFormatter(value)
          : currencyFormatter(0),
    },
    { field: "contractName", headerName: "Contract", width: 180 },
    { field: "symbol", headerName: "Symbol", width: 100 },
    { field: "quantity", headerName: "Quantity", width: 100 },
    {
      field: "commissionType",
      headerName: "Type",
      valueFormatter: ({ value }: { value: string }) => caps(value),
      width: 100,
    },
    { field: "commissionTypeName", headerName: "Title", width: 150 },
    {
      field: "discretionary",
      headerName: "Discretionary",
      valueFormatter: ({ value }: { value: boolean }) => (value ? "Yes" : "No"),
      width: 130,
    },
  ];

  return columns;
};

export default potentialLiquidationsColDef;
