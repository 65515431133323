import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDD-pjiC6J5Oxqf5L32q3fBa0FhVvxe2Bo",
  authDomain: "treasury-monitoring.firebaseapp.com",
  projectId: "treasury-monitoring",
  storageBucket: "treasury-monitoring.appspot.com",
  messagingSenderId: "805730267241",
  appId: "1:805730267241:web:e7120518485d5d3f24f2f8",
  measurementId: "G-E2XG8QJM78",
};

export const app = initializeApp(firebaseConfig);
