import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LiquidateIcon from "@mui/icons-material/Opacity";
import UploadIcon from "@mui/icons-material/Upload";
import ReviewIcon from "@mui/icons-material/Reviews";
import CreateIcon from "@mui/icons-material/Create";
import PendingIcon from "@mui/icons-material/Pending";
import { drawerWidth } from "./theme";
import { styled } from "@mui/material/styles";

import Logo from "./Logo";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Divider, ListItemIcon, Tooltip } from "@mui/material";
import { Approval, Logout } from "@mui/icons-material";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar({
  open,
  avatar,
  name,
  userRole,
  handleDrawerOpen,
  logout,
}: TopBarProps) {
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        {userRole !== "contractOnly" ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div />
        )}
        <Box sx={{ flexGrow: 1 }} style={{ paddingTop: 6 }}>
          <Logo
            onClick={() => {
              navigate("/contracts");
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <UserMenu
          avatar={avatar}
          name={name}
          logout={logout}
          userRole={userRole}
        />
      </Toolbar>
    </AppBar>
  );
}

function UserMenu({
  avatar,
  name,
  userRole,
  logout,
}: {
  avatar: string;
  name: string;
  userRole: string;
  logout: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const n = useNavigate();
  const navigate = useCallback(
    (location: string) => {
      handleClose();
      n(location);
    },
    [n]
  );

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Actions">
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, textTransform: "none", color: "inherit" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 38, height: 38 }} src={avatar} />{" "}
            {/* <Typography sx={{ pl: 1 }}>{name}</Typography> */}
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {userRole !== "contractOnly" ? (
          <>
            <MenuItem
              onClick={() => navigate("/contracts/liquidate")}
              disabled={location.pathname === "/contracts/liquidate"}
            >
              <ListItemIcon>
                <LiquidateIcon fontSize="small" />
              </ListItemIcon>
              Liquidate Fees
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/contracts/liquidate/cash/excel")}
              disabled={location.pathname === "/contracts/liquidate/cash/excel"}
            >
              <ListItemIcon>
                <UploadIcon fontSize="small" />
              </ListItemIcon>
              Upload Cash Fees
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/contracts/liquidations/pending")}
              disabled={location.pathname === "/contracts/liquidations/pending"}
            >
              <ListItemIcon>
                <PendingIcon fontSize="small" />
              </ListItemIcon>
              Pending Liquidations
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/contracts/liquidations")}
              disabled={location.pathname === "/contracts/liquidations"}
            >
              <ListItemIcon>
                <Approval fontSize="small" />
              </ListItemIcon>
              Approved Liquidations
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => navigate("/data")}
              disabled={location.pathname === "/data"}
            >
              <ListItemIcon>
                <CreateIcon fontSize="small" />
              </ListItemIcon>
              Create Snapshot
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/snapshot")}
              disabled={location.pathname === "/snapshot"}
            >
              <ListItemIcon>
                <ReviewIcon fontSize="small" />
              </ListItemIcon>
              Review Snapshots
            </MenuItem>
            <Divider />
          </>
        ) : (
          <div />
        )}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface TopBarProps {
  open: boolean;
  avatar: string;
  name: string;
  userRole: string;
  handleDrawerOpen: () => void;
  logout: () => void;
}
