import { Contract } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";
import { monthDiff } from "../../../utils";

const poolPayeeRows = (contracts: Contract[]) => {
  const rows: GridRowsProp = contracts.map((contract, i) => {
    return {
      id: i,
      name: contract.name,
      businessLine: contract.businessLine,
      contractType: contract.contractType,
      baseContract: contract.baseContract,
      startDate: new Date(contract.startDate),
      endDate: new Date(contract.endDate),
      termMonths: monthDiff(
        new Date(contract.startDate),
        new Date(contract.endDate)
      ),
      tags: contract.tags,
    };
  });

  return rows;
};

export default poolPayeeRows;
