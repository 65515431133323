import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  dateColDef,
  infoShortColDef,
  currencyColDef,
  ApproveColDef,
  numberColDef,
} from "./common";

const liquidationPendingListColDef = ({
  handleEditClick,
  handleDeleteClick,
}: Props) => {
  const columns: GridColumns = [
    {
      field: "contractName",
      headerName: "Contract",
      width: 330,
    },
    {
      ...dateColDef("date", "Sell Date"),
    },
    {
      ...currencyColDef("Value", "value"),
      width: 100,
    },
    {
      field: "name",
      headerName: "Event",
      width: 210,
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
    },
    {
      field: "symbol",
      headerName: "Symbol",
      width: 100,
    },
    {
      ...numberColDef("Quantity", "quantity"),
    },
    {
      ...currencyColDef("Price", "price"),
      width: 100,
    },
    { ...ApproveColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default liquidationPendingListColDef;
