import { TextField, Autocomplete } from "@mui/material";
import { CommissionPool, Payee } from "../../../../contract/types";
import { useFormContext } from "react-hook-form";
import { TextInput, ArrayInput } from "../../inputs";

const PoolInfo = ({ update, payees, tags }: Props) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <TextInput
        name="name"
        label="Name"
        defaultValue={update?.name}
        required={true}
        register={register}
        errors={errors.name}
      />

      <Autocomplete
        options={["Yes", "No"]}
        defaultValue={
          update ? (update.discretionary ? "Yes" : "No") : undefined
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ mb: 2 }}
            label="Discretionary"
            placeholder="Discretionary"
            error={!!errors["discretionary"]}
            helperText={
              errors["discretionary"] ? errors["discretionary"].message : ""
            }
          />
        )}
        getOptionLabel={(option) => (typeof option === "string" ? option : "")}
        {...register("discretionary")}
        onChange={(e, selectedObject) => {
          if (selectedObject !== null)
            setValue(
              "discretionary",
              selectedObject === "Yes"
                ? true
                : selectedObject === "No"
                ? false
                : undefined,
              { shouldValidate: true }
            );
        }}
      />

      <ArrayInput
        name="owners"
        label="Owners"
        register={register}
        setValue={setValue}
        options={payees}
        defaultValue={
          update && update.owners
            ? payees.filter(
                (payee) => payee.id && update!.owners.includes(payee.id)
              )
            : undefined
        }
        errors={errors.owners}
        multiple={true}
        required={true}
        optionFormatter={(option: any) =>
          option.name && option.email ? `${option.name} (${option.email})` : ""
        }
        path="id"
        reduce={true}
      />

      <ArrayInput
        name="tags"
        label="Tags"
        register={register}
        setValue={setValue}
        options={tags}
        defaultValue={update?.tags}
        errors={errors.tags}
        multiple={true}
      />
    </>
  );
};

interface Props {
  update?: CommissionPool;
  payees: Payee[];
  tags: string[];
}

export default PoolInfo;
