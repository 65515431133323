import {
  CommissionModel,
  CommissionRole,
  Payee,
} from "../../../contract/types";
import { RoleModal, DeleteByIdModal } from "../../modals";
import { GridBase, roleColDef, roleRows } from "..";
import { delCommissionRole } from "../../../api/delete";

const RoleDataGrid = ({ roles, payees, models, tags }: Props) => {
  return (
    <GridBase
      name="Role"
      rowFormatter={roleRows}
      FormModal={RoleModal as any}
      DeleteModal={DeleteByIdModal as any}
      deleteMethod={delCommissionRole}
      data={roles}
      colDef={roleColDef}
      deleteById={true}
      payees={payees}
      models={models}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  roles: CommissionRole[];
  tags: string[];
  payees: Payee[];
  models: CommissionModel[];
}

export default RoleDataGrid;
