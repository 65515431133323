import React from "react";
import {
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import LiquidateIcon from "@mui/icons-material/Opacity";

const liquidateColDef = ({ handleEditClick }: Props) => {
  const actions = {
    field: "actions",
    type: "actions",
    headerName: "Liquidate",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }: GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<LiquidateIcon color="primary" />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
      ];
    },
  };
  return actions;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default liquidateColDef;
