import { GridColDef } from "@mui/x-data-grid-pro";

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 8,
}).format;

const numberColDef = (title: string, field: string) => {
  const nameColumn: GridColDef = {
    field: field,
    headerName: title,
    width: 140,
    valueFormatter: ({ id, value, api }) => {
      return value === "Pending"
        ? "Pending"
        : !Number.isNaN(parseInt(String(value), 10))
        ? numberFormatter(value)
        : numberFormatter(0);
    },
  };
  return nameColumn;
};

export default numberColDef;
