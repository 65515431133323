import { Typography } from "@mui/material";

const Subheading = ({ title }: Props) => {
  return (
    <Typography variant="h6" sx={{ mb: 0.5 }}>
      {title}
    </Typography>
  );
};

interface Props {
  title: string;
}

export default Subheading;
