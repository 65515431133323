import { GridRowsProp } from "@mui/x-data-grid-pro";

const contractCommissionOverrideRows = (rules: any[]) => {
  const rows: GridRowsProp = rules.map((rule, i) => {
    return {
      id: i,
      name: rule.name,
      reason: rule.reason,
      startDate: rule.startDate ? new Date(rule.startDate) : undefined,
      endDate: rule.endDate ? new Date(rule.endDate) : undefined,
      payeeName: rule.payeeName,
      email: rule.email,
      poolIds: rule.poolIds,
      percentage: rule.percentage ? Number(rule.percentage) : undefined,
      tags: rule.tags,
    };
  });

  return rows;
};

export default contractCommissionOverrideRows;
