import { CommissionModel } from "../../../contract/types";
import { ModelModal, DeleteByIndexModal } from "../../modals";
import {
  GridBase,
  modelIndividualRuleColDef,
  modelIndividualRuleRows,
} from "..";
import { putCommissionModel } from "../../../api/put";

const ModelIndividualRuleDataGrid = ({
  rules,
  modelData,
  deletePath,
}: Props) => {
  return (
    <GridBase
      name="Rule"
      FormModal={ModelModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putCommissionModel}
      data={rules}
      rowFormatter={modelIndividualRuleRows}
      colDef={modelIndividualRuleColDef}
      deleteById={false}
      formType="payees"
      modelData={modelData}
      deletePath={deletePath}
      objectToEdit={modelData}
      deleteType="models"
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  rules: any[];
  modelData: CommissionModel;
  deletePath: string;
}

export default ModelIndividualRuleDataGrid;
