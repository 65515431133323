import { GridBase } from "..";
import { delCommissionModel } from "../../../api/delete";
import commissionEventRow from "../rows/commissionEventRow";
import commissionEventColDef from "../columns/CommissionEventColDef";

const CommissionEventDataGrid = ({ events }: Props) => {
  return (
    <GridBase
      name=""
      deleteMethod={delCommissionModel}
      data={events}
      rowFormatter={commissionEventRow}
      colDef={commissionEventColDef}
      deleteById={false}
      initialState={{
        pinnedColumns: {
          left: ["name", "date", "value"],
        },
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      createSnapshot={true}
      defaultRows={20}
    />
  );
};

interface Props {
  events: any[];
}

export default CommissionEventDataGrid;
