import { useState, useCallback, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { putSnapshotUpdate } from "../../../api/put";
import { DataContext } from "../../../context/data";
import { useParams } from "react-router-dom";
import { deleteStyle } from "../common";
import { LoadingButton } from "@mui/lab";
import { app } from "../../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { NotificationContext } from "../../../context/notification";

export default function DeleteSnapshotPayeeModal({
  id,
  name,
  handleOpenState,
  openState,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const params = useParams();
  const snapshotId = params.snapshotId;
  const { snapshots, update, refreshAll } = useContext(DataContext);
  const notifications = useContext(NotificationContext);

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const handleOpen = () =>
    handleOpenState ? handleOpenState(true) : setOpen(true);

  const handleClose = () => {
    if (sending) return;
    handleOpenState ? handleOpenState(false) : setOpen(false);
  };

  const confirmedDelete = useCallback(async () => {
    if (!token) throw new Error("No token available");
    if (!snapshotId) throw new Error("Snapshot ID was not provided");

    setSending(true);
    const snapshotIndex = snapshots.findIndex((s) => s.id === snapshotId);
    const snapshot = { ...snapshots[snapshotIndex] };
    snapshot.payouts.splice(id, 1);

    try {
      const result = await putSnapshotUpdate(snapshotId, token, snapshot);
      if (result) {
        notifications.set({ message: "Saved!", type: "success" });
        update(snapshot, "snapshots", snapshotId!);
        refreshAll();
        setSending(false);
        handleClose();
      } else {
        notifications.set({ message: "Unable to update", type: "error" });
        setSending(false);
      }
    } catch (e: any) {
      notifications.set({ message: "Failed to update", type: "error" });
      setSending(false);
      console.error(e);
    }
  }, [id, snapshotId, token]);

  return (
    <>
      {!handleOpenState ? (
        <Button color="error" style={{ float: "right" }} onClick={handleOpen}>
          Delete
        </Button>
      ) : null}
      <Modal
        open={
          handleOpenState && typeof openState === "boolean" ? openState : open
        }
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to remove this payment to {name}?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action cannot be reversed
          </Typography>
          <LoadingButton
            variant="outlined"
            fullWidth
            loading={sending}
            sx={{ py: "0.8rem", mt: "2rem", color: "white" }}
            color="error"
            onClick={() => {
              confirmedDelete();
            }}
            disabled={!!!token}
          >
            Delete
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  id: number;
  name: string;
  deleteMethod: (id: string, token: string) => Promise<boolean>;
  successURL: number | string;
  handleOpenState?: (value: React.SetStateAction<boolean>) => void;
  openState?: boolean;
}
