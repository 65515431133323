import { GridColumns } from "@mui/x-data-grid-pro";
import { nameColDef, dateColDef, currencyColDef, emailColDef } from "./common";

const commissionEventColDef = () => {
  const columns: GridColumns = [
    { ...nameColDef("Name", "name") },
    { ...emailColDef },
    {
      ...currencyColDef("Amount USD", "value"),
    },
    { ...dateColDef("approved", "Approved") },
  ];

  return columns;
};

export default commissionEventColDef;
