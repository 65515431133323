import { ContractModal, DeleteByIndexModal } from "../../modals";
import {
  GridBase,
  contractLiquidationColDef,
  contractLiquidationRows,
} from "..";
import { putLegalEntity } from "../../../api/put";
import { Contract, LegalEntity } from "../../../contract/types";

const ContractLiquidationDataGrid = ({
  liquidations,
  projectIndex,
  contractIndex,
  legalEntity,
  contract,
  formType,
  deletePath,
}: Props) => {
  return (
    <GridBase
      name="Liquidation"
      rowFormatter={contractLiquidationRows}
      FormModal={ContractModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putLegalEntity}
      data={liquidations}
      colDef={contractLiquidationColDef}
      deleteById={false}
      projectIndex={projectIndex}
      contractIndex={contractIndex}
      legalEntityId={legalEntity.id}
      legalEntityData={legalEntity}
      contractData={contract}
      formType={formType}
      deletePath={deletePath}
      objectToEdit={legalEntity}
      initialState={{
        sorting: {
          sortModel: [{ field: "date", sort: "desc" }],
        },
      }}
      deleteType="entities"
    />
  );
};

interface Props {
  liquidations: any[];
  projectIndex: number;
  contractIndex: number;
  legalEntity: LegalEntity;
  contract: Contract;
  formType: "liquidation";
  deletePath: string;
}

export default ContractLiquidationDataGrid;
