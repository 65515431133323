import { useContext } from "react";
import { EntityInfoDataGrid } from "../../components/datagrids";
import { Heading } from "../../components/profile";
import Loading from "../../layout/Loading";
import { DataContext } from "../../context/data";

const EntityInfoGridTest = () => {
  const context = useContext(DataContext);

  if (context.loading) return <Loading />;
  return (
    <div>
      <Heading
        title={"Partners"}
        extraText={"Create, edit and manage partners and projects. "}
      />
      <div style={{ height: 5 }} />
      <EntityInfoDataGrid entities={context.entities} tags={context.tags} />
      <div style={{ height: 50 }} />
    </div>
  );
};

export default EntityInfoGridTest;
