import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  tagsColDef,
  percentageColDef,
  actionsColDef,
} from "./common";

const modelRoleColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Role") },
    { ...percentageColDef("Percentage", "percentage") },
    { ...tagsColDef },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default modelRoleColDef;
