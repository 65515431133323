import { Contract } from "../../../contract/types";
import { ContractModal, DeleteByIndexModal } from "../../modals";
import { GridBase, contractInfoColDef, contractInfoRows } from "..";
import { useNavigate } from "react-router-dom";
import { LegalEntity } from "../../../contract/types";
import { putLegalEntity } from "../../../api/put";

const ContractInfoDataGrid = ({
  contracts,
  legalEntityData,
  legalEntityId,
  projectIndex,
  deletePath,
  objectToEdit,
}: Props) => {
  const navigate = useNavigate();

  const onClick = (data: any) => {
    const id: string = data.row.id;
    const url = `update/${id}`;
    navigate(url);
  };

  return (
    <GridBase
      name="Contract"
      rowFormatter={contractInfoRows}
      FormModal={ContractModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putLegalEntity}
      data={contracts}
      colDef={contractInfoColDef}
      deleteById={false}
      deletePath={deletePath}
      onClick={onClick}
      legalEntityData={legalEntityData}
      legalEntityId={legalEntityId}
      projectIndex={projectIndex}
      objectToEdit={objectToEdit}
      formType="info"
      deleteType="entities"
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  contracts: Contract[];
  legalEntityData: LegalEntity;
  legalEntityId: string;
  projectIndex: number;
  deletePath: string;
  objectToEdit: { [key: string]: any };
}

export default ContractInfoDataGrid;
