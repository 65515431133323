import { GridRowsProp } from "@mui/x-data-grid-pro";

const liquidationListRows = (liquidations: any[]) => {
  const rows: GridRowsProp = liquidations.map((liquidation) => {
    return {
      ...liquidation,
      date: new Date(liquidation.date),
      approved: liquidation.approved
        ? new Date(liquidation.approved)
        : undefined,
    };
  });

  return rows;
};

export default liquidationListRows;
