import { useRef, useContext, useState, useEffect } from "react";
import { DataContext } from "../../../../context/data";
import { useFormContext, useFieldArray } from "react-hook-form";
import { Contract, Fee } from "../../../../contract/types";
import { TextInput, ArrayInput, DateInput } from "../../inputs";
import { FormNestedBorder } from "../../common";

const tokenFeeTypes = [
  { id: "Fixed", name: "Fixed Amount" },
  {
    id: "PercentOfSupplyAtDate",
    name: "Percent at Date",
  },
  {
    id: "PercentOfSupplyDynamic",
    name: "Percent Forever",
  },
  {
    id: "Cash",
    name: "Cash Value at Date",
  },
];

const ContractFees = ({
  itemIndex,
  reward,
  baseContract,
  entityData,
  projectIndex,
}: Props) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append } = useFieldArray<Contract, "fees", "index">({
    control: control as any,
    name: "fees",
    keyName: "index",
  });

  const indexRef = useRef(
    typeof itemIndex === "number" ? itemIndex : fields.length
  );

  const [feeType, setFeeType] = useState<string | undefined>(
    reward ? "Rewards" : fields[indexRef.current]?.type
  );

  const [tokenFeeType, setTokenFeeType] = useState<string | undefined>(
    fields[indexRef.current]?.feeData?.tokenFeeType
  );

  const { tags } = useContext(DataContext);

  const baseContractData: Contract | false = baseContract
    ? entityData.projects[projectIndex]?.contracts.find(
        (c: Contract) => c.name === baseContract
      )
    : false;

  const baseTokens: string[] = [];
  baseContractData &&
    baseContractData.fees &&
    baseContractData.fees.forEach((f) => {
      if (f.type === "Token" && !baseTokens.includes(f.symbol))
        baseTokens.push(f.symbol);
    });

  const initialTokenFeeType = useRef(true);
  useEffect(() => {
    if (!initialTokenFeeType.current) {
      switch (tokenFeeType) {
        case "Fixed":
          setValue(`fees.${indexRef.current}.feeData.atDate`, undefined);
          setValue(`fees.${indexRef.current}.feeData.cashValue`, undefined);
          setValue(`fees.${indexRef.current}.feeData.percent`, undefined);
          setValue(
            `fees.${indexRef.current}.quantity`,
            fields[indexRef.current]?.quantity
              ? fields[indexRef.current].quantity
              : undefined,
            {
              shouldValidate: true,
            }
          );
          break;
        case "PercentOfSupplyAtDate":
          setValue(`fees.${indexRef.current}.feeData.cashValue`, undefined);
          setValue(`fees.${indexRef.current}.feeData.atDate`, "");
          setValue(
            `fees.${indexRef.current}.quantity`,
            fields[indexRef.current]?.quantity
              ? fields[indexRef.current].quantity
              : undefined,
            {
              shouldValidate: true,
            }
          );
          break;
        case "PercentOfSupplyDynamic":
          setValue(`fees.${indexRef.current}.feeData.atDate`, "");
          setValue(`fees.${indexRef.current}.feeData.cashValue`, undefined);
          setValue(
            `fees.${indexRef.current}.quantity`,
            fields[indexRef.current]?.quantity
              ? fields[indexRef.current].quantity
              : undefined,
            {
              shouldValidate: true,
            }
          );
          break;
        case "Cash":
          setValue(`fees.${indexRef.current}.feeData.atDate`, "");
          setValue(`fees.${indexRef.current}.feeData.percent`, undefined);
          setValue(
            `fees.${indexRef.current}.quantity`,
            fields[indexRef.current]?.quantity
              ? fields[indexRef.current].quantity
              : undefined,
            {
              shouldValidate: true,
            }
          );
          break;
      }
    } else {
      initialTokenFeeType.current = false;
    }
  }, [tokenFeeType, indexRef.current]);

  const initialFeeType = useRef(true);

  useEffect(() => {
    if (!initialFeeType.current) {
      switch (feeType) {
        case "Cash":
          setValue(`fees.${indexRef.current}.symbol`, "USD");
          setValue(`fees.${indexRef.current}.feeData.tokenFeeType`, undefined);
          setValue(`fees.${indexRef.current}.feeData.atDate`, "");
          setValue(`fees.${indexRef.current}.feeData.percent`, undefined);
          setValue(`fees.${indexRef.current}.feeData.cashValue`, undefined);
          setValue(`fees.${indexRef.current}.feeData`, undefined);
          setTokenFeeType(undefined);
          break;
        case "Token":
          setTokenFeeType(undefined);
          break;
      }
    } else {
      initialFeeType.current = false;
    }
  }, [feeType, indexRef]);

  const firstLoad = useRef<boolean>(true);

  if (firstLoad.current && typeof itemIndex === "undefined") {
    firstLoad.current = false;
    append({
      name:
        baseContract && baseTokens
          ? `${baseTokens[0]} Staking Rewards ${new Date().toLocaleDateString(
              "en-US"
            )}`
          : undefined,
      quantity: undefined,
      symbol: baseTokens ? baseTokens[0] : undefined,
      type: baseContract && baseTokens ? "Rewards" : undefined,
      tags: [],
    });
  }

  return (
    <>
      {fields.map((field, index) => {
        if (index === indexRef.current) {
          return (
            <FormNestedBorder uid={field.index} key={index}>
              <TextInput
                name={`fees.${index}.name`}
                label="Name"
                defaultValue={field.name}
                required={true}
                register={register}
                errors={errors.fees?.[index]?.name}
              />

              <ArrayInput
                name={`fees.${index}.type`}
                label="Fee Type"
                register={register}
                setValue={setValue}
                options={["Cash", "Rewards", "Fund", "Token", "Reserve"]}
                defaultValue={field.type}
                errors={errors.fees?.[index]?.type}
                multiple={false}
                required={true}
                setParentState={setFeeType}
              />

              {feeType === "Token" || feeType === "Rewards" ? (
                <TextInput
                  name={`fees.${index}.symbol`}
                  label="Symbol"
                  defaultValue={field.symbol}
                  required={true}
                  register={register}
                  errors={errors.fees?.[index]?.symbol}
                />
              ) : (
                <></>
              )}

              {/* Extended Token Fee */}

              {feeType === "Token" ? (
                <>
                  <ArrayInput
                    name={`fees.${index}.feeData.tokenFeeType`}
                    label="Token Fee Model"
                    defaultValue={
                      field.feeData?.tokenFeeType
                        ? tokenFeeTypes[
                            tokenFeeTypes.findIndex(
                              (fT) => fT.id === field.feeData?.tokenFeeType
                            )
                          ]
                        : undefined
                    }
                    optionFormatter={(option: any) =>
                      option.name ? option.name : ""
                    }
                    register={register}
                    setValue={setValue}
                    required={true}
                    options={tokenFeeTypes}
                    errors={errors.fees?.[index].feeData?.tokenFeeType}
                    path="id"
                    setParentState={setTokenFeeType}
                  />

                  {tokenFeeType === "Cash" ? (
                    <>
                      <TextInput
                        name={`fees.${index}.feeData.cashValue`}
                        label="Cash Value"
                        defaultValue={field.feeData?.cashValue}
                        type="number"
                        required={true}
                        register={register}
                        errors={errors.fees?.[index]?.feeData?.cashValue}
                      />
                      <DateInput
                        name={`fees.${index}.feeData.atDate`}
                        label="At Date"
                        value={field.feeData?.atDate as Date | null}
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors.fees?.[index]?.feeData?.atDate}
                        required={true}
                      />
                    </>
                  ) : tokenFeeType === "Fixed" ? (
                    <></>
                  ) : tokenFeeType === "PercentOfSupplyAtDate" ? (
                    <>
                      <TextInput
                        name={`fees.${index}.feeData.percent`}
                        label="Percentage"
                        defaultValue={field.feeData?.percent}
                        type="number"
                        required={true}
                        register={register}
                        errors={errors.fees?.[index]?.feeData?.percent}
                      />
                      <DateInput
                        name={`fees.${index}.feeData.atDate`}
                        label="At Date"
                        value={field.feeData?.atDate as Date | null}
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors.fees?.[index]?.feeData?.atDate}
                        required={true}
                      />
                    </>
                  ) : tokenFeeType === "PercentOfSupplyDynamic" ? (
                    <>
                      <TextInput
                        name={`fees.${index}.feeData.percent`}
                        label="Percentage"
                        defaultValue={field.feeData?.percent}
                        type="number"
                        required={true}
                        register={register}
                        errors={errors.fees?.[index]?.feeData?.percent}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              <TextInput
                name={`fees.${index}.quantity`}
                label={
                  feeType === "Cash" ||
                  feeType === "Fund" ||
                  feeType === "Rewards" ||
                  feeType === "Reserve" ||
                  tokenFeeType === "Fixed" ||
                  (feeType === "Token" && tokenFeeType === undefined) ||
                  (feeType === undefined && tokenFeeType === undefined)
                    ? "Quantity"
                    : "Quantity (Leave blank to auto-populate)"
                }
                defaultValue={field.quantity}
                type="number"
                required={
                  feeType === "Cash" ||
                  tokenFeeType === "Fixed" ||
                  (feeType === "Token" && tokenFeeType === undefined)
                    ? true
                    : false
                }
                register={register}
                errors={errors.fees?.[index]?.quantity}
              />

              <TextInput
                name={`fees.${index}.notes`}
                label="Notes"
                defaultValue={field.notes}
                required={false}
                register={register}
                errors={errors.fees?.[index]?.notes}
                multiline
                mlHeight={1}
              />

              <ArrayInput
                name={`fees.${index}.tags`}
                label="Tags"
                register={register}
                setValue={setValue}
                options={tags}
                defaultValue={field.tags}
                errors={errors.fees?.[index].tags}
                multiple={true}
              />
            </FormNestedBorder>
          );
        } else return null;
      })}
    </>
  );
};

interface Props {
  itemIndex?: number;
  reward?: boolean;
  baseContract?: string;
  entityData: { [key: string]: any };
  projectIndex: number;
}

export default ContractFees;
