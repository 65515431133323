import { useContext, useEffect } from "react";
import { DataContext } from "../../context/data";
import { Heading } from "../../components/profile";
import { LiquidateMultipleDataGrid } from "../../components/datagrids";
import Loading from "../../layout/Loading";

const LiquidateMultiple = () => {
  const context = useContext(DataContext);
  const { loading, entities } = context;

  const toLiquidate: ToLiquidate[] = [];
  !loading &&
    entities.forEach((entity) => {
      entity.projects &&
        entity.projects.forEach((project, i) => {
          project.contracts &&
            project.contracts.forEach((contract, j) => {
              /* Create symbol-value pairs for each available currency */
              const totalFees: {
                [key: string]: {
                  value: number;
                  spent: number;
                  types: string[];
                };
              } = {};

              /* Calculate the total of each symbol */
              contract.fees &&
                contract.fees.forEach((fee) => {
                  if (!fee.quantity) return;

                  if (totalFees[fee.symbol]?.value) {
                    totalFees[fee.symbol].value += Number(fee.quantity);
                    !totalFees[fee.symbol].types.includes(fee.type) &&
                      totalFees[fee.symbol].types.push(fee.type);
                  } else {
                    totalFees[fee.symbol] = {
                      value: Number(fee.quantity),
                      spent: 0,
                      types: [fee.type],
                    };
                  }
                });

              /* Remove values already spent */
              totalFees &&
                contract.liquidations &&
                contract.liquidations.forEach((liquidation) => {
                  if (totalFees[liquidation.symbol]) {
                    totalFees[liquidation.symbol].value -= Number(
                      liquidation.quantity
                    );
                    if (totalFees[liquidation.symbol].spent) {
                      totalFees[liquidation.symbol].spent += Number(
                        liquidation.quantity
                      );
                    } else {
                      totalFees[liquidation.symbol].spent = Number(
                        liquidation.quantity
                      );
                    }
                  } else {
                    console.error(
                      `Liquidation for symbol ${liquidation.symbol} with no corresponding fee`
                    );
                  }
                });

              /* Create grid rows of each available token symbol by contract */
              if (totalFees) {
                Object.keys(totalFees).forEach((symbol) => {
                  toLiquidate.push({
                    id: `${entity.id}-${i}-${j}-${symbol}-${totalFees[symbol].value}-${project.name}`,
                    symbol,
                    quantity: totalFees[symbol].value,
                    spent: totalFees[symbol].spent ?? 0,
                    type: totalFees[symbol].types.join(", "),
                    entityName: entity.name,
                    projectName: project.name,
                    contractName: contract.name,
                  });
                });
              }
            });
        });
    });

  if (loading) return <Loading />;
  return (
    <>
      <Heading
        title="Liquidate Fees"
        extraText="Record events that should appear in employees snapshots"
      />
      <LiquidateMultipleDataGrid liquidations={toLiquidate} />
    </>
  );
};

interface ToLiquidate {
  id: string;
  symbol: string;
  quantity: number;
  spent: number;
  type: string;
  entityName: string;
  projectName: string;
  contractName: string;
}

export default LiquidateMultiple;
