import { TextField } from "@mui/material";
import { FieldValues, UseFormRegister } from "react-hook-form";

const TextInput = ({
  name,
  label,
  required,
  register,
  errors,
  defaultValue,
  multiline,
  mlHeight,
  type,
  disabled,
}: Props) => {
  return (
    <TextField
      sx={{ mb: 2 }}
      label={label}
      fullWidth
      required={required ? true : false}
      defaultValue={defaultValue ? defaultValue : ""}
      error={!!errors}
      helperText={errors ? errors.message : ""}
      multiline={multiline}
      rows={multiline ? (mlHeight ? mlHeight : 5.7) : 1}
      type={type ? type : "string"}
      disabled={disabled ? true : false}
      {...register(name)}
    />
  );
};

interface Props {
  name: string;
  label: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  errors?: { [key: string]: any };
  defaultValue?: string | number;
  type?: "string" | "number";
  multiline?: boolean;
  mlHeight?: number;
  disabled?: boolean;
}

export default TextInput;
