import React from "react";
import { Box } from "@mui/system";

const FormContainer = (props: Props) => {
  return <Box sx={{ maxWidth: "100%", margin: "auto" }}>{props.children}</Box>;
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export default FormContainer;
