import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RoleForm } from "../../forms/elements/role";
import { formModalStyle } from "../common";
import { Payee } from "../../../contract/types";

export default function RoleModal({ data, open, handleOpenState }: Props) {
  const [sending, setSending] = useState<boolean>(false);
  const handleOpen = () => handleOpenState(true);
  const handleClose = () => handleOpenState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={formModalStyle}>
          <RoleForm update={data} redirect={0} close={handleClose} />
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  data?: Payee;
  open: boolean;
  handleOpenState: (value: React.SetStateAction<boolean>) => void;
}
