import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  dateColDef,
  infoShortColDef,
  tagsColDef,
  actionsColDef,
} from "./common";

const contractInfoColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Name") },
    { ...infoShortColDef("Business Line", "businessLine") },
    {
      ...infoShortColDef("Type", "contractType"),
    },
    {
      ...nameColDef("Base Contract", "baseContract"),
    },
    {
      ...dateColDef("startDate", "Start Date"),
    },
    {
      ...infoShortColDef("Term (m)", "termMonths"),
      width: 100,
    },
    { ...tagsColDef },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default contractInfoColDef;
