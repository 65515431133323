import { useContext, useEffect } from "react";
import { DataContext } from "../../context/data";
import { PoolInfoDataGrid } from "../../components/datagrids";
import { Heading } from "../../components/profile";
import Loading from "../../layout/Loading";

const PoolInfoGridTest = () => {
  const { loading, tags, pools, payees, refreshAll } = useContext(DataContext);

  useEffect(() => {
    refreshAll();
  }, []);

  if (loading) return <Loading />;
  return (
    <div>
      <Heading
        title={"Pools"}
        extraText={"Create groups that can be assigned to models."}
      />
      <div style={{ height: 5 }} />
      <PoolInfoDataGrid pools={pools} tags={tags} payees={payees} />
    </div>
  );
};

export default PoolInfoGridTest;
