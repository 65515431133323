import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  dateColDef,
  infoShortColDef,
  tagsColDef,
  actionsColDef,
  numberColDef,
  currencyColDef,
} from "./common";

const contractLiquidationColDef = ({
  handleEditClick,
  handleDeleteClick,
}: Props) => {
  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 140,
    },
    {
      ...infoShortColDef("Type", "type"),
    },
    {
      ...infoShortColDef("Symbol", "symbol"),
    },
    {
      ...numberColDef("Quantity", "quantity"),
    },
    {
      ...currencyColDef("Price", "price"),
    },
    {
      ...dateColDef("date", "Date"),
    },
    {
      ...dateColDef("approved", "Approved"),
    },
    { ...tagsColDef },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default contractLiquidationColDef;
