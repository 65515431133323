import { GridColDef } from "@mui/x-data-grid-pro";

const percentFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

const percentageColDef = (title: string, field: string) => {
  const def: GridColDef = {
    field: field,
    headerName: title,
    width: 110,
    valueFormatter: ({ value }) =>
      value && !Number.isNaN(parseInt(String(value), 10))
        ? percentFormat(value / 100)
        : "--",
  };

  return def;
};

export default percentageColDef;
