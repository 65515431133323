import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import EventsIcon from "@mui/icons-material/DataObject";
import PaymentsIcon from "@mui/icons-material/MonetizationOn";

const ApprovedActionsColDef = () => {
  const navigate = useNavigate();

  const actions = {
    field: "actions",
    type: "actions",
    headerName: "Views",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }: any) => {
      return [
        <GridActionsCellItem
          icon={<EventsIcon />}
          label="Events"
          className="textPrimary"
          onClick={() => navigate(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<PaymentsIcon />}
          label="Payments"
          onClick={() => navigate(`${id}/payments`)}
        />,
      ];
    },
  };
  return actions;
};

export default ApprovedActionsColDef;
