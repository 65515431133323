import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  tagsColDef,
  actionsColDef,
  percentageColDef,
} from "./common";

const poolDiscretionaryCommissionColDef = ({
  handleEditClick,
  handleDeleteClick,
}: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Rule Name") },
    { field: "reason", headerName: "Reason", width: 280 },
    { field: "payeeName", headerName: "Payee", width: 220 },
    { ...percentageColDef("Percentage", "percentage"), width: 150 },
    {
      ...tagsColDef,
    },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  payees?: any[];
}

export default poolDiscretionaryCommissionColDef;
