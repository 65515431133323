import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../../context/data";
import Loading from "../../layout/Loading";
import { SnapshotUpdateDataGrid } from "../../components/datagrids";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const UpdateSnapshot = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [commissionEventRows, setCommissionEventRows] = useState<
    CommissionRow[]
  >([]);
  const [approved, setApproved] = useState<boolean>();

  const params = useParams();
  const snapshotId = params.snapshotId;
  const context = useContext(DataContext);

  useEffect(() => {
    if (context.snapshots && context.snapshots.length > 0) {
      const snapshotIndex = context.snapshots.findIndex(
        (s) => s.id === snapshotId
      );
      const snapshot = context.snapshots[snapshotIndex];

      if (snapshot.approved) setApproved(true);

      const commissionEventRows: CommissionRow[] = [];

      snapshot.payouts &&
        snapshot.payouts.forEach((payout) => {
          const row: CommissionRow = {
            name: payout.payeeName,
            date: new Date(payout.date),
            liquidationName: payout.liquidationName,
            projectName: payout.projectName,
            contractName: payout.contractName,
            quantity: Number(payout.commissionTotal),
            symbol: payout.symbol,
            value: Number(payout.valueUsd),
            model: payout.sourceLiquidation.modelName,
            source:
              payout.sourceLiquidation.poolName ??
              payout.sourceLiquidation.roleName ??
              payout.sourceLiquidation.modelName,
            type: capitalizeFirstLetter(
              payout.sourceLiquidation.commissionType
            ),
            percentage: payout.sourceLiquidation.percentage,
          };

          commissionEventRows.push(row);
        });

      setCommissionEventRows(commissionEventRows);
      setLoading(false);
    }
  }, [context]);

  if (loading) return <Loading />;
  return (
    <div
      style={{
        marginTop: -28,
        marginLeft: -40,
        marginRight: -40,
        marginBottom: -49,
        height: "calc(100% + 55px)",
        width: "calc(100% + 80px)",
        overflow: "hidden",
      }}
    >
      <SnapshotUpdateDataGrid
        events={commissionEventRows}
        approved={approved}
      />
    </div>
  );
};

interface CommissionRow {
  name: string;
  date: Date | string;
  liquidationName: string;
  projectName: string;
  contractName: string;
  symbol: string;
  quantity: number;
  value: number;
  source?: string;
  model?: string;
  pool?: string;
  role?: string;
  type?: string;
  percentage?: number;
}

export default UpdateSnapshot;
