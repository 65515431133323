import { Snapshot } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const snapshotListRows = (snapshots: Snapshot[]) => {
  const rows: GridRowsProp = snapshots.map((snapshot) => {
    const totalPayees: string[] = [];
    let totalValue = 0;

    snapshot.payouts.forEach((payout) => {
      if (!totalPayees.includes(payout.payeeId))
        totalPayees.push(payout.payeeId);
      totalValue += Number(payout.valueUsd);
    });

    return {
      ...snapshot,
      name: snapshot.name ?? "DATA MISSING",
      totalValue: totalValue,
      totalPayees: totalPayees.length,
    };
  });

  return rows;
};

export default snapshotListRows;
