import { GridColDef } from "@mui/x-data-grid-pro";

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 8,
}).format;

const tokenColDef = (title: string, field: string) => {
  const nameColumn: GridColDef = {
    field: field,
    headerName: title,
    width: 140,
    valueFormatter: ({ id, value, api }) => {
      const symbol = api.getRow(id)?.symbol;
      return value && !Number.isNaN(parseInt(String(value), 10))
        ? `${numberFormatter(Number(value))} ${symbol}`
        : "--";
    },
  };
  return nameColumn;
};

export default tokenColDef;
