import React from 'react'
import { DataContext } from '../../context/data'
import { NotificationContext } from '../../context/notification'
import * as XLSX from 'xlsx'
import { LegalEntity, Liquidation } from '../../contract/types'
import Loading from '../../layout/Loading'
import _deepClone from 'lodash/cloneDeep'
import { Button, CircularProgress, styled, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { formModalStyle } from '../../components/modals/common'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { getAuth, getIdToken } from 'firebase/auth'
import { app } from '../../components/firebase'
import { putLegalEntity } from '../../api/put'
import LiquidationIcon from '@mui/icons-material/Opacity'
import { TextField } from '@mui/material'

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 8,
}).format

const LiquidateCashExcel = () => {
  const [state, setState] = React.useState<{ data: any[]; cols: any[] }>({
    data: [],
    cols: [],
  })
  const [targetCells, setTargetCells] = React.useState<[string, string]>([
    'A',
    'BY',
  ])
  const [headerRowNumber, setHeaderRowNumber] = React.useState<number>(7)
  const [amountColumn, setAmountColumn] =
    React.useState<string>('Cash Received')
  const [projectNameColumn, setProjectNameColumn] = React.useState<string>(
    'Project Name - Type - Date'
  )
  const [dateToRecord, setDateToRecord] = React.useState<Date>(new Date())

  const { loading } = React.useContext(DataContext)

  const handleFile = (file: any /*:File*/) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target!.result
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        cellDates: true,
      })

      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      console.log(rABS, wb)

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })

      /* Update state */
      const cols = make_cols(ws['!ref']!)
      if (targetCells.length !== 2 || !targetCells[0] || !targetCells[1]) {
        alert('Invalid target cells')
        return
      }
      const target = targetCells
      const rangeIndex = [
        cols.findIndex((c) => c.name === target[0]),
        cols.findIndex((c) => c.name === target[1]),
      ]
      if (rangeIndex[0] === -1 || rangeIndex[1] === -1) {
        alert('The target cells do not exist in this file')
        return
      }

      const columnsInRange = cols.slice(rangeIndex[0], rangeIndex[1] + 1)
      setState({ data: data, cols: columnsInRange })
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  }

  const clear = () => {
    setState({ data: [], cols: [] })
  }

  // const exportFile = () => {
  //   /* convert state to workbook */
  //   const ws = XLSX.utils.aoa_to_sheet(state.data);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  //   /* generate XLSX file and send to client */
  //   XLSX.writeFile(wb, "sheetjs.xlsx");
  // };

  if (loading) return <Loading />
  return (
    <DragDropFile handleFile={handleFile}>
      <div style={{ height: '100%', width: '100%' }}>
        <DataInput
          handleFile={handleFile}
          targetCells={targetCells}
          setTargetCells={setTargetCells}
          headerRowNumber={headerRowNumber}
          setHeaderRowNumber={setHeaderRowNumber}
          amountColumn={amountColumn}
          setAmountColumn={setAmountColumn}
          dateToRecord={dateToRecord}
          setDateToRecord={setDateToRecord}
          projectNameColumn={projectNameColumn}
          setProjectNameColumn={setProjectNameColumn}
          clear={clear}
        />
        {state.data.length && state.cols.length ? (
          <UploadModal
            data={state.data}
            cols={state.cols}
            targetCells={targetCells}
            headerRowNumber={headerRowNumber}
            amountColumn={amountColumn}
            dateToRecord={dateToRecord}
            projectNameColumn={projectNameColumn}
            clear={clear}
          />
        ) : null}
      </div>
    </DragDropFile>
  )
}

const Input = styled('input')({
  display: 'none',
})

const DataInput = ({
  handleFile,
  headerRowNumber,
  setHeaderRowNumber,
  targetCells,
  setTargetCells,
  amountColumn,
  setAmountColumn,
  projectNameColumn,
  setProjectNameColumn,
  dateToRecord,
  setDateToRecord,
  clear,
}: {
  handleFile: (file: any) => void
  headerRowNumber: number
  setHeaderRowNumber: React.Dispatch<React.SetStateAction<number>>
  targetCells: [string, string]
  setTargetCells: React.Dispatch<React.SetStateAction<[string, string]>>
  amountColumn: string
  setAmountColumn: React.Dispatch<React.SetStateAction<string>>
  projectNameColumn: string
  setProjectNameColumn: React.Dispatch<React.SetStateAction<string>>
  dateToRecord: Date
  setDateToRecord: React.Dispatch<React.SetStateAction<Date>>
  clear: () => void
}) => {
  const handleChange = (e: any) => {
    const files = e.target.files
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <form
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: -5,
        }}
      >
        <LiquidationIcon
          color='primary'
          sx={{ height: 70, fontSize: 70, mb: 1.5 }}
        />

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: 1.5,
            mt: 0.5,
            mb: 2,
            width: '100%',
          }}
        >
          <TextField
            variant='outlined'
            size='small'
            inputProps={{ sx: { textAlign: 'center' } }}
            label='Target Cells'
            placeholder='Target Cells'
            value={targetCells.join(':')}
            onChange={(e: any) => setTargetCells(e.target.value.split(':'))}
            fullWidth
          />

          <TextField
            variant='outlined'
            size='small'
            inputProps={{ sx: { textAlign: 'center' } }}
            label='Header Row'
            placeholder='Header Row'
            value={headerRowNumber}
            onChange={(e: any) => {
              if (
                !!!isNaN(e.target.value) &&
                Number.isInteger(Number(e.target.value))
              ) {
                setHeaderRowNumber(Number(e.target.value))
              } else {
                alert('Header row must be an integer')
              }
            }}
            fullWidth
          />
        </Box>

        <TextField
          variant='outlined'
          size='small'
          label='Project Name Header'
          inputProps={{ sx: { textAlign: 'center' } }}
          placeholder={projectNameColumn}
          value={projectNameColumn}
          sx={{ mb: 2.5 }}
          onChange={(e: any) => setProjectNameColumn(e.target.value)}
          fullWidth
        />

        <label htmlFor='contained-button-file' style={{ width: '100%' }}>
          <Input
            id='contained-button-file'
            type='file'
            onChange={handleChange}
          />
          <Button variant='outlined' component='span' size='large' fullWidth>
            Choose File
          </Button>
        </label>
      </Box>
    </form>
  )
}

const DragDropFile = ({
  children,
  handleFile,
}: {
  children: JSX.Element | JSX.Element[]
  handleFile: (file: any) => void
}) => {
  const suppress = (evt: any) => {
    evt.stopPropagation()
    evt.preventDefault()
  }
  const onDrop = (evt: any) => {
    evt.stopPropagation()
    evt.preventDefault()
    const files = evt.dataTransfer.files
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <div
      onDrop={onDrop}
      onDragEnter={suppress}
      onDragOver={suppress}
      style={{
        height: 'calc(100% - 45px)',
        width: '100%',
        border: '1px solid rgb(255 255 255 / 20%)',
        borderRadius: 14,
        padding: 15,
        marginBottom: 15,
      }}
    >
      {children}
    </div>
  )
}

const UploadModal = ({
  data,
  cols,
  headerRowNumber,
  targetCells,
  amountColumn,
  projectNameColumn,
  dateToRecord,
  clear,
}: {
  data: any[]
  cols: any[]
  headerRowNumber: number
  targetCells: [string, string]
  amountColumn: string
  projectNameColumn: string
  dateToRecord: Date
  clear: () => void
}) => {
  const [processing, setProcessing] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState<boolean>(true)
  const [columns, setColumns] = React.useState<any[]>([])
  const [issueList, setIssues] = React.useState<
    {
      name: string
      message: string
      type: 'duplicate' | 'tooMuch' | 'notFound'
    }[]
  >([])
  const [totalValueToLiquidate, setTotalValueToLiquidate] =
    React.useState<number>()
  const [totalLiquidationEvents, setTotalLiquidationEvents] =
    React.useState<number>()
  const [totalEntitiesToUpdate, setTotalEntitiesToUpdate] =
    React.useState<number>()
  const [totalDuplicates, setTotalDuplicates] = React.useState<number>()
  const [valuesToUpdate, setValuesToUpdate] = React.useState<any[]>()
  const [token, setToken] = React.useState<string>()
  const [updating, setUpdating] = React.useState<string>()

  const { entities, loading, update } = React.useContext(DataContext)
  const notification = React.useContext(NotificationContext)
  const navigate = useNavigate()

  const firstStart = React.useRef<boolean>(true)
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app)
    const user = auth.currentUser
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user))
      }
      setNewToken()
    }

    firstStart.current = false
  }

  const processData = React.useCallback(() => {
    setIssues([])

    /* Filter the columns to just the ones in range */
    if (targetCells.length !== 2) {
      alert('Invalid target cells. Should be in the format: BS:BY')
      clear()
      return
    }

    /* Grab the columns that fall between the specified cells */
    const rangeIndex = [
      cols.findIndex((c) => c.name === targetCells[0]),
      cols.findIndex((c) => c.name === targetCells[1]),
    ]
    const columnsInRange = cols.slice(rangeIndex[0], rangeIndex[1] + 1)
    setColumns(columnsInRange)

    /* Remove unused columns dataset and rows with empty fields */
    const scrape = data
      .map((r) => {
        return columnsInRange.map((c) => r[c.key])
      })
      .filter((_, i) => i >= headerRowNumber - 1)

    /* Grab/remove the headings and key them to their values */
    const columnHeaders = scrape[0]

    /* Link the data to its respective header and remove rows that don't contain a project */
    const rows = scrape
      .slice(1, scrape.length)
      .map((d) => {
        const output: any = {}
        d.forEach((c, i) => {
          output[columnHeaders[i]] = c
        })
        return output
      })
      .filter((r) => r[projectNameColumn])

    /* Check the column marked as project names exists and has data */
    const nameCheck = rows.filter((r) => r[projectNameColumn])
    if (!nameCheck || nameCheck.length === 0) {
      alert(
        'Could not find the project name column. Make sure the formatting is the same as the spreadsheet.'
      )
      clear()
      return
    }

    /* Grab the fully quantified date headers that are not years */
    const dateColumns = columnHeaders.filter(
      (h) => h && String(h).length > 4 && Date.parse(h) // &&
      // new Date(h) >= new Date("1/1/2019 00:00:00 UTC") &&
      // new Date(h) <= new Date("1/1/2021 00:00:00 UTC")
    )

    /* Keep track of the indexes we've modified and list of objects to post to the server */
    const updatedIndexes: number[] = []
    const updatedEntities: LegalEntity[] = []

    /* Sanity check values */
    let totalLiquidations: number = 0
    let totalAlreadyExist: number = 0
    const totalPerColumn: number[] = []
    const issues: any[] = []

    /* Deep clone the entities so we don't mutate state */
    const entitiesClone = _deepClone(entities)

    /* For each available date check if all payments exist and create if not */
    dateColumns.forEach((d) => {
      /* Grab all rows that have a value for the date */
      const activeRows = rows.filter((r) => r[d])

      /* Tally the total for each month to sanity check */
      let totalInMonth = 0

      if (activeRows && activeRows.length > 0) {
        /* Check all contracts are in the spreadsheet to ensure nothing is missing */
        entitiesClone.forEach((e, i) => {
          e.projects &&
            e.projects.forEach((p) => {
              p.contracts &&
                p.contracts.forEach((c) => {
                  const target = rows.find(
                    (r) =>
                      r[projectNameColumn].toLowerCase() ===
                      c.name.toLowerCase()
                  )
                  const eventDate = new Date(d)
                  if (target) {
                    /* Check if it exists */
                    const eventName = `${p.name} ${
                      c.businessLine
                    } USD liquidation ${new Date(
                      eventDate.getFullYear(),
                      eventDate.getMonth() + 1,
                      0,
                      23,
                      59,
                      59
                    )
                      .toISOString()
                      .substring(0, 10)}`

                    if (c.liquidations) {
                      const duplicate = c.liquidations.find(
                        (l) => l.name === eventName
                      )

                      if (duplicate) {
                        totalAlreadyExist++
                        return
                      }
                    }

                    /* Make sure the value is a number */
                    target[d] =
                      typeof target[d] === 'string'
                        ? Number(target[d].replace(/\$|\–|,/g, '') || 0)
                        : target[d]

                    /* Check there is enough cash available on the contract */
                    if (target[d] > 0) {
                      let totalCashAvailable = 0
                      c.fees &&
                        c.fees.forEach((f) => {
                          if (f.symbol === 'USD') {
                            totalCashAvailable += Number(f.quantity)
                          }
                        })
                      c.liquidations &&
                        c.liquidations.forEach((l) => {
                          if (l.symbol === 'USD') {
                            totalCashAvailable -= Number(l.quantity)
                          }
                        })

                      /* If the request is larger than the max amount change the value and warn the user */
                      if (target[d] > totalCashAvailable) {
                        const max = target[d]
                        if (c.name == 'IBMR - Advisory - 7/16/2019') {
                          alert(totalCashAvailable)
                        }
                        issues.push({
                          name: c.name,
                          message: `Does not have ${currencyFormatter(
                            max
                          )} to liquidate, max available ${currencyFormatter(
                            totalCashAvailable
                          )}. The value has been changed to ${currencyFormatter(
                            totalCashAvailable
                          )}.`,
                          type: 'tooMuch',
                        })
                        target[d] = totalCashAvailable
                      }

                      /* Create the liquidation object to insert in to the contract */
                      const doc: Liquidation = {
                        name: eventName,
                        quantity: target[d],
                        price: 1,
                        type: 'Cash',
                        symbol: 'USD',
                        date: new Date(d),
                        tags: [],
                      }

                      let finished = false

                      /* If no liquidations object exists yet create it */
                      if (!c.liquidations) {
                        c.liquidations = [doc]
                      } else {
                        /* Check it wasn't already added (based on title only, changing the format would break it) */
                        const duplicate = c.liquidations.find(
                          (l) => l.name === doc.name
                        )

                        /* If it's not a duplicate add it */
                        if (!duplicate) {
                          c.liquidations.push(doc)
                          totalLiquidations++
                          totalInMonth += Number(doc.quantity)
                        }
                      }

                      /* Update the list indexes and objects we want to post to update  */
                      if (!!!updatedIndexes.includes(i)) {
                        updatedIndexes.push(i)
                        updatedEntities.push(e)
                      } else {
                        updatedEntities[updatedIndexes.indexOf(i)] = e
                      }
                    } else {
                      // alert(target[d]);
                    }
                  } else {
                    // Handle missing
                    if (
                      !issues.find(
                        (i) => i.name === c.name && i.type === 'notFound'
                      )
                    ) {
                      issues.push({
                        name: c.name,
                        message: `No values for this contract exist in your spreadsheet.`,
                        type: 'notFound',
                      })
                    }
                  }
                })
            })
        })
        totalPerColumn.push(totalInMonth)
      } else {
        // clear();
        // alert("Could not find the data in the spreadsheet");
      }
    })

    /* If theres nothing to do remove the sheet from state so it can but uploaded again */
    if (totalLiquidations === 0) {
      alert('Already up to date!')
      clear()
    }

    setIssues(issues)
    setValuesToUpdate(updatedEntities)
    setTotalEntitiesToUpdate(updatedEntities.length)
    setTotalValueToLiquidate(
      totalPerColumn.reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
    )
    setTotalLiquidationEvents(totalLiquidations)
    setTotalDuplicates(totalAlreadyExist)

    // updatedEntities.map((e: any) => {
    //   e.projects.map((p: any) => {
    //     p.contracts.map((c: any) => {
    //       let total = 0;
    //       if (c.liquidations) {
    //         c.liquidations.forEach((l: any) => {
    //           total += l.quantity;
    //         });
    //         alert(`${c.name}: ${total}`);
    //       }
    //     });
    //   });
    // });
  }, [
    data,
    cols,
    entities,
    targetCells,
    amountColumn,
    projectNameColumn,
    dateToRecord,
  ])

  const processUpdate = React.useCallback(async () => {
    setProcessing(true)

    if (!valuesToUpdate) throw new Error('Values to update not set')

    for (let i = 0; i < valuesToUpdate.length; i++) {
      const body = valuesToUpdate[i] as LegalEntity
      setUpdating(body.name)

      try {
        const response = await putLegalEntity(body.id!, token!, body)

        if (!response.error && response.id) {
          update(response, 'entities', response.id)
          continue
        } else {
          notification.set({
            message: `Failed to save ${body.name}: ${
              response?.message
                ? response.message
                : response?.status
                ? response.status + ' Error'
                : 'Unknown error'
            }`,
            type: 'error',
          })
        }
      } catch (e: any) {
        notification.set({
          message: `Failed to save ${body.name}: ${
            e?.message
              ? e.message
              : e?.status
              ? e.status + ' Error'
              : 'Unknown error'
          }`,
          type: 'error',
        })
        console.error(e)
      }
    }

    setUpdating('Competed!')
    navigate('/contracts/liquidations/pending')
  }, [valuesToUpdate, navigate])

  React.useEffect(() => {
    processData()
  }, [])

  if (loading || !entities.length || !columns.length || !valuesToUpdate)
    return null

  return (
    <>
      <Modal
        open={open}
        // onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-upload'
        aria-describedby='modal-modal-confirm-upload'
      >
        <Box sx={{ ...formModalStyle, maxHeight: '100%', overflow: 'auto' }}>
          <Typography variant='h6' sx={{ mb: 1, fontWeight: 700 }}>
            Summary
          </Typography>
          <Box sx={{ mb: 1 }}>
            <Typography>
              {totalLiquidationEvents ?? 0} payments on{' '}
              {totalEntitiesToUpdate ?? 0} contracts totalling{' '}
              {currencyFormatter(totalValueToLiquidate ?? 0)}
              <br />
              {totalDuplicates} existing payments are being ignored.
            </Typography>
          </Box>
          {!processing ? (
            <>
              <Typography variant='h6' sx={{ mb: 1, fontWeight: 700 }}>
                Warnings
              </Typography>
              {issueList.length > 0 ? (
                <>
                  {issueList.map((issue, i) => (
                    <div key={`issue-${i}`}>
                      <Typography
                        variant='body1'
                        sx={{ mb: 0.5, fontWeight: 500 }}
                      >
                        {issue.name}
                      </Typography>
                      <Typography variant='body2' sx={{ mb: 2 }}>
                        {issue.message}
                      </Typography>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Typography sx={{ mb: 2 }}>No issues found!</Typography>
                </>
              )}

              <LoadingButton
                fullWidth
                variant='outlined'
                sx={{ color: 'white', mt: 3, mb: 0 }}
                onClick={() => processUpdate()}
                disabled={!!!token}
              >
                Proceed
              </LoadingButton>
              <Button
                fullWidth
                sx={{ mt: 1 }}
                color='error'
                variant='text'
                onClick={() => {
                  setOpen(false)
                  clear()
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CircularProgress
                color='primary'
                size={22}
                thickness={6}
                sx={{ mr: 2 }}
              />
              <Typography variant='subtitle1'>Updating: {updating}</Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  )
}

const make_cols = (refstr: string) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
  return o
}

/* list of supported file types */
const SheetJSFT = [
  'xlsx',
  'xlsb',
  'xlsm',
  'xls',
  'xml',
  'csv',
  'txt',
  'ods',
  'fods',
  'uos',
  'sylk',
  'dif',
  'dbf',
  'prn',
  'qpw',
  '123',
  'wb*',
  'wq*',
  'html',
  'htm',
]
  .map(function (x) {
    return '.' + x
  })
  .join(',')

export default LiquidateCashExcel
