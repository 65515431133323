import { Project } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const projectRows = (projects: Project[]) => {
  const rows: GridRowsProp = projects.map((project, i) => {
    return {
      id: i,
      name: project.name,
      tags: project.tags,
    };
  });

  return rows;
};

export default projectRows;
