import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  dateColDef,
  infoShortColDef,
  ApprovedActionsColDef,
  currencyColDef,
} from "./common";

const snapshotApprovedListColDef = ({
  handleEditClick,
  handleDeleteClick,
}: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Name") },
    {
      ...currencyColDef("Total", "totalValue"),
    },
    { ...infoShortColDef("Total Payees", "totalPayees") },
    {
      ...dateColDef("approved", "Approved"),
    },
    { ...ApprovedActionsColDef() },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default snapshotApprovedListColDef;
