import * as React from "react";
import Box from "@mui/material/Box";
import {
  useGridApiRef,
  DataGridPro,
  GridRowId,
  GridColumns,
} from "@mui/x-data-grid-pro";
import { GridToolbar } from "../";
import {
  CommissionModel,
  CommissionPool,
  Contract,
  LegalEntity,
  Payee,
} from "../../../contract/types";

export default function GridBase({
  name,
  data,
  rowFormatter,
  colDef,
  FormModal,
  DeleteModal,
  deleteMethod,
  idMap,
  payees,
  models,
  projectIndex,
  contractIndex,
  legalEntityId,
  legalEntityData,
  modelData,
  poolData,
  contractData,
  formType,
  deleteById,
  onClick,
  initialState,
  defaultRows,
  style,
  checkboxSelection,
  createSnapshot,
  deletePath,
  objectToEdit,
  deleteType,
  isList,
}: Props) {
  const [openFormModal, setOpenFormModal] = React.useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [docToUpdate, setDocToUpdate] = React.useState<{
    [key: string]: any;
  }>();
  const [indexToDelete, setIndexToDelete] = React.useState<number>();
  const [selectionModel, setSelectionModel] = React.useState<any[]>([]);
  const apiRef = useGridApiRef();

  const handleEditClick = (id: GridRowId) => (event: React.MouseEvent) => {
    event.stopPropagation();
    const docToEdit = !deleteById
      ? id
      : data[data.findIndex((p) => p.id === id)];

    setDocToUpdate(docToEdit);
    setOpenFormModal(true);
  };

  const handleDeleteClick = (id: GridRowId) => (event: React.MouseEvent) => {
    event.stopPropagation();
    const docToEdit = !deleteById
      ? data[Number(id)]
      : data[data.findIndex((p) => p.id === id)];
    !deleteById && setIndexToDelete(id as any);
    setDocToUpdate(docToEdit);
    setOpenDeleteModal(true);
  };

  React.useEffect(() => {
    if (!openFormModal) setDocToUpdate(undefined);
  }, [openFormModal]);

  const columns = colDef({
    handleEditClick,
    handleDeleteClick,
    idMap,
    payees,
    models,
  });

  const rows = rowFormatter(data);

  if (!rows || !columns) return null;
  return (
    <Box
      sx={{
        height: name || isList ? "auto" : "100%",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        sx={{
          border: "none",
          cursor: onClick ? "pointer" : "cursor",
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            background: "#0e0e0e",
            width: 12,
            height: 12,
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-corner": {
            background: "#0e0e0e",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:vertical": {
            background: "rgb(88,88,88, 0.7)",
            width: 10,
            borderRadius: 10,
            opacity: 0.1,
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:horizontal":
            {
              background: "rgb(88,88,88, 0.7)",
              height: 10,
              borderRadius: 10,
              opacity: "0.1!important",
            },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover:horizontal":
            {
              background: "rgb(88,88,88, 0.8)",
            },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover:vertical":
            {
              background: "rgb(88,88,88, 0.8)",
            },
          "& .MuiDataGrid-main": {
            "::-webkit-scrollbar": { width: 20 },
            "::-webkit-scrollbar-track": { display: "none!important" },
            "::-webkit-scrollbar-thumb": {
              background: "red",
              borderRadius: 10,
            },
          },
          "& .MuiDataGrid-footerContainer": { height: "1px", minHeight: "1px" },
          "& .MuiDataGrid-cell:focus": {
            outline: "none!important",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none!important",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: `rgba(255, 255, 255, ${onClick ? "0.08" : 0})`,
          },
          "& .MuiDataGrid-row.Mui-hovered": {
            backgroundColor: `rgba(255, 255, 255, ${onClick ? "0.08" : 0})`,
          },
        }}
        editMode="row"
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            name,
            apiRef,
            setDocToUpdate,
            setOpenFormModal,
            createSnapshot,
            selectionModel,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onRowClick={onClick ? (data) => onClick(data) : undefined}
        autoHeight={!name && !isList ? false : true}
        initialState={initialState}
        checkboxSelection={checkboxSelection}
        isRowSelectable={() => false}
        onSelectionModelChange={(newSelection) => {
          const newSelectionData = rows.filter((d) => {
            return newSelection.includes(d.id);
          });
          setSelectionModel(newSelectionData);
        }}
        density="compact"
        columnBuffer={20}
        columnThreshold={20}
        rowBuffer={rows.length}
        rowThreshold={rows.length}
        hideFooterRowCount={true}
      />
      {FormModal && (
        <FormModal
          open={openFormModal}
          data={docToUpdate}
          itemIndex={docToUpdate}
          handleOpenState={setOpenFormModal}
          projectIndex={projectIndex}
          contractIndex={contractIndex}
          legalEntityId={legalEntityId}
          legalEntityData={legalEntityData}
          modelData={modelData}
          poolData={poolData}
          contractData={contractData}
          type={formType}
        />
      )}
      {DeleteModal && (
        <DeleteModal
          openState={openDeleteModal}
          handleOpenState={setOpenDeleteModal}
          id={docToUpdate?.id ?? docToUpdate}
          name={docToUpdate?.name!}
          deleteMethod={deleteMethod}
          successURL={0}
          path={deletePath}
          index={indexToDelete}
          objectToEdit={objectToEdit}
          deleteType={deleteType}
        />
      )}
    </Box>
  );
}

interface Props {
  name: string;
  data: any[];
  rowFormatter: (data: any[]) => readonly any[];
  FormModal?: ({}: { [key: string]: any }) => JSX.Element;
  DeleteModal?: ({}: { [key: string]: any }) => JSX.Element;
  colDef: ({
    handleEditClick,
    handleDeleteClick,
    idMap,
  }: ColProps) => GridColumns<any>;
  deleteMethod: (id: string, token: string, body?: any) => Promise<any>;
  deleteById: boolean;
  idMap?: any[];
  payees?: Payee[];
  models?: CommissionModel[];
  projectIndex?: number;
  contractIndex?: number;
  legalEntityId?: string;
  legalEntityData?: LegalEntity;
  modelData?: CommissionModel;
  poolData?: CommissionPool;
  contractData?: Contract;
  formType?: string;
  onClick?: (data: any) => void;
  initialState?: { [key: string]: any };
  defaultRows?: number;
  style?: any;
  checkboxSelection?: boolean;
  createSnapshot?: boolean;
  deletePath?: string;
  objectToEdit?: { [key: string]: any };
  deleteType?: string;
  isList?: boolean;
}

interface ColProps {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  idMap?: any[];
  payees?: Payee[];
  models?: CommissionModel[];
}
