import { CommissionPool, Payee } from "../../../contract/types";
import { PoolModal, DeleteByIndexModal } from "../../modals";
import { GridBase, poolPayeeColDef, poolPayeeRows } from "..";
import { putCommissionPool } from "../../../api/put";

const PoolPayeeDataGrid = ({ payees, poolData, deletePath }: Props) => {
  return (
    <GridBase
      name="Payee"
      FormModal={PoolModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putCommissionPool}
      data={payees}
      rowFormatter={poolPayeeRows}
      colDef={poolPayeeColDef}
      deleteById={false}
      formType="payees"
      poolData={poolData}
      deletePath={deletePath}
      objectToEdit={poolData}
      deleteType="pools"
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

interface Props {
  payees: Payee[];
  tags?: string[];
  poolData: CommissionPool;
  deletePath: string;
}

export default PoolPayeeDataGrid;
