import { useEffect, useState, useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import BackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography, IconButton, Chip, Button } from "@mui/material";
import { AuthContext } from "../../context/role";

const Heading = ({ title, tags, extraText, handleOpenState }: Props) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const navigate = useNavigate();

  const authState = useContext(AuthContext);

  useEffect(() => {
    if (authState.role === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [authState]);

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        sx={{ mb: 0, mt: -2, ml: -1 }}
        size="medium"
      >
        <BackIcon sx={{ fontSize: 17 }} />
        &nbsp;Back
      </Button>
      <Typography variant="h4" sx={{ mb: 1.5, fontWeight: 500, fontSize: 30 }}>
        {title}{" "}
        {handleOpenState && (
          <IconButton
            onClick={() => handleOpenState(true)}
            sx={{ mb: 0.5 }}
            disabled={!isAdmin}
          >
            <EditIcon />
          </IconButton>
        )}
      </Typography>
      <div>
        {tags &&
          tags.map((tag, i) => (
            <Chip
              label={tag}
              sx={{ mr: 0.5, mb: 0.5 }}
              key={(Math.random() + 1).toString(36).substring(7)}
            />
          ))}
      </div>
      {extraText ? (
        <Box
          sx={{
            marginTop: tags?.length ? 3 : 1.6,
            marginBottom: tags?.length ? 4.5 : 2,
            display: "flex",
          }}
        >
          <InfoIcon sx={{ height: 16, lineHeight: 1 }} />
          <Typography
            variant="body1"
            sx={{ lineHeight: 1, fontWeight: 400, opacity: 0.8 }}
          >
            {extraText}
          </Typography>
        </Box>
      ) : (
        <div style={{ height: 20 }} />
      )}
    </>
  );
};

interface Props {
  title: string;
  tags?: string[];
  extraText?: string;
  handleOpenState?: (value: React.SetStateAction<boolean>) => void;
}

export default Heading;
