import { GridColumns } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  dateColDef,
  infoShortColDef,
  currencyColDef,
  percentageColDef,
  numberColDef,
} from "./common";

const commissionEventColDef = () => {
  const columns: GridColumns = [
    { ...nameColDef("Name", "name") },
    { ...dateColDef("date", "Date") },
    {
      ...currencyColDef("Value USD", "value"),
    },
    {
      ...infoShortColDef("Symbol", "symbol"),
      width: 90,
    },
    {
      ...numberColDef("Quantity", "quantity"),
    },
    {
      ...infoShortColDef("Type", "type"),
      width: 90,
    },
    {
      ...infoShortColDef("Source", "source"),
      width: 160,
    },
    {
      ...percentageColDef("Percent", "percentage"),
      width: 80,
    },

    { ...nameColDef("Liquidation", "liquidationName"), width: 300 },
    { ...infoShortColDef("Contract", "contractName"), width: 330 },
    {
      ...infoShortColDef("Comm. Model", "model"),
      width: 165,
    },
  ];

  return columns;
};

export default commissionEventColDef;
