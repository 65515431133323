import { useEffect, useRef } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { TextInput, DateInput, ArrayInput } from "../../inputs";
import { Contract } from "../../../../contract/types";
import { FormNestedBorder } from "../../common";
import { Typography, Box } from "@mui/material";

const ContractQuickLiquidation = ({
  tags,
  itemIndex,
  symbol,
  max,
  title,
}: Props) => {
  const {
    control,
    register,
    setValue,
    setFocus,
    formState: { errors },
  } = useFormContext();

  const { fields, append } = useFieldArray<Contract, "liquidations", "index">({
    control: control as any,
    name: "liquidations",
    keyName: "index",
  });

  const firstLoad = useRef<boolean>(true);

  if (firstLoad.current && typeof itemIndex === "undefined") {
    firstLoad.current = false;
    let r = (Math.random() + 1).toString(36).substring(7);
    append({
      name: `${title} ${symbol} liquidation (${r})`,
      quantity: undefined,
      symbol: symbol,
      price: undefined,
      type: symbol === "USD" ? "Cash" : "Token",
      date: new Date(),
      approved: "",
      tags: [],
    });
  }

  useEffect(() => {
    setFocus(`liquidations.${fields.length - 1}.quantity`);
  }, [setFocus]);

  return (
    <>
      <Box sx={{ textAlign: "center", mb: 1.7, mt: -1.3 }}>
        <Typography variant="body2">
          <b>Max:</b> {symbol} {max}
        </Typography>
      </Box>

      {fields.map((field, index) => {
        if (
          typeof itemIndex === "number"
            ? index === itemIndex
            : index === fields.length - 1
        ) {
          return (
            <FormNestedBorder uid={field.index} key={field.index}>
              <TextInput
                name={`liquidations.${index}.name`}
                label="Event Title"
                defaultValue={field.name}
                required={true}
                register={register}
                errors={errors.liquidations?.[index]?.name}
              />

              <ArrayInput
                name={`liquidations.${index}.type`}
                label="Fee Type"
                register={register}
                setValue={setValue}
                options={["Cash", "Rewards", "Fund", "Token", "Reserve"]}
                defaultValue={field.type}
                errors={errors.liquidations?.[index]?.type}
                multiple={false}
                required={true}
              />

              <DateInput
                name={`liquidations.${index}.date`}
                label="Liquidation Date"
                value={field.date as Date | null}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors.liquidations?.[index]?.date}
                required={true}
                maxDate={new Date()}
              />

              {/* <DateInput
                name={`liquidations.${index}.approved`}
                label="Approved Date"
                value={field.date as Date | null}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors.liquidations?.[index]?.approved}
                required={false}
                maxDate={new Date()}
              /> */}

              <TextInput
                name={`liquidations.${index}.quantity`}
                label="Quantity"
                defaultValue={field.quantity}
                type="number"
                required={true}
                register={register}
                errors={errors.liquidations?.[index]?.quantity}
              />

              <TextInput
                name={`liquidations.${index}.price`}
                label="Price"
                defaultValue={field.price}
                type="number"
                required={true}
                register={register}
                errors={errors.liquidations?.[index]?.price}
              />

              <ArrayInput
                name={`liquidations.${index}.tags`}
                label="Tags"
                register={register}
                setValue={setValue}
                options={tags}
                defaultValue={field.tags}
                errors={errors.liquidations?.[index].tags}
                multiple={true}
              />
            </FormNestedBorder>
          );
        } else return null;
      })}
    </>
  );
};

interface Props {
  update?: Contract;
  tags: string[];
  itemIndex?: number;
  symbol: string;
  max: number;
  title: string;
}

export default ContractQuickLiquidation;
