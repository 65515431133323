import { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import potentialLiquidationsColDef from "./PotentialLiquidationsColDef";

const PotentialLiquidationsGrid = ({ data }: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const columns = potentialLiquidationsColDef();

  const pagination = [5, 10, 20, 50, 100];

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            columns={columns}
            rows={data.map((d, i) => ({
              ...d,
              id: i,
            }))}
            autoHeight
            pagination
            sx={{ border: "none" }}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pageSize={rowsPerPage}
            onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
            isRowSelectable={() => false}
          />
        </div>
      </div>
    </div>
  );
};

interface Props {
  data: {
    entityName: string;
    projectName: string;
    contractName: string;
    modelName?: string;
    commissionType: "pool" | "role" | "individual" | "override";
    commissionTypeName: string;
    symbol: string;
    quantity: number;
    value: number;
  }[];
}

export default PotentialLiquidationsGrid;
