import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import { dateColDef, tagsColDef, actionsColDef } from "./common";

const modelColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    { field: "name", headerName: "Name", width: 185 },
    { field: "businessLine", headerName: "Business Line", width: 150 },

    {
      ...dateColDef("startDate", "Start Date"),
    },
    {
      ...dateColDef("endDate", "End Date"),
    },
    { ...tagsColDef },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default modelColDef;
