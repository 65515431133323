import { GridRowsProp } from "@mui/x-data-grid-pro";
import { monthDiff } from "../../../utils";

const contractListRows = (contracts: any[]) => {
  const rows: GridRowsProp = contracts.map((contract, i) => {
    return {
      id: contract.id,
      ...contract,
      name: contract.name,
      project: contract.project,
      legalName: contract.legalName,
      contractDate: contract.contractDate,
      contractEndDate: contract.contractEndDate,
      businessLine: contract.businessLine,
      contractType: contract.contractType,
      commissionModel: contract.commissionModel ?? [],
      termMonths: contract.termMonths,
      cash: contract.cash ?? 0,
      tokens: contract.tokens ?? 0,
      manual: contract.manual,
      rewards: contract.rewards ?? 0,
      tokenPercent: contract.tokenPercent,
      equityFee: contract.equityFee ?? 0,
      cashTerms: contract.cashTerms ?? "",
      tokenTerms: contract.tokenTerms ?? "",
      roleAssignments: contract.roleAssignments ?? [],
      commissionOverrides: contract.commissionOverrides ?? [],
      tags: contract.tags ?? [],
    };
  });

  return rows;
};

export default contractListRows;
