import * as contract from "../../contract/types";
import { put, Error } from "../../utils";
import { processResponse } from "../../utils";

export async function putLegalEntity(
  id: string,
  token: string,
  body: contract.LegalEntity
): Promise<contract.LegalEntity & Error> {
  return put(`legalEntity/${id}`, body, token).then((res) => {
    return processResponse(res);
  });
}

export async function putCommissionModel(
  id: string,
  token: string,
  body: contract.CommissionModel
): Promise<contract.CommissionModel & Error> {
  return put(`commissionModel/${id}`, body, token).then((res) => {
    return processResponse(res);
  });
}

export async function putCommissionPool(
  id: string,
  token: string,
  body: contract.CommissionPool
): Promise<contract.CommissionPool & Error> {
  return put(`commissionPool/${id}`, body, token).then((res) => {
    return processResponse(res);
  });
}

export async function putCommissionRole(
  id: string,
  token: string,
  body: contract.CommissionRole
): Promise<contract.CommissionRole & Error> {
  return put(`commissionRole/${id}`, body, token).then((res) => {
    return processResponse(res);
  });
}

export async function putCommissionOverride(
  id: string,
  token: string,
  body: contract.CommissionOverride
): Promise<contract.CommissionOverride & Error> {
  return put(`commissionOverride/${id}`, body, token).then((res) => {
    return processResponse(res);
  });
}

export async function putPayee(
  id: string,
  token: string,
  body: contract.Payee
): Promise<contract.Payee & Error> {
  return put(`payee/${id}`, body, token).then((res) => {
    return processResponse(res);
  });
}

export async function putCommissionData(
  id: string,
  token: string,
  body: contract.CommissionData
): Promise<contract.CommissionData & Error> {
  return put(`commission/${id}`, body, token).then((res) => {
    return processResponse(res);
  });
}

export async function putSnapshotApproval(
  id: string,
  token: string
): Promise<boolean> {
  return put(`snapshot/${id}/approve`, undefined, token).then((res) => {
    return res.status === 200;
  });
}

export async function putSnapshotUpdate(
  id: string,
  token: string,
  body: contract.Snapshot
): Promise<boolean> {
  return put(`snapshot/${id}`, body, token).then((res) => {
    return res.status === 200;
  });
}

export async function putTag(tag: string, token: string): Promise<boolean> {
  return put(`tags`, { tag }, token).then((res) => {
    return res.status === 200;
  });
}
