import { createContext, useState, useEffect } from "react";
import { app } from "../components/firebase";
import { getAuth, User } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";

const initialState: AuthState = {
  user: null,
  role: "",
  loading: true,
};

export const AuthContext = createContext<AuthState>(initialState);

export const AuthContextProvider = (props: Props) => {
  const [authState, setAuthState] = useState<AuthState>(initialState);
  const location = useLocation();
  const auth = getAuth(app);
  const navigate = useNavigate();

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (!!!user) {
        setAuthState({ user: null, role: "", loading: false });
      } else {
        // The roles being set here reflect the rules of the backend.
        //
        // Changing these values has no effect on what can be read/written by the
        // user via the frontend.
        user.getIdTokenResult().then((data) => {
          let role = "";
          if (data.claims?.contract_viewer) {
            role = "contractOnly";
          }
          if (data.claims?.finance) {
            role = "finance";
          }
          if (data.claims?.admin) {
            role = "admin";
          }
          setAuthState({ user, role, loading: false });
        });
      }
    });

    return () => unregisterAuthObserver();
  }, []);

  useEffect(() => {
    if (authState.loading) return;
    if (authState.user === null && location.pathname !== "/login")
      navigate("/login");
    if (!!authState.user && location.pathname === "/login")
      navigate("/contracts");
  }, [location, authState]);

  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  );
};

interface AuthState {
  user: User | null;
  role: string;
  loading: boolean;
}

interface Props {
  children: JSX.Element | JSX.Element[];
}
