import { GridColDef } from "@mui/x-data-grid";

const nameColDef = (headerName: string, fieldName?: string) => {
  const nameColumn: GridColDef = {
    field: fieldName ?? "name",
    headerName: headerName,
    width: 210,
    valueFormatter: ({ value }) => value || "--",
  };
  return nameColumn;
};

export default nameColDef;
