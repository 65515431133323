import * as f from '../../common'
import { useState, useContext, useEffect, useRef } from 'react'
import { DataContext } from '../../../../context/data'
import { NotificationContext } from '../../../../context/notification'
import { Box } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CommissionRole } from '../../../../contract/types'
import { postCommissionRole } from '../../../../api/post'
import { putCommissionRole } from '../../../../api/put'
import { roleSchema } from '../../validation/schemas'
import { handleResponse, handleError } from '../../../../utils'
import { TextInput, ArrayInput, SubmitButton } from '../../inputs'
import { app } from '../../../firebase'
import { getAuth, getIdToken } from 'firebase/auth'

const RoleForm = ({ update, redirect, close }: Props) => {
  const [sending, setSending] = useState<boolean>(false)
  const [token, setToken] = useState<string>()

  const context = useContext(DataContext)
  const notifications = useContext(NotificationContext)

  const firstStart = useRef<boolean>(true)
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app)
    const user = auth.currentUser
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user))
      }
      setNewToken()
    }

    firstStart.current = false
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: zodResolver(roleSchema),
  })

  const onSubmitHandler: SubmitHandler<any> = async values => {
    if (!token) throw new Error('No token available')

    setSending(true)
    const body = { ...values }
    try {
      const response =
        update && update.id
          ? await putCommissionRole(update.id, token, body)
          : await postCommissionRole(body, token)

      const result = handleResponse(response, notifications)

      if (result) {
        if (!response.id) throw new Error('The server returned an object with no id')

        context.update(response, 'roles', response.id)
        context.refreshAll()
        close()
      } else {
        /* Stop the loading spinner */
        setSending(false)
      }
    } catch (e: any) {
      handleError(e, notifications)
      setSending(false)
    }
  }

  useEffect(() => {
    if (update) {
      setValue('name', update.name)
      setValue('tags', update.tags ? update.tags : [])
    } else {
      setValue('tags', [])
    }
  }, [])

  return (
    <f.FormContainer>
      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <TextInput
          name='name'
          label='New title'
          defaultValue={update?.name}
          required={true}
          register={register}
          errors={errors.name}
        />

        <ArrayInput
          name='tags'
          label='Tags'
          register={register}
          setValue={setValue}
          options={context.tags}
          defaultValue={update?.tags}
          errors={errors.tags}
          multiple={true}
        />

        <SubmitButton
          sending={sending}
          update={!!update}
          roles={['admin', 'finance']}
          disabled={!!!token}
        />
      </Box>
    </f.FormContainer>
  )
}

interface Props {
  update?: CommissionRole
  redirect?: string | number
  close: () => void
}

export default RoleForm
