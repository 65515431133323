import { memo } from "react";
import { Chip } from "@mui/material";

export const InfoChip = ({ label, variant }: ChipProps) => {
  let r = (Math.random() + 1).toString(36).substring(7);

  return (
    <Chip
      label={label}
      style={{ margin: 2 }}
      key={r}
      size="small"
      variant={variant}
    />
  );
};

const InfoChipMemo = memo(InfoChip);

interface ChipProps {
  label: string;
  variant: "outlined" | "filled";
}

export default InfoChipMemo;
