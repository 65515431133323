import * as f from "../../common";
import { Box, Typography } from "@mui/material";
import { useState, useContext, useCallback, useRef } from "react";
import { putSnapshotApproval } from "../../../../api/put";
import { DataContext } from "../../../../context/data";
import { NotificationContext } from "../../../../context/notification";
import { LoadingButton } from "@mui/lab";
import { app } from "../../../firebase";
import { getAuth, getIdToken } from "firebase/auth";

const SnapshotApprovalForm = ({ snapshotId, close }: Props) => {
  const [sending, setSending] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const { snapshots, update, refreshAll } = useContext(DataContext);
  const notifications = useContext(NotificationContext);

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const approve = useCallback(async () => {
    if (!token) throw new Error("No token available");

    setSending(true);
    const snapshotIndex = snapshots.findIndex((s) => s.id === snapshotId);
    const updated = { ...snapshots[snapshotIndex] };
    updated.approved = new Date();

    try {
      const response = await putSnapshotApproval(snapshotId, token);
      if (response) {
        notifications.set({
          message: `Saved approval`,
          type: "success",
        });
        update(updated, "snapshots", snapshotId);
        refreshAll();
        close();
      } else {
        notifications.set({
          message: `Unable to update`,
          type: "error",
        });
        setSending(false);
      }
    } catch (e: any) {
      notifications.set({
        message: `Failed to save: ${
          e?.message
            ? e.message
            : e?.status
            ? e.status + " Error"
            : "Unknown error"
        }`,
        type: "error",
      });
      setSending(false);
    }
  }, [snapshots, snapshotId, token]);

  return (
    <f.FormContainer>
      <Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to approve?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 1 }}>
          You cannot edit a snapshot once it has been approved.
        </Typography>
        <LoadingButton
          variant="outlined"
          fullWidth
          type="submit"
          loading={sending}
          sx={{ py: "0.8rem", mt: "1.7rem", color: "white" }}
          color="success"
          onClick={() => approve()}
          disabled={!!!token}
        >
          Approve
        </LoadingButton>
      </Box>
    </f.FormContainer>
  );
};

interface Props {
  snapshotId: string;
  close: () => void;
}

export default SnapshotApprovalForm;
