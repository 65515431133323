import { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Heading } from "../../components/profile";
import { ContractInfoDataGrid } from "../../components/datagrids";
import { DataContext } from "../../context/data";
import Loading from "../../layout/Loading";

const ListContract = () => {
  const params = useParams();
  const context = useContext(DataContext);

  if (!params.entityId || !params.entityData || !params.projectIndex) {
    console.error("Data not provided");
    return <Navigate to="/entity" />;
  }
  if (context.loading) return <Loading />;

  const entityIndex = context.entities.findIndex(
    (e) => e.id === params.entityId
  );
  const projectIndex = Number(params.projectIndex);
  const entityData = context.entities[entityIndex];
  const contractData = entityData.projects?.[projectIndex].contracts ?? [];
  const deletePath = `projects.${projectIndex}.contracts`;

  return (
    <>
      <Heading
        title={`${entityData.projects![projectIndex].name} Contracts`}
        extraText={"Manage contract terms, commission roles and liquidations."}
      />
      <div style={{ height: 15 }} />
      <ContractInfoDataGrid
        contracts={contractData}
        legalEntityData={entityData}
        legalEntityId={entityData.id!}
        projectIndex={projectIndex}
        deletePath={deletePath}
        objectToEdit={entityData}
      />
    </>
  );
};

export default ListContract;
