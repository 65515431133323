import * as f from '../../common'
import { useState, useEffect, useContext, useRef } from 'react'
import { payeeSchema } from '../../validation/schemas'
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Payee } from '../../../../contract/types'
import { postPayee } from '../../../../api/post'
import { putPayee } from '../../../../api/put'
import { Box } from '@mui/material'
import { TextInput, DateInput, ArrayInput, SubmitButton } from '../../inputs'
import { DataContext } from '../../../../context/data'
import { NotificationContext } from '../../../../context/notification'
import { app } from '../../../firebase'
import { getAuth, getIdToken } from 'firebase/auth'
import { handleResponse, handleError } from '../../../../utils'

const PayeeForm = ({ update, close }: Props) => {
  const [sending, setSending] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(
    update && update.startDate ? new Date(update.startDate) : null,
  )
  const [endDate, setEndDate] = useState<Date | null>(
    update && update.endDate ? new Date(update.endDate) : null,
  )
  const [tagOptions, setTagOptions] = useState<string[]>([])
  const [token, setToken] = useState<string>()

  const firstStart = useRef<boolean>(true)
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app)
    const user = auth.currentUser
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user))
      }
      setNewToken()
    }

    firstStart.current = false
  }

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: zodResolver(payeeSchema),
  })

  useEffect(() => {
    if (update) {
      setValue('startDate', new Date(update.startDate))
      setValue('tags', update.tags ? update.tags : [])
      if (update.endDate) {
        setValue('endDate', new Date(update.endDate))
      }
    } else {
      setValue('tags', [])
    }
  }, [])

  const onSubmitHandler: SubmitHandler<any> = async values => {
    if (!token) throw new Error('No token available')

    setSending(true)
    const body: Payee = {
      ...values,
    }
    try {
      const response =
        update && update.id ? await putPayee(update.id, token, body) : await postPayee(body, token)

      const result = handleResponse(response, notifications)

      if (result) {
        if (!response.id) throw new Error('The server returned an object with no id')

        context.update(response, 'payees', response.id)
        context.refreshAll()
        close()
      } else {
        /* Stop the loading spinner */
        setSending(false)
      }
    } catch (e: any) {
      handleError(e, notifications)
      setSending(false)
    }
  }

  const context = useContext(DataContext)
  const notifications = useContext(NotificationContext)

  useEffect(() => {
    if (context.tags) {
      setTagOptions(context.tags)
    }
  }, [context])

  return (
    <f.FormContainer>
      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <TextInput
          name='name'
          label='Name'
          defaultValue={update?.name}
          required={true}
          register={register}
          errors={errors.name}
        />

        <TextInput
          name='email'
          label='Email'
          defaultValue={update?.email}
          required={true}
          register={register}
          errors={errors.email}
        />

        <DateInput
          name='startDate'
          label='Start Date'
          value={startDate}
          control={control}
          register={register}
          setValue={setValue}
          setParentState={setStartDate}
          errors={errors.startDate}
          required={true}
          maxDate={new Date()}
        />

        <DateInput
          name='endDate'
          label='End Date'
          value={endDate}
          control={control}
          register={register}
          setValue={setValue}
          setParentState={setEndDate}
          errors={errors.endDate}
          minDate={new Date()}
        />

        <ArrayInput
          name='tags'
          label='Tags'
          register={register}
          setValue={setValue}
          options={tagOptions}
          defaultValue={update?.tags}
          errors={errors.tags}
          multiple={true}
        />

        <SubmitButton
          sending={sending}
          update={!!update}
          roles={['admin', 'finance']}
          disabled={!!!token}
        />
      </Box>
    </f.FormContainer>
  )
}

interface Props {
  update?: Payee
  redirect?: string | number
  close: () => void
}

export default PayeeForm
