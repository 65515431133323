import { GridColDef } from "@mui/x-data-grid";

const infoLongColDef = (headerName: string, fieldName: string) => {
  const nameColumn: GridColDef = {
    field: fieldName,
    headerName: headerName,
    width: 450,
    valueFormatter: ({ value }) => value || "--",
  };
  return nameColumn;
};

export default infoLongColDef;
