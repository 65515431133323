import { useState, useCallback, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { deleteStyle } from "../common";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../context/data";
import { app } from "../../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { NotificationContext } from "../../../context/notification";
import cloneDeep from "lodash/cloneDeep";

export default function DeleteByIndexModal({
  name,
  id,
  objectToEdit,
  path,
  index,
  deleteMethod,
  handleOpenState,
  openState,
  deleteType,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const handleClose = () => {
    if (sending) return;
    handleOpenState ? handleOpenState(false) : setOpen(false);
  };

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const { update, refreshAll } = useContext(DataContext);
  const notifications = useContext(NotificationContext);

  const confirmedDelete = useCallback(async () => {
    if (!token) throw new Error("No token available");

    setSending(true);
    const pathToDelete = `${path}.${index}`;
    const updatedObj = cloneDeep(objectToEdit) as any;
    removeIndexByDotNotation(updatedObj, pathToDelete);

    if (!updatedObj.id) {
      setSending(false);
      notifications.set({ message: "Data is missing an ID", type: "error" });
    }

    try {
      const result = await deleteMethod(updatedObj.id, token, updatedObj);
      if (result) {
        update(updatedObj, deleteType, updatedObj.id);
        refreshAll();
        setTimeout(() => {
          notifications.set({ message: "Removed successfully", type: "error" });
          setSending(false);
          handleClose();
        }, 2000);
      }
    } catch (e) {
      refreshAll();
      console.error(e);
      setTimeout(() => {
        notifications.set({ message: "Enable to delete", type: "error" });
        setSending(false);
      }, 2000);
    }
  }, [path, index]);

  return (
    <>
      <Modal
        open={
          handleOpenState && typeof openState === "boolean" ? openState : open
        }
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete {name}?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action cannot be reversed
          </Typography>
          <LoadingButton
            variant="outlined"
            fullWidth
            loading={sending}
            sx={{ py: "0.8rem", mt: "2rem", color: "white" }}
            color="error"
            onClick={() => {
              confirmedDelete();
            }}
            disabled={!!!token}
          >
            Delete
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
}

function removeIndexByDotNotation(obj: any, path: string) {
  var keys = path.split(".");
  var targetIndex = keys.pop();

  while (keys.length) {
    path = keys.shift()!;
    obj = obj[path];
  }

  obj.splice(targetIndex, 1);

  return true;
}

interface Props {
  name: string;
  id: string;
  objectToEdit: { [key: string]: any };
  path: string;
  index: number;
  deleteMethod: (id: string, token: string, body: any) => Promise<boolean>;
  handleOpenState?: (value: React.SetStateAction<boolean>) => void;
  openState?: boolean;
  deleteType: any;
}
