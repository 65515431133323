import { Payee } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const poolPayeeRows = (payees: Payee[]) => {
  const rows: GridRowsProp = payees.map((payee, i) => {
    return {
      id: i,
      name: payee.name,
      email: payee.email,
      startDate: new Date(payee.startDate),
      endDate: payee.endDate ? new Date(payee.endDate) : null,
      tags: payee.tags,
    };
  });

  return rows;
};

export default poolPayeeRows;
