import { ContractModal, DeleteByIndexModal } from "../../modals";
import {
  GridBase,
  contractRoleAssignmentColDef,
  contractRoleAssignmentRows,
} from "..";
import { putLegalEntity } from "../../../api/put";
import { useNavigate } from "react-router-dom";
import { Contract, LegalEntity } from "../../../contract/types";

const ContractRoleAssignmentDataGrid = ({
  assignments,
  projectIndex,
  contractIndex,
  legalEntity,
  contract,
  formType,
  deletePath,
}: Props) => {
  const navigate = useNavigate();

  return (
    <GridBase
      name="Assignment"
      rowFormatter={contractRoleAssignmentRows}
      FormModal={ContractModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putLegalEntity}
      data={assignments}
      colDef={contractRoleAssignmentColDef}
      deleteById={false}
      projectIndex={projectIndex}
      contractIndex={contractIndex}
      legalEntityId={legalEntity.id}
      legalEntityData={legalEntity}
      contractData={contract}
      formType={formType}
      deletePath={deletePath}
      objectToEdit={legalEntity}
      initialState={{
        sorting: {
          sortModel: [{ field: "payeeName", sort: "asc" }],
        },
      }}
      deleteType="entities"
    />
  );
};

interface Props {
  assignments: any[];
  projectIndex: number;
  contractIndex: number;
  legalEntity: LegalEntity;
  contract: Contract;
  formType: "assignment";
  deletePath: string;
}

export default ContractRoleAssignmentDataGrid;
