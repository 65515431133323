import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  dateColDef,
  infoShortColDef,
  tagsColDef,
  ApproveColDef,
  currencyColDef,
} from "./common";

const snapshotListColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Name") },
    {
      ...currencyColDef("Total", "totalValue"),
    },
    { ...infoShortColDef("Total Payees", "totalPayees") },
    {
      ...dateColDef("approved", "Approved"),
    },
    { ...ApproveColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default snapshotListColDef;
