import { GridRowsProp } from "@mui/x-data-grid-pro";

const modelIndividualRuleRows = (rules: any[]) => {
  const rows: GridRowsProp = rules.map((rule, i) => {
    return {
      id: i,
      name: rule.name,
      email: rule.email,
      percentage: rule.percentage,
      tags: rule.tags,
    };
  });

  return rows;
};

export default modelIndividualRuleRows;
