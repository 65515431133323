import { ContractModal, DeleteByIndexModal } from "../../modals";
import { GridBase, contractFeeColDef, contractFeeRows } from "..";
import { Contract, LegalEntity } from "../../../contract/types";
import { putLegalEntity } from "../../../api/put";

const ContractFeeDataGrid = ({
  fees,
  projectIndex,
  contractIndex,
  legalEntity,
  contract,
  formType,
  deletePath,
}: Props) => {
  return (
    <GridBase
      name={contract.contractType === "Staking" ? "Reward" : "Fee"}
      rowFormatter={contractFeeRows}
      FormModal={ContractModal as any}
      DeleteModal={DeleteByIndexModal as any}
      deleteMethod={putLegalEntity}
      data={fees}
      colDef={contractFeeColDef}
      deleteById={false}
      projectIndex={projectIndex}
      contractIndex={contractIndex}
      legalEntityId={legalEntity.id}
      legalEntityData={legalEntity}
      contractData={contract}
      formType={formType}
      deletePath={deletePath}
      objectToEdit={legalEntity}
      deleteType="entities"
      initialState={{
        sorting: {
          sortModel: [{ field: "date", sort: "desc" }],
        },
      }}
    />
  );
};

interface Props {
  fees: any[];
  projectIndex: number;
  contractIndex: number;
  legalEntity: LegalEntity;
  contract: Contract;
  formType: "fee" | "reward";
  deletePath: string;
}

export default ContractFeeDataGrid;
