import { useRef, useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5percent from "@amcharts/amcharts5/percent";
import { CommissionData } from "../../contract/types";

// export const republicBlues = [
//   "#0AA2F2",
//   "#08AEF1",
//   "#07BBEF",
//   "#05C8EE",
//   "#03D5EC",
//   "#0B95F4",
//   "#0A9CF3",
//   "#09A3F2",
//   "#09AAF2",
//   "#08B1F1",
//   "#07B8F0",
//   "#06BFEF",
//   "#05C6EE",
//   "#04CDED",
//   "#04D4ED",
//   "#03DBEC",
//   "#02E2EB",
//   "#03DBEC",
//   "#04D4ED",
//   "#04CDED",
//   "#05C6EE",
//   "#06BFEF",
//   "#07B8F0",
//   "#08B1F1",
//   "#09AAF2",
//   "#09A3F2",
//   "#0A9CF3",
//   "#03D5EC",
//   "#05C8EE",
//   "#07BBEF",
//   "#08AEF1",
// ];

// const republicBlues = [
//   "#FFFFFF",
//   "#F8F8F8",
//   "#F5F5F5",
//   "#F0F0F0",
//   "#E8E8E8",
//   "#E0E0E0",
//   "#DCDCDC",
//   "#D8D8D8",
//   "#D3D3D3",
//   "#D0D0D0",

//   "#D3D3D3",
//   "#D8D8D8",
//   "#DCDCDC",
//   "#E0E0E0",
//   "#E8E8E8",
//   "#F0F0F0",
//   "#F5F5F5",
//   "#F8F8F8",
// ];

const republicBlues = ["#1660FA", "#167EFF", "#0B9DFF", "#02BDFF", "#01EFE9"];

const PortfolioBalancePie = ({ distribution, totalValue }: Props) => {
  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5percent.PieChart | null>(null);
  const seriesRef = useRef<am5percent.PieSeries | null>(null);
  const balanceRef = useRef<am5.Label | null>(null);

  useLayoutEffect(() => {
    if (!rootRef.current && !chartRef.current && !seriesRef.current) {
      rootRef.current = am5.Root.new("balancepiediv");

      const myTheme = am5.Theme.new(rootRef.current);
      myTheme.rule("Label").setAll({ fontFamily: "Roboto", fontWeight: "300" });

      rootRef.current.setThemes([
        am5themes_Animated.new(rootRef.current),
        am5themes_Dark.new(rootRef.current),
        myTheme,
      ]);

      chartRef.current = rootRef.current.container.children.push(
        am5percent.PieChart.new(rootRef.current, {
          innerRadius: 110,
          layout: rootRef.current.verticalLayout,
        })
      );

      seriesRef.current = chartRef.current.series.push(
        am5percent.PieSeries.new(rootRef.current, {
          valueField: "value",
          categoryField: "projectName",
        })
      );

      seriesRef!.current!.ticks.template.setAll({
        forceHidden: true,
      });

      seriesRef!.current!.labels.template.setAll({
        forceHidden: true,
      });

      let blueIndex = 0;

      seriesRef!.current!.get("colors")?.set(
        "colors",
        //@ts-ignore
        distribution.map((d) => {
          blueIndex++;
          if (blueIndex > republicBlues.length - 1) blueIndex = 1;
          return republicBlues[blueIndex - 1];
        })
      );

      seriesRef!.current.slices.template.setAll({
        stroke: am5.color("#121212"),
        strokeWidth: 1,
        strokeOpacity: 0.4,
      });

      // Add label
      balanceRef.current = rootRef.current.tooltipContainer.children.push(
        am5.Label.new(rootRef.current, {
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
          fill: am5.color("#fff"),
          fontSize: 32,
        })
      );
      balanceRef.current.set("text", `${totalValue}`);

      seriesRef!.current!.data.setAll(distribution);
      seriesRef!.current!.appear(1000, 100);
    }
  }, []);

  return (
    <div
      id="balancepiediv"
      style={{ height: 350, width: 350, margin: "auto" }}
    ></div>
  );
};

interface Props {
  distribution: CommissionData[];
  totalValue: string;
}

export default PortfolioBalancePie;
