import { useState, useCallback, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { deleteStyle } from "../common";
import { LoadingButton } from "@mui/lab";
import { app } from "../../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { DataContext } from "../../../context/data";
import { NotificationContext } from "../../../context/notification";

export default function DeleteByIdModal({
  id,
  name,
  deleteMethod,
  successURL,
  handleOpenState,
  openState,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const context = useContext(DataContext);
  const notifications = useContext(NotificationContext);

  const handleOpen = () =>
    handleOpenState ? handleOpenState(true) : setOpen(true);

  const handleClose = () => {
    if (sending) return;
    handleOpenState ? handleOpenState(false) : setOpen(false);
  };

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const confirmedDelete = useCallback(async () => {
    if (!token) throw new Error("No token available");

    setSending(true);
    try {
      const result = await deleteMethod(id, token);
      if (result) {
        context.refreshAll();
        setTimeout(() => {
          notifications.set({ message: "Removed successfully", type: "error" });
          setSending(false);
          handleClose();
        }, 2000);
      }
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  return (
    <>
      {!handleOpenState ? (
        <Button color="error" style={{ float: "right" }} onClick={handleOpen}>
          Delete
        </Button>
      ) : null}
      <Modal
        open={
          handleOpenState && typeof openState === "boolean" ? openState : open
        }
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete {name}?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action cannot be reversed
          </Typography>
          <LoadingButton
            variant="outlined"
            fullWidth
            loading={sending}
            sx={{ py: "0.8rem", mt: "2rem", color: "white" }}
            color="error"
            onClick={() => {
              confirmedDelete();
            }}
            disabled={!!!token}
          >
            Delete
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
}

interface Props {
  id: string;
  name: string;
  deleteMethod: (id: string, token: string) => Promise<boolean>;
  successURL: number | string;
  handleOpenState?: (value: React.SetStateAction<boolean>) => void;
  openState?: boolean;
}
