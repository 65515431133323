import { useEffect, useCallback, useState, useRef } from "react";
import { getPendingSnapshot } from "../../api/get";
import { PendingSnapshot } from "../../contract/types";
import { getAuth, getIdToken } from "firebase/auth";
import { app } from "../../components/firebase";
import CommissionEventDataGrid from "../../components/datagrids/elements/CommissionEventDataGrid";
import Loading from "../../layout/Loading";
import { Typography } from "@mui/material";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 8,
}).format;

const NewSnapshot = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>();
  const [total, setTotal] = useState<number>();
  const [snapshot, setSnapshot] = useState<PendingSnapshot>();
  const [commissionEventRows, setCommissionEventRows] = useState<
    CommissionRow[]
  >([]);

  const firstStart = useRef<boolean>(true);

  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const fetchData = useCallback(async () => {
    if (!token) throw new Error("No token present!");
    const snapshotData = await getPendingSnapshot(token);
    setSnapshot(snapshotData[0]);
  }, [token]);

  useEffect(() => {
    if (snapshot) {
      const commissionEventRows: CommissionRow[] = [];
      let total = 0;

      snapshot.payouts &&
        snapshot.payouts.forEach((payout) => {
          const row: CommissionRow = {
            name: payout.payeeName,
            date: new Date(payout.date),
            liquidationName: payout.liquidationName,
            projectName: payout.projectName,
            contractName: payout.contractName,
            quantity: Number(payout.commissionTotal),
            symbol: payout.symbol,
            value: Number(payout.valueUsd),
            model: payout.sourceLiquidation.modelName,
            source:
              payout.sourceLiquidation.poolName ??
              payout.sourceLiquidation.roleName ??
              payout.sourceLiquidation.modelName,
            type: capitalizeFirstLetter(
              payout.sourceLiquidation.commissionType
            ),
            percentage: payout.sourceLiquidation.percentage,
          };

          total += row.value;

          commissionEventRows.push(row);
        });

      setTotal(total);
      setCommissionEventRows(commissionEventRows);
      setLoading(false);
    }
  }, [snapshot]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  if (loading) return <Loading />;
  return (
    <>
      <div
        style={{
          marginTop: -28,
          marginLeft: -40,
          marginRight: -40,
          marginBottom: -49,
          height: "calc(100% + 55px)",
          width: "calc(100% + 80px)",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h4"
          sx={{ pl: 2, pt: 2, mb: -1, fontWeight: 500, fontSize: 30 }}
        >
          Total: {total ? currencyFormatter(total) : "0.00"}
        </Typography>
        <CommissionEventDataGrid events={commissionEventRows} />
      </div>
    </>
  );
};

interface CommissionRow {
  name: string;
  date: Date | string;
  liquidationName: string;
  projectName: string;
  contractName: string;
  symbol: string;
  quantity: number;
  value: number;
  source?: string;
  model?: string;
  pool?: string;
  role?: string;
  type?: string;
  percentage?: number;
}

export default NewSnapshot;
