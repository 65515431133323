import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ProjectIcon from "@mui/icons-material/AccountTree";
import ContractIcon from "@mui/icons-material/FormatListBulleted";
import PoolIcon from "@mui/icons-material/Pool";
import ModelIcon from "@mui/icons-material/AltRoute";
import RoleIcon from "@mui/icons-material/AssignmentInd";
import PayeeIcon from "@mui/icons-material/People";
import PendingIcon from "@mui/icons-material/Timelapse";
import SnapshotIcon from "@mui/icons-material/Camera";
import Tooltip from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { openedMixin, closedMixin, drawerWidth } from "./theme";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ open, handleDrawerClose }: SidebarProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{ elevation: 0 }}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: (theme) => theme.palette.background.paper,
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        {[
          {
            name: "Contracts",
            Icon: ContractIcon,
            link: "/contracts",
            alt: "Contract Database",
          },
          {
            name: "Partners",
            Icon: ProjectIcon,
            link: "/entity",
            alt: "Partners & Projects",
          },
          {
            name: "Comm. Models",
            Icon: ModelIcon,
            link: "/model",
            alt: "Commission Models",
          },
          { name: "Pools", Icon: PoolIcon, link: "/pool", alt: "Pools" },
          { name: "Roles", Icon: RoleIcon, link: "/role", alt: "Role Titles" },
          {
            name: "Payees",
            Icon: PayeeIcon,
            link: "/payee",
            alt: "Payee Database",
          },
        ].map((menuItem, index) => (
          <ListItemButton
            key={menuItem.name}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(menuItem.link)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname
                  .split("/")[1]
                  .includes(menuItem.link.split("/")[1])
                  ? theme.palette.primary.main
                  : undefined,
              }}
            >
              <Tooltip
                title={menuItem.alt}
                disableHoverListener={open}
                placement="right-end"
              >
                <menuItem.Icon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={menuItem.name}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <List>
        {[
          {
            name: "Pending",
            Icon: PendingIcon,
            link: "/data",
            alt: "Pending Snapshot",
          },
          {
            name: "Snapshots",
            Icon: SnapshotIcon,
            link: "/snapshot",
            alt: "Existing Snapshots",
          },
        ].map((menuItem, index) => (
          <ListItemButton
            key={menuItem.name}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(menuItem.link)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname
                  .split("/")[1]
                  .includes(menuItem.link.split("/")[1])
                  ? theme.palette.primary.main
                  : undefined,
              }}
            >
              <Tooltip
                title={menuItem.alt}
                disableHoverListener={open}
                placement="right-end"
              >
                <menuItem.Icon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={menuItem.name}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
}

interface SidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
}
