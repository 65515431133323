import { GridRowsProp } from "@mui/x-data-grid-pro";

const poolDiscretionaryCommissionRows = (rules: any[]) => {
  const rows: GridRowsProp = rules.map((rule, i) => {
    return {
      id: i,
      name: rule.name,
      reason: rule.reason,
      payeeName: rule.payeeName,
      email: rule.email,
      percentage: rule.percentage ? Number(rule.percentage) : 0,
      tags: rule.tags,
    };
  });

  return rows;
};

export default poolDiscretionaryCommissionRows;
