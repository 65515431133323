import { Card, Slide } from "@mui/material";

const FormCard = (props: Props) => {
  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Card sx={{ pb: 4, pt: 3, pl: 3, pr: 3, m: "auto", maxWidth: "36rem" }}>
        {props.children}
      </Card>
    </Slide>
  );
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export default FormCard;
