import * as f from '../../common'
import { Box } from '@mui/material'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState, useContext, useRef } from 'react'
import { CommissionPool, Payee } from '../../../../contract/types'
import { postCommissionPool } from '../../../../api/post'
import { putCommissionPool } from '../../../../api/put'
import { commissionPoolSchema } from '../../validation/schemas'
import { SubmitButton } from '../../inputs'
import { DataContext } from '../../../../context/data'
import { NotificationContext } from '../../../../context/notification'
import { app } from '../../../firebase'
import { getAuth, getIdToken } from 'firebase/auth'
import { handleResponse, handleError } from '../../../../utils'
import PoolInfo from './PoolInfo'
import PoolDiscretionaryCommissions from './PoolDiscretionaryCommissions'
import PoolPayeePoolMappings from './PoolPayeePoolMappings'

const PoolForm = ({ update, type, itemIndex, close }: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [sending, setSending] = useState<boolean>(false)
  const [tagOptions, setTagOptions] = useState<string[]>([])
  const [payees, setPayees] = useState<Payee[]>([])
  const [token, setToken] = useState<string>()

  const methods = useForm({
    resolver: zodResolver(commissionPoolSchema),
  })

  const context = useContext(DataContext)
  const notifications = useContext(NotificationContext)

  const firstStart = useRef<boolean>(true)
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app)
    const user = auth.currentUser
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user))
      }
      setNewToken()
    }

    firstStart.current = false
  }

  /* Need to have the tokens and payees before the form loads */
  useEffect(() => {
    if (context.tags && context.payees) {
      setTagOptions(context.tags)
      setPayees(context.payees)
    }
  }, [context])

  useEffect(() => {
    if (update) {
      methods.setValue('name', update.name)
      methods.setValue('discretionary', update.discretionary)
      methods.setValue('tags', update.tags ? update.tags : [])
      methods.setValue('owners', update.owners)
      methods.setValue(
        'discretionaryCommissions',
        update.discretionaryCommissions ? update.discretionaryCommissions : [],
      )
      methods.setValue(
        'payeePoolMappings',
        update.payeePoolMappings
          ? update.payeePoolMappings.map(payee => {
              const payeeData = {
                ...payee,
                startDate: new Date(payee.startDate),
              }
              if (payee.endDate) payeeData.endDate = new Date(payee.endDate)
              return payeeData
            })
          : [],
      )
    } else {
      methods.setValue('discretionaryCommissions', [])
      methods.setValue('payeePoolMappings', [])
      methods.setValue('tags', [])
    }
  }, [])

  useEffect(() => {
    if (tagOptions.length > 0 && payees.length > 0) setLoading(false)
  }, [tagOptions, payees])

  const onSubmitHandler: SubmitHandler<any> = async values => {
    if (!token) throw new Error('No token available')

    setSending(true)
    const newPool: CommissionPool = {
      ...values,
    }

    try {
      const response =
        update && update.id
          ? await putCommissionPool(update.id, token, newPool)
          : await postCommissionPool(newPool, token)

      const result = handleResponse(response, notifications)

      if (result) {
        if (!response.id) throw new Error('The server returned an object with no id')

        context.update(response, 'pools', response.id)
        context.refreshAll()
        close()
      } else {
        /* Stop the loading spinner */
        setSending(false)
      }
    } catch (e: any) {
      handleError(e, notifications)
      setSending(false)
    }
  }

  if (loading) return null
  return (
    <f.FormContainer>
      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          {type === 'info' ? (
            <>
              <PoolInfo update={update} payees={payees} tags={tagOptions} />
              <SubmitButton sending={sending} roles={['admin', 'finance']} update={!!update} />
            </>
          ) : type === 'commissions' ? (
            <>
              <PoolDiscretionaryCommissions
                payees={payees}
                tags={tagOptions}
                itemIndex={itemIndex}
              />
              <SubmitButton sending={sending} roles={['admin', 'finance']} update={!!update} />
            </>
          ) : type === 'payees' ? (
            <>
              <PoolPayeePoolMappings payees={payees} tags={tagOptions} itemIndex={itemIndex} />
              <SubmitButton
                sending={sending}
                update={!!update}
                roles={['admin', 'finance']}
                disabled={!!!token}
              />
            </>
          ) : null}
        </Box>
      </FormProvider>
    </f.FormContainer>
  )
}

interface Props {
  update?: CommissionPool
  redirect?: string | number
  type: 'info' | 'commissions' | 'payees'
  itemIndex?: number
  close: () => void
}

export default PoolForm
