import { useState, useCallback, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { deleteStyle } from "../common";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../context/data";
import { NotificationContext } from "../../../context/notification";
import { app } from "../../firebase";
import { getAuth, getIdToken } from "firebase/auth";

export default function DeleteEntityKeyByStringModal({
  path,
  index,
  deleteMethod,
  handleOpenState,
  openState,
  deleteType,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const handleClose = () => {
    if (sending) return;
    handleOpenState ? handleOpenState(false) : setOpen(false);
  };

  const firstStart = useRef<boolean>(true);
  if (firstStart.current) {
    /* Generate ID token */
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      const setNewToken = async () => {
        setToken(await getIdToken(user));
      };
      setNewToken();
    }

    firstStart.current = false;
  }

  const { entities, update } = useContext(DataContext);
  const notification = useContext(NotificationContext);

  const confirmedDelete = useCallback(async () => {
    if (!token) throw new Error("No token available");

    setSending(true);

    const [entityId, projectIndex, contractIndex, targetIndex] =
      index.split("-");
    const entity = entities.find((e) => e.id === entityId);
    if (!entity) throw new Error("Could not find entity with ID", entityId);

    const updated = { ...entity };
    removeIndexByDotNotation(
      updated,
      `projects.${projectIndex}.contracts.${contractIndex}.${deleteType}.${targetIndex}`
    );

    try {
      const result = await deleteMethod(entityId, token, updated);
      if (result) {
        update(updated, "entities", entityId);
        notification.set({
          message: `Deleted ${deleteType.slice(0, deleteType.length - 1)}`,
          type: "error",
        });
        setSending(false);
        handleClose();
      }
    } catch (e: any) {
      notification.set({
        message: `Failed to save: ${
          e?.message
            ? e.message
            : e?.status
            ? e.status + " Error"
            : "Unknown error"
        }`,
        type: "error",
      });
      console.error(e);
      setSending(false);
      return false;
    }
  }, [path, index, entities, notification]);

  if (!index) return null;

  return (
    <>
      <Modal
        open={
          handleOpenState && typeof openState === "boolean" ? openState : open
        }
        onClose={!sending ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this{" "}
            {deleteType.slice(0, deleteType.length - 1)}?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action cannot be reversed
          </Typography>
          <LoadingButton
            variant="outlined"
            fullWidth
            loading={sending}
            sx={{ py: "0.8rem", mt: "2rem", color: "white" }}
            color="error"
            onClick={() => {
              confirmedDelete();
            }}
            disabled={!!!token}
          >
            Delete
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
}

function removeIndexByDotNotation(obj: any, path: string) {
  var keys = path.split(".");
  var targetIndex = keys.pop();

  while (keys.length) {
    path = keys.shift()!;
    obj = obj[path];
  }

  obj.splice(targetIndex, 1);

  return true;
}

interface Props {
  name: string;
  id: string;
  objectToEdit: { [key: string]: any };
  path: string;
  index: any;
  deleteMethod: (id: string, token: string, body: any) => Promise<boolean>;
  handleOpenState?: (value: React.SetStateAction<boolean>) => void;
  openState?: boolean;
  deleteType: any;
}
