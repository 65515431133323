import { LegalEntity } from "../../../contract/types";
import { EntityModal, DeleteByIdModal } from "../../modals";
import { GridBase, entityInfoColDef, entityInfoRows } from "..";
import { delLegalEntity } from "../../../api/delete";
import { useNavigate } from "react-router-dom";

const EntityDataGrid = ({ entities, tags }: Props) => {
  const navigate = useNavigate();

  const onClick = (data: any) => {
    const id: string = data.row.id;
    const url = `/entity/${id}/projects/data`;
    navigate(url);
  };

  return (
    <GridBase
      name="Partner"
      rowFormatter={entityInfoRows}
      FormModal={EntityModal as any}
      DeleteModal={DeleteByIdModal as any}
      deleteMethod={delLegalEntity}
      data={entities}
      colDef={entityInfoColDef}
      deleteById={true}
      onClick={onClick}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      style={{ border: "none" }}
      defaultRows={20}
    />
  );
};

interface Props {
  entities: LegalEntity[];
  tags: string[];
}

export default EntityDataGrid;
