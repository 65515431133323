import {
  GridColumns,
  GridRowId,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import {
  CommissionModel,
  CommissionModelRoleMappings,
  Payee,
} from "../../../contract/types";
import { nameColDef, actionsColDef, InfoChipMemo } from "./common";

const roleColDef = ({
  handleEditClick,
  handleDeleteClick,
  models,
}: // payees,
Props) => {
  const columns: GridColumns = [
    { ...nameColDef("Name"), width: 150 },
    {
      field: "commissionModelRoleMappings",
      headerName: "Models",
      maxWidth: 1280,
      flex: 1,
      valueFormatter: (params) => {
        if (!params.value) return null;

        const commissionModelIds = params.value.map(
          (data: CommissionModelRoleMappings) => data.commissionModelId
        );

        const applicableModels = models?.filter((model) =>
          commissionModelIds.includes(model.id)
        );

        return applicableModels?.map((model) => model.name).join(", ");
      },

      renderCell: (params: GridRenderCellParams<any>) => {
        if (!params.value) return null;

        const commissionModelIds = params.value.map(
          (data: CommissionModelRoleMappings) => data.commissionModelId
        );

        const applicableModels = models?.filter((model) =>
          commissionModelIds.includes(model.id)
        );

        let r = (Math.random() + 1).toString(36).substring(7);

        if (applicableModels) {
          return applicableModels?.map((model, i) => {
            return (
              <InfoChipMemo label={model.name} variant="filled" key={r + i} />
            );
          });
        } else {
          return null;
        }
      },
    },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  models?: CommissionModel[];
  payees?: Payee[];
}

export default roleColDef;
