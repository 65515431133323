import { CommissionRole } from "../../../contract/types";
import { GridRowsProp } from "@mui/x-data-grid-pro";

const modelRoleRows = (roles: any[]) => {
  const rows: GridRowsProp = roles.map((role, i) => {
    return {
      id: i,
      name: role.name,
      percentage: role.percentage,
      tags: role.tags,
    };
  });

  return rows;
};

export default modelRoleRows;
