import { useContext } from "react";
import { DataContext } from "../../context/data";
import { Heading } from "../../components/profile";
import { Liquidation } from "../../contract/types";
import { LiquidationListDataGrid } from "../../components/datagrids";
import Loading from "../../layout/Loading";

const ListAllLiquidations = () => {
  const context = useContext(DataContext);
  const { loading, entities } = context;

  const liquidations: LiquidationEvent[] = [];
  entities.forEach((entity) => {
    entity.projects &&
      entity.projects.forEach((project, i) => {
        project.contracts &&
          project.contracts.forEach((contract, j) => {
            contract.liquidations &&
              contract.liquidations.forEach((liquidation, k) => {
                liquidation.approved &&
                  liquidations.push({
                    ...liquidation,
                    id: `${entity.id}-${i}-${j}-${k}`,
                    entityId: entity.id ?? "",
                    projectIndex: i,
                    contractIndex: j,
                    liquidationIndex: k,
                    entityName: entity.name,
                    projectName: project.name,
                    contractName: contract.name,
                    price: Number(liquidation.price),
                    value:
                      Number(liquidation.quantity) * Number(liquidation.price),
                  });
              });
          });
      });
  });

  if (loading) return <Loading />;
  return (
    <>
      <Heading
        title="Approved Liquidations"
        extraText="Liquidations that have been approved for inclusion in snapshots"
      />
      <LiquidationListDataGrid liquidations={liquidations} />
    </>
  );
};

interface LiquidationEvent extends Liquidation {
  id: string;
  entityId: string;
  projectIndex: number;
  contractIndex: number;
  liquidationIndex: number;
  entityName: string;
  projectName: string;
  contractName: string;
  value: number;
}

export default ListAllLiquidations;
