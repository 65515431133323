import { Fade } from "@mui/material";

const FormNestedBorder = ({ uid, children }: Props) => {
  return (
    <Fade in={true} timeout={{ enter: 105 }} key={uid}>
      <div
        style={{
          border: "1px solid rgb(255 255 255 / 20%)",
          borderRadius: 14,
          padding: 15,
          marginBottom: 15,
        }}
      >
        {children}
      </div>
    </Fade>
  );
};

interface Props {
  uid: string;
  children: JSX.Element | JSX.Element[];
}

export default FormNestedBorder;
