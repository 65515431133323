import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "./layout";
import { ListContract } from "./routes/contract";

import * as entity from "./routes/entity";
import * as project from "./routes/project";
import * as contract from "./routes/contract";
import * as model from "./routes/model";
import * as pool from "./routes/pool";
import * as payee from "./routes/payee";
import * as role from "./routes/role";
import * as snapshot from "./routes/snapshot";
import * as login from "./routes/login";
import "./App.css";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { darkTheme, lightTheme } from "./layout";

import * as am5 from "@amcharts/amcharts5";
import { LicenseInfo } from "@mui/x-license-pro";

am5.addLicense("AM5C314272773");
LicenseInfo.setLicenseKey(
  "8f625828c96fc20cda3c503c305964c4T1JERVI6NDMyMjIsRVhQSVJZPTE2ODM0NDg4MzAwMDAsS0VZVkVSU0lPTj0x"
);

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{ input: { colorScheme: prefersDarkMode ? "dark" : "light" } }}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<login.LoginPage />} />
            <Route path="/" element={<Navigate to="/contracts" />} />
            <Route path="entity" element={<entity.EntityInfoGridTest />} />
            <Route path="entity/new" element={<entity.NewEntity />} />
            <Route
              path="entity/:entityId/:entityData/update"
              element={<entity.ModifyEntity />}
            />
            <Route
              path="entity/:entityId/projects/:entityData"
              element={<project.ListProject />}
            />
            <Route
              path="entity/:entityId/projects/:entityData/new"
              element={<project.NewProject />}
            />
            <Route
              path="entity/:entityId/projects/:entityData/update/:projectIndex"
              element={<project.ModifyProject />}
            />
            <Route
              path="entity/:entityId/projects/:entityData/:projectIndex/contracts"
              element={<contract.ListContract />}
            />
            <Route
              path="entity/:entityId/projects/:entityData/:projectIndex/contracts/update/:contractIndex"
              element={<contract.ModifyContract />}
            />

            <Route path="contracts" element={<contract.ListAllContract />} />
            <Route
              path="contracts/liquidations"
              element={<contract.ListAllLiquidations />}
            />
            <Route
              path="contracts/liquidations/pending"
              element={<contract.ListPendingLiquidations />}
            />
            <Route
              path="contracts/liquidate"
              element={<contract.LiquidateMultiple />}
            />
            <Route
              path="contracts/liquidate/cash/excel"
              element={<contract.LiquidateCashExcel />}
            />

            <Route path="model" element={<model.ModelInfoGridTest />} />
            <Route path="model/new" element={<model.NewModel />} />
            <Route
              path="model/update/:modelId/:modelData"
              element={<model.ModifyModel />}
            />
            <Route path="model/:modelId" element={<ListContract />} />
            <Route path="pool" element={<pool.PoolInfoGridTest />} />
            <Route
              path="pool/update/:poolId/:poolData"
              element={<pool.ModifyPool />}
            />

            <Route path="payee" element={<payee.PayeeGridTest />} />
            <Route path="payee/:emailAddress" element={<payee.Profile />} />

            <Route path="payeegrid" element={<payee.PayeeGridTest />} />

            <Route path="role" element={<role.RoleGridTest />} />

            {/* <Route path="data" element={<commission.Commissions />} /> */}
            <Route path="data" element={<snapshot.PendingSnapshot />} />
            <Route path="snapshot" element={<snapshot.Snapshots />} />
            <Route
              path="snapshot/:snapshotId"
              element={<snapshot.UpdateSnapshot />}
            />
            <Route
              path="snapshot/:snapshotId/payments"
              element={<snapshot.Payments />}
            />

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
