import { useContext } from "react";
import { GridColumns, GridRowId } from "@mui/x-data-grid-pro";
import {
  nameColDef,
  tagsColDef,
  dateColDef,
  actionsColDef,
  InfoChipMemo,
  percentageColDef,
} from "./common";
import { DataContext } from "../../../context/data";

const ContractCommissionOverrideColDef = ({
  handleEditClick,
  handleDeleteClick,
}: Props) => {
  const { pools } = useContext(DataContext);
  const columns: GridColumns = [
    { ...nameColDef("Payee", "payeeName") },
    { ...nameColDef("Rule Name", "name") },
    {
      field: "poolIds",
      headerName: "Source Pools",
      width: 210,
      renderCell: ({ value }) => {
        const r = (Math.random() + 1).toString(36).substring(7);
        if (value && value.length > 0) {
          return pools
            .filter((p) => value.includes(p.id))
            .map((p, i) => {
              return (
                <InfoChipMemo label={p.name} variant="filled" key={r + i} />
              );
            });
        } else {
          return <InfoChipMemo label="All" variant="filled" key={r} />;
        }
      },
    },
    {
      ...percentageColDef("Percent", "percentage"),
      width: 140,
    },
    {
      ...dateColDef("startDate", "Start Date"),
    },
    {
      ...dateColDef("endDate", "End Date"),
    },
    {
      ...tagsColDef,
      width: 140,
    },
    { ...actionsColDef({ handleEditClick, handleDeleteClick }) },
  ];

  return columns;
};

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void;
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void;
}

export default ContractCommissionOverrideColDef;
