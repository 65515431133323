import { useContext, useEffect } from "react";
import { DataContext } from "../../context/data";
import { ModelInfoDataGrid } from "../../components/datagrids";
import { Heading } from "../../components/profile";
import Loading from "../../layout/Loading";

const ModelInfoGridTest = () => {
  const context = useContext(DataContext);

  useEffect(() => {
    context.refreshAll();
  }, []);

  if (context.loading) return <Loading />;
  return (
    <div>
      <Heading
        title={"Commission Models"}
        extraText={"Set the commission rules for pools, roles and individuals."}
      />
      <div style={{ height: 5 }} />
      <ModelInfoDataGrid models={context.models} tags={context.tags} />
    </div>
  );
};

export default ModelInfoGridTest;
