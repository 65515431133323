import { useCallback, createContext, useState, useEffect, useRef } from "react";

const initialState = {
  notifications: [],
  set: () => {},
  clear: () => {},
};

export const NotificationContext =
  createContext<NotificationProps>(initialState);

export const NotificationContextProvider = (props: Props) => {
  const [values, setValues] = useState<NotificationProps>(initialState);

  const set = useCallback(
    ({
      message,
      type,
    }: {
      message: string;
      type: "success" | "info" | "error" | "warning";
    }) => {
      setValues((prevState) => {
        const newState = { ...prevState };
        const id = (Math.random() + 1).toString(36).substring(7);
        newState.notifications.push({ message, type, id });
        return newState;
      });
    },
    [setValues]
  );

  const clear = useCallback(
    (id: string) => {
      setValues((prevState) => ({
        ...prevState,
        notifications: prevState.notifications.filter((n) => n.id !== id),
      }));
    },
    [setValues]
  );

  useEffect(() => {
    setValues({ notifications: [], set, clear });
  }, [setValues]);

  return (
    <NotificationContext.Provider value={values}>
      {props.children}
    </NotificationContext.Provider>
  );
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface NotificationProps {
  notifications: Notification[];
  set: ({
    message,
    type,
  }: {
    message: string;
    type: "success" | "info" | "error" | "warning";
  }) => void;
  clear: (id: string) => void;
}

export interface Notification {
  id: string;
  message: string;
  type: "success" | "info" | "error" | "warning";
}

export default NotificationContextProvider;
