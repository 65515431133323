import { useContext, useEffect, useState } from 'react'
import { GridActionsCellItem, GridRowId, GridRowParams } from '@mui/x-data-grid-pro'
import { AuthContext } from '../../../../context/role'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'

const actionsColDef = ({ handleEditClick, handleDeleteClick }: Props) => {
  const actions = {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id, row }: GridRowParams) => {
      return [
        <GridEditButton id={id} handleEditClick={handleEditClick} disabled={!!row.snapshotted} />,
        <GridDeleteButton
          id={id}
          handleDeleteClick={handleDeleteClick}
          disabled={!!row.snapshotted}
        />,
      ]
    },
  }
  return actions
}

interface GridEditButtonProps {
  id: GridRowId
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void
  disabled: boolean
}

const GridEditButton = (props: GridEditButtonProps) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const authState = useContext(AuthContext)

  useEffect(() => {
    if (
      authState.role === 'admin' ||
      authState.role === 'finance' ||
      authState.role === 'contractViewer'
    ) {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [authState])

  return (
    <GridActionsCellItem
      icon={<EditIcon style={{ opacity: props.disabled || !isAdmin ? 0.4 : 1 }} />}
      label='Edit'
      className='textPrimary'
      onClick={props.handleEditClick(props.id)}
      color='inherit'
      disabled={props.disabled || !isAdmin}
    />
  )
}

interface GridDeleteButtonProps {
  id: GridRowId
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void
  disabled: boolean
}

const GridDeleteButton = (props: GridDeleteButtonProps) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const authState = useContext(AuthContext)

  useEffect(() => {
    if (authState.role === 'admin' || authState.role === 'finance') {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [authState])

  return (
    <GridActionsCellItem
      icon={<DeleteIcon style={{ opacity: props.disabled || !isAdmin ? 0.4 : 1 }} />}
      label='Delete'
      onClick={props.handleDeleteClick(props.id)}
      color='error'
      disabled={props.disabled || !isAdmin}
    />
  )
}

interface Props {
  handleEditClick: (id: GridRowId) => (event: React.MouseEvent) => void
  handleDeleteClick: (id: GridRowId) => (event: React.MouseEvent) => void
}

export default actionsColDef
