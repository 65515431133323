import { GridRowsProp } from "@mui/x-data-grid-pro";

const contractLiquidationRows = (liquidations: any[]) => {
  const rows: GridRowsProp = liquidations.map((liquidation, i) => {
    return {
      id: i,
      name: liquidation.name,
      type: liquidation.type,
      symbol: liquidation.symbol,
      quantity: liquidation.quantity,
      price: liquidation.price,
      date: new Date(liquidation.date),
      approved: liquidation.approved
        ? new Date(liquidation.approved)
        : undefined,
      tags: liquidation.tags,
      snapshotted: liquidation.snapshotted,
    };
  });

  return rows;
};

export default contractLiquidationRows;
