import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { DataContext } from "../../context/data";
import { ModelModal } from "../../components/modals";
import {
  ModelIndividualRuleDataGrid,
  ModelRoleDataGrid,
  ModelLinkedPoolDataGrid,
} from "../../components/datagrids";
import { useParams } from "react-router-dom";
import { Heading, Subheading } from "../../components/profile";
import Loading from "../../layout/Loading";

const ModifyModel = () => {
  const context = useContext(DataContext);
  const { loading, payees, pools, roles, models } = context;

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [modelInfo, setModelInfo] = useState<any>([]);
  const [poolData, setPoolData] = useState<any>([]);
  const [roleData, setRoleData] = useState<any>([]);
  const [individualData, setIndividualData] = useState<any>([]);
  const [percentage, setPercentage] = useState<number>();
  const [modelData, setModelData] = useState<any>();
  const params = useParams();

  const firstLoad = useRef<boolean>(true);

  const processData = useCallback(() => {
    let totalPercentage = 0;
    const payeeIdMap = payees?.map((payee) => payee.id);
    const poolIdMap = pools?.map((pool) => pool.id);
    const roleIdMap = roles?.map((role) => role.id);

    const modelIndex = models.findIndex((m) => m.id === params.modelId);
    const modelData = models[modelIndex];
    const {
      commissionModelPoolMappings,
      commissionModelRoleMappings,
      commissionModelIndividualMappings,
      ...info
    } = modelData;

    const commissionPoolData = commissionModelPoolMappings?.map((pool) => {
      const poolData = pools![poolIdMap!.indexOf(pool.commissionPoolId)];
      if (pool.percentage) {
        totalPercentage += Number(pool.percentage);
      }
      return {
        ...pool,
        name: poolData?.name || "DELETED",
        id: poolData?.id || "DELETED",
      };
    });

    const commissionRoleData = commissionModelRoleMappings?.map((role) => {
      const roleData = roles![roleIdMap!.indexOf(role.commissionRoleId)];
      if (role.percentage) {
        totalPercentage += Number(role.percentage);
      }
      return { ...role, name: roleData?.name ?? "DELETED" };
    });

    const commissionIndividualData = commissionModelIndividualMappings?.map(
      (rule) => {
        const payeeData = payees![payeeIdMap!.indexOf(rule.payeeId)];
        if (rule.percentage) {
          totalPercentage += Number(rule.percentage);
        }
        if (!payeeData) {
          console.error(
            `commissionIndividualData error. Payee ${rule.payeeId} does not exist.`
          );
          return { ...rule, name: "Removed", email: "DELETED" };
        }
        return {
          ...rule,
          name: payeeData?.name ?? "DELETE",
          email: payeeData?.email ?? "DELETED",
        };
      }
    );

    setModelInfo(info);
    setPoolData(commissionPoolData);
    setRoleData(commissionRoleData);
    setIndividualData(commissionIndividualData);
    setModelData(modelData);
    setPercentage(totalPercentage);
  }, [context]);

  /* Hack to work like componentWillMount */
  if (firstLoad.current && !loading) {
    firstLoad.current = false;
    processData();
  }

  useEffect(() => {
    /* Stop it processing twice on launch */
    if (!firstLoad.current) {
      processData();
    }
  }, [context]);

  if (!params.modelId) {
    alert("Params not provided");
    return null;
  }

  const poolDeletePath = `commissionModelPoolMappings`;
  const roleDeletePath = `commissionModelRoleMappings`;
  const individualDeletePath = `commissionModelIndividualMappings`;

  if (loading) return <Loading />;
  return (
    <>
      <Heading
        title={`${modelInfo.name} (${percentage}%)`}
        tags={modelInfo.tags}
        handleOpenState={setOpenEdit}
        extraText={`Active ${modelInfo.endDate ? "from" : "since"} ${new Date(
          modelInfo.startDate
        )
          .toString()
          .substring(0, 15)}${
          modelInfo.endDate
            ? " until " +
              new Date(modelInfo.endDate).toString().substring(0, 15) +
              "."
            : "."
        }`}
      />
      <div style={{ height: 15 }} />
      <Subheading title="Role Commissions" />
      <ModelRoleDataGrid
        roles={roleData!}
        modelData={modelData}
        deletePath={roleDeletePath}
      />
      <div style={{ height: 50 }} />
      <Subheading title="Individual Rules" />
      <ModelIndividualRuleDataGrid
        rules={individualData!}
        modelData={modelData}
        deletePath={individualDeletePath}
      />
      <div style={{ height: 50 }} />
      <Subheading title="Linked Pools" />
      <ModelLinkedPoolDataGrid
        pools={poolData!}
        modelData={modelData}
        deletePath={poolDeletePath}
      />
      <div style={{ height: 50 }} />
      <ModelModal
        open={openEdit}
        handleOpenState={setOpenEdit}
        modelData={modelData}
        type="info"
      />
    </>
  );
};

export default ModifyModel;
